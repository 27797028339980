.comment .subject a {
    font-weight: 700!important;
    color: #4C4C4C;
    line-height: 1em;
}
.comment .subject a:hover {
    text-decoration: underline;
    outline: 0;
}
.meta {
    color: #b3b3b3;
    font-size: 14px;
    font-weight: 700;
}
.meta a {
    color: #A7B3C2;
}
.meta a:hover {
    color: parent;
}
.replies-count {
    font-weight: bold;
    text-align: center;
    line-height: 17px;
}