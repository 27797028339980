@media screen {
    #printSection {
        display: none;
    }
}

@media print {
    body * {
        visibility:hidden;
    }
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
        font-size: 11px;
    }
    .appointment-list {
        font-size: 11px !important;
    }
    #print_area {
        display: none;
    }
}

.trashed {
    background: #FFBABA;
}

.well.with-pagination {
    padding-bottom: 80px;
}

.list-btn {
    float: left;
    margin-left: 10px;
}

.list-form {
    margin-left: 10px;
    float: left;
}

.form-loader {
    display: none;
}

.linkable {
    cursor: pointer;
}

.loader {
    pointer-events: none;
}

.error {
    color: #FF0000;
}

.patient-container .patient-header {
    background-color: #fbfbfb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
    margin: 15px 15px 0;
    min-height: 175px;
}
.patient-container .patient-header .header-avatar {
    background-clip: padding-box;
    border: 5px solid #f5f5f5;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    height: 125px;
    margin: 25px auto;
    width: 125px;
}
.patient-container .patient-header .patient-info {
    border-right: 1px solid #eee;
    min-height: 175px;
    padding: 15px;
}
.patient-container .patient-header .patient-info .header-fullname {
    display: inline-block;
    font: 21px "Roboto","Lucida Sans","trebuchet MS",Arial,Helvetica;
    margin-top: 27px;
}
.patient-container .patient-header .patient-info .btn-follow {
    position: absolute;
    right: 40px;
    top: 45px;
}
.patient-container .patient-header .patient-info .header-information {
    line-height: 23px;
    margin-top: 15px;
    text-align: justify;
}
.patient-container .patient-header .patient-stats {
    border-right: 1px solid #eee;
    min-height: 175px;
}
.patient-container .patient-header .patient-stats .stats-col {
    margin: 30px 0;
    text-align: center;
}
.patient-container .patient-header .patient-stats .stats-col:not(:last-child) {
    border-right: 1px solid #eee;
}
.patient-container .patient-header .patient-stats .stats-col .stats-value {
    display: block;
    font-family: "Roboto","Lucida Sans","trebuchet MS",Arial,Helvetica;
    font-size: 30px;
    margin: 0 auto;
    text-align: center;
}
.patient-container .patient-header .patient-stats .stats-col .stats-title {
    display: block;
    margin: 0 auto;
    text-align: center;
}
.patient-container .patient-header .patient-stats .inlinestats-col {
    border-top: 1px solid #eee;
    font-family: "Roboto","Lucida Sans","trebuchet MS",Arial,Helvetica;
    min-height: 55px;
    padding-top: 15px;
    text-align: center;
}
.patient-container .patient-header .patient-stats .inlinestats-col:not(:last-child) {
    border-right: 1px solid #eee;
}
.patient-container .patient-header .patient-stats .inlinestats-col i {
    margin-right: 2px;
}
.patient-container .patient-header .patient-contactinfo .title {
    margin-bottom: 5px;
    margin-top: 30px;
}
.patient-container .patient-header .patient-contactinfo .list-group-item {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
    border: medium none;
    margin-top: 5px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 5px;
}
.patient-container .patient-body .nav-tabs > li.active > a, .patient-container .patient-body .nav-tabs > li.active > a:hover, .patient-container .patient-body .nav-tabs > li.active > a:focus {
    background-color: #fbfbfb;
    border-bottom: 2px solid #2dc3e8;
    border-top: 0 none;
    box-shadow: none;
    margin-bottom: 0;
    top: 2px;
}
.patient-container .patient-body .nav-tabs {
    border-top: 1px solid #e5e5e5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
    top: 0;
}
.patient-container .patient-body .nav-tabs.tabs-flat {
    background-color: #fbfbfb;
}
.patient-container .patient-body .tab-content {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
    margin-top: 30px;
}
.patient-container .patient-body .setting-header {
    margin-bottom: 25px;
}
.patient-container .patient-contacts {
    padding: 25px;
}
.patient-container .patient-contacts .contact-badge i {
    background-clip: padding-box;
    background-image: linear-gradient(to bottom, #eee 0px, #fbfbfb 100%);
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.176);
    color: #444;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px;
    z-index: 100;
}
.patient-container .patient-contacts .contact-badge span {
    font-size: 26px;
    font-weight: 300;
    margin-left: 20px;
}
.patient-container .patient-contacts .contact-info p {
    padding: 10px 70px;
}
.patient-container #contact-map {
    background-clip: padding-box;
    border: 5px solid #fbfbfb;
    border-radius: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 400px;
    margin: 20px;
    overflow: hidden;
    width: 400px;
}
.patient-container .patient-badge i {
    background-clip: padding-box;
    background-image: linear-gradient(to bottom, #eee 0px, #fbfbfb 100%);
    border-radius: 50%;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.176);
    color: #444;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 50px;
    z-index: 100;
}
.patient-container .patient-badge span {
    font-size: 26px;
    font-weight: 300;
    margin-left: 20px;
}
.patient-container .patient-overview {
    padding: 5px;
}
.patient-container .row-title {
    margin-left: -17px;
    margin-top: 50px;
}
.patient-container .timeline {
    margin-top: -16px;
    padding-top: 35px;
}
@media (max-width: 992px) {
    .patient-container .patient-header .patient-info {
        padding: 0 40px;
    }
    .patient-container .patient-header .patient-info .btn-follow {
        top: 28px;
    }
    .patient-container .patient-header .patient-stats .stats-col {
        border-right: 0 none;
    }
}

.patient-info-td {
    width: 180px;
}

.appointment-list > tbody {
    font-size: 12px;
}

.list-group-item-info > a {
    color: #3c3c3c !important;
}

.destroyer-from-lists {
    cursor: pointer;
}

.toggle-parameter
{
    cursor: pointer;
}

.action {
    width: 200px;
    text-align: right;
}

.action_m {
    width: 300px;
    text-align: right;
}

.well-light {
    background: white;
}

.sample-info-form {
    display: none;
}

.sample-info {
    overflow: auto; position: relative
}

.sample-info > .text {
    margin-right: 10px;
}

.sample_delete_btn {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #333;
}

.sample_print_btn {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #333;
}

.statement {
    overflow: auto; position: relative
}

.statement-form {
    display: none
}

.statement-text {
    height: 220px; resize: none; margin-top: 10px;
}

.save-order {
    cursor: pointer;
}

.lab-service-type {
    width: 200px !important;
}

.expand {
    cursor: pointer;
}

.sum {
    font-weight: bold;
    background: #fcf8e3 !important;
}

.statistic > thead > tr {
    background: #fcf8e3 !important;
}

.statistic > tbody > tr > td, .statistic > tbody > tr > th, .statistic > tfoot > tr > td, .statistic > tfoot > tr > th, .statistic > thead > tr > td, .statistic > thead > tr > th {
    padding: 4px !important;
}


.amount_input {
    border: none !important;
}

.amount_input:focus {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    color: #262626;
    outline: 0 none;
}