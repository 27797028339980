﻿@-webkit-keyframes borderPulse {
    15% {
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.3);
        opacity: 0
    }
}
@-moz-keyframes borderPulse {
    15% {
        opacity: 1
    }
    100% {
        -moz-transform: scale(1.3);
        opacity: 0
    }
}
@-o-keyframes borderPulse {
    15% {
        opacity: 1;
    }
    100% {
        -o-transform: scale(1.3);
        opacity: 0;
    }
}
@keyframes borderPulse {
    15% {
        opacity: 1
    }
    100% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        transform: scale(1.3);
        opacity: 0
    }
}
@-webkit-keyframes pulse {
    0% {
        opacity: .5
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: .5
    }
}
@-moz-keyframes pulse {
    0% {
        opacity: .5
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: .5
    }
}
@-o-keyframes pulse {
    0% {
        opacity: .5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .5;
    }
}
@keyframes pulse {
    0% {
        opacity: .5
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: .5
    }
}
@-webkit-keyframes explode {
    100% {
        opacity: 0;
        -webkit-transform: scale(3) rotate(30deg) translateY(60px)
    }
}
@-moz-keyframes explode {
    100% {
        opacity: 0;
        -moz-transform: scale(3) rotate(30deg) translateY(60px)
    }
}
@-o-keyframes explode {
    100% {
        opacity: 0;
        -o-transform: scale(3)rotate(30deg)translateY(60px);
    }
}
@keyframes explode {
    100% {
        opacity: 0;
        -webkit-transform: scale(3) rotate(30deg) translateY(60px);
        -moz-transform: scale(3) rotate(30deg) translateY(60px);
        -ms-transform: scale(3) rotate(30deg) translateY(60px);
        transform: scale(3) rotate(30deg) translateY(60px)
    }
}
@-webkit-keyframes wave {
    5% {
        opacity: .6
    }
    27% {
        -webkit-transform: scale(1.8);
        opacity: 0
    }
    100% {
        opacity: 0
    }
}
@-moz-keyframes wave {
    5% {
        opacity: .6
    }
    27% {
        -moz-transform: scale(1.8);
        opacity: 0
    }
    100% {
        opacity: 0
    }
}
@-o-keyframes wave {
    5% {
        opacity: .6;
    }
    27% {
        -o-transform: scale(1.8);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes wave {
    5% {
        opacity: .6
    }
    27% {
        -webkit-transform: scale(1.8);
        -moz-transform: scale(1.8);
        -ms-transform: scale(1.8);
        transform: scale(1.8);
        opacity: 0
    }
    100% {
        opacity: 0
    }
}
@-webkit-keyframes dropdownSlideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px)
    }
    100% {
        -webkit-transform: translateY(0)
    }
}
@-moz-keyframes dropdownSlideInDown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px)
    }
    100% {
        -moz-transform: translateY(0)
    }
}
@-o-keyframes dropdownSlideInDown {
    0% {
        opacity: 0;
        -o-transform: translateY(-20px);
    }
    100% {
        -o-transform: translateY(0);
    }
}
@keyframes dropdownSlideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px)
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg)
    }
    100% {
        -moz-transform: rotate(360deg)
    }
}
@-o-keyframes spin {
    0% {
        -o-transform: rotate(0deg);
    }
    100% {
        -o-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
.themeprimary {
    color: #2dc3e8 !important
}
.themesecondary {
    color: #fb6e52 !important
}
.themethirdcolor {
    color: #ffce55 !important
}
.themefourthcolor {
    color: #a0d468 !important
}
.themefifthcolor {
    color: #e75b8d !important
}
.white {
    color: #fff !important
}
.snow {
    color: #fbfbfb !important
}
.whitesmoke {
    color: #f5f5f5 !important
}
.seashell {
    color: #f3f3f3 !important
}
.ivory {
    color: #eee !important
}
.platinum {
    color: #e5e5e5 !important
}
.lightgray {
    color: #d0d0d0 !important
}
.gray {
    color: #ccc !important
}
.darkgray {
    color: #999 !important
}
.silver {
    color: #777 !important
}
.sonic-silver {
    color: #737373 !important
}
.storm-cloud {
    color: #666 !important
}
.lightcarbon {
    color: #555 !important
}
.carbon {
    color: #444 !important
}
.slate-gray {
    color: #333 !important
}
.darkcarbon {
    color: #262626 !important
}
.blue {
    color: #5db2ff !important
}
.info {
    color: #57b5e3 !important
}
.sky {
    color: #11a9cc !important
}
.primary {
    color: #4374e0 !important
}
.azure {
    color: #2dc3e8 !important
}
.blueberry {
    color: #6f85bf !important
}
.palegreen {
    color: #a0d468 !important
}
.success {
    color: #53a93f !important
}
.green {
    color: #8cc474 !important
}
.lightyellow {
    color: #f6d52e !important
}
.yellow {
    color: #ffce55 !important
}
.warning {
    color: #f4b400 !important
}
.gold {
    color: #f9b256 !important
}
.orange {
    color: #fb6e52 !important
}
.lightred {
    color: #e46f61 !important
}
.darkorange {
    color: #ed4e2a !important
}
.red {
    color: #df5138 !important
}
.pink {
    color: #e75b8d !important
}
.darkpink {
    color: #cc324b !important
}
.danger {
    color: #d73d32 !important
}
.magenta {
    color: #bc5679 !important
}
.purple {
    color: #7e3794 !important
}
.maroon {
    color: #981b48 !important
}
.bg-themeprimary {
    background-color: #2dc3e8 !important
}
.bg-themesecondary {
    background-color: #fb6e52 !important
}
.bg-themethirdcolor {
    background-color: #ffce55 !important
}
.bg-themefourthcolor {
    background-color: #a0d468 !important
}
.bg-themefifthcolor {
    background-color: #e75b8d !important
}
.bg-white {
    background-color: #fff !important
}
.bg-snow {
    background-color: #fbfbfb !important
}
.bg-whitesmoke {
    background-color: #f5f5f5 !important
}
.bg-seashell {
    background-color: #f3f3f3 !important
}
.bg-ivory {
    background-color: #eee !important
}
.bg-platinum {
    background-color: #e5e5e5 !important
}
.bg-lightgray {
    background-color: #d0d0d0 !important
}
.bg-gray {
    background-color: #ccc !important
}
.bg-darkgray {
    background-color: #999 !important
}
.bg-silver {
    background-color: #777 !important
}
.bg-sonic-silver {
    background-color: #737373 !important
}
.bg-storm-cloud {
    background-color: #666 !important
}
.bg-lightcarbon {
    background-color: #555 !important
}
.bg-carbon {
    background-color: #444 !important
}
.bg-slate-gray {
    background-color: #333 !important
}
.bg-darkcarbon {
    background-color: #262626 !important
}
.bg-blue {
    background-color: #5db2ff !important
}
.bg-info {
    background-color: #57b5e3 !important
}
.bg-sky {
    background-color: #11a9cc !important
}
.bg-primary {
    background-color: #4374e0 !important
}
.bg-azure {
    background-color: #2dc3e8 !important
}
.bg-blueberry {
    background-color: #6f85bf !important
}
.bg-palegreen {
    background-color: #a0d468 !important
}
.bg-success {
    background-color: #53a93f !important
}
.bg-green {
    background-color: #8cc474 !important
}
.bg-lightyellow {
    background-color: #f6d52e !important
}
.bg-yellow {
    background-color: #ffce55 !important
}
.bg-warning {
    background-color: #f4b400 !important
}
.bg-gold {
    background-color: #f9b256 !important
}
.bg-orange {
    background-color: #fb6e52 !important
}
.bg-lightred {
    background-color: #e46f61 !important
}
.bg-darkorange {
    background-color: #ed4e2a !important
}
.bg-red {
    background-color: #df5138 !important
}
.bg-danger {
    background-color: #d73d32 !important
}
.bg-pink {
    background-color: #e75b8d !important
}
.bg-darkpink {
    background-color: #cc324b !important
}
.bg-magenta {
    background-color: #bc5679 !important
}
.bg-purple {
    background-color: #7e3794 !important
}
.bg-maroon {
    background-color: #981b48 !important
}
.before-themeprimary:before {
    background-color: #2dc3e8 !important
}
.before-themesecondary:before {
    background-color: #fb6e52 !important
}
.before-themethirdcolor:before {
    background-color: #ffce55 !important
}
.before-themefourthcolor:before {
    background-color: #a0d468 !important
}
.before-themefifthcolor:before {
    background-color: #e75b8d !important
}
.before-white:before {
    background-color: #fff !important
}
.before-snow:before {
    background-color: #fbfbfb !important
}
.before-whitesmoke:before {
    background-color: #f5f5f5 !important
}
.before-seashell:before {
    background-color: #f3f3f3 !important
}
.before-ivory:before {
    background-color: #eee !important
}
.before-platinum:before {
    background-color: #e5e5e5 !important
}
.before-lightgray:before {
    background-color: #d0d0d0 !important
}
.before-gray:before {
    background-color: #ccc !important
}
.before-darkgray:before {
    background-color: #999 !important
}
.before-silver:before {
    background-color: #777 !important
}
.before-sonic-silver:before {
    background-color: #737373 !important
}
.before-storm-cloud:before {
    background-color: #666 !important
}
.before-lightcarbon:before {
    background-color: #555 !important
}
.before-carbon:before {
    background-color: #444 !important
}
.before-slate-gray:before {
    background-color: #333 !important
}
.before-darkcarbon:before {
    background-color: #262626 !important
}
.before-blue:before {
    background-color: #5db2ff !important
}
.before-info:before {
    background-color: #57b5e3 !important
}
.before-sky:before {
    background-color: #11a9cc !important
}
.before-primary:before {
    background-color: #4374e0 !important
}
.before-azure:before {
    background-color: #2dc3e8 !important
}
.before-blueberry:before {
    background-color: #6f85bf !important
}
.before-palegreen:before {
    background-color: #a0d468 !important
}
.before-success:before {
    background-color: #53a93f !important
}
.before-green:before {
    background-color: #8cc474 !important
}
.before-lightyellow:before {
    background-color: #f6d52e !important
}
.before-yellow:before {
    background-color: #ffce55 !important
}
.before-warning:before {
    background-color: #f4b400 !important
}
.before-gold:before {
    background-color: #f9b256 !important
}
.before-orange:before {
    background-color: #fb6e52 !important
}
.before-lightred:before {
    background-color: #e46f61 !important
}
.before-darkorange:before {
    background-color: #ed4e2a !important
}
.before-red:before {
    background-color: #df5138 !important
}
.before-danger:before {
    background-color: #d73d32 !important
}
.before-pink:before {
    background-color: #e75b8d !important
}
.before-darkpink:before {
    background-color: #cc324b !important
}
.before-magenta:before {
    background-color: #bc5679 !important
}
.before-purple:before {
    background-color: #7e3794 !important
}
.before-maroon:before {
    background-color: #981b48 !important
}
.bordered-solid {
    border-style: solid !important
}
.bordered-dashed {
    border-style: dashed !important
}
.bordered-dotted {
    border-style: dotted !important
}
.bordered-double {
    border-style: double !important
}
.bordered-1 {
    border: 1px solid #fff
}
.bordered-2 {
    border: 2px solid #fff
}
.bordered-3 {
    border: 3px solid #fff
}
.bordered-4 {
    border: 4px solid #fff
}
.bordered-5 {
    border: 5px solid #fff
}
.bordered-bottom-1 {
    border-bottom: 1px solid #fff
}
.bordered-bottom-2 {
    border-bottom: 2px solid #fff
}
.bordered-bottom-3 {
    border-bottom: 3px solid #fff
}
.bordered-bottom-4 {
    border-bottom: 4px solid #fff
}
.bordered-bottom-5 {
    border-bottom: 5px solid #fff
}
.bordered-top-1 {
    border-top: 1px solid #fff
}
.bordered-top-2 {
    border-top: 2px solid #fff
}
.bordered-top-3 {
    border-top: 3px solid #fff
}
.bordered-top-4 {
    border-top: 4px solid #fff
}
.bordered-top-5 {
    border-top: 5px solid #fff
}
.bordered-left-1 {
    border-left: 1px solid #fff
}
.bordered-left-2 {
    border-left: 2px solid #fff
}
.bordered-left-3 {
    border-left: 3px solid #fff
}
.bordered-left-4 {
    border-left: 4px solid #fff
}
.bordered-left-5 {
    border-left: 5px solid #fff
}
.bordered-right-1 {
    border-right: 1px solid #fff
}
.bordered-right-2 {
    border-right: 2px solid #fff
}
.bordered-right-3 {
    border-right: 3px solid #fff
}
.bordered-right-4 {
    border-right: 4px solid #fff
}
.bordered-right-5 {
    border-right: 5px solid #fff
}
.bordered-themeprimary {
    border-color: #2dc3e8 !important
}
.bordered-themesecondary {
    border-color: #fb6e52 !important
}
.bordered-themethirdcolor {
    border-color: #ffce55 !important
}
.bordered-themefourthcolor {
    border-color: #a0d468 !important
}
.bordered-themefifthcolor {
    border-color: #e75b8d !important
}
.bordered-white {
    border-color: #fff !important
}
.bordered-snow {
    border-color: #fbfbfb !important
}
.bordered-whitesmoke {
    border-color: #f5f5f5 !important
}
.bordered-seashell {
    border-color: #f3f3f3 !important
}
.bordered-ivory {
    border-color: #eee !important
}
.bordered-platinum {
    border-color: #e5e5e5 !important
}
.bordered-lightgray {
    border-color: #d0d0d0 !important
}
.bordered-gray {
    border-color: #ccc !important
}
.bordered-darkgray {
    border-color: #999 !important
}
.bordered-silver {
    border-color: #777 !important
}
.bordered-sonic-silver {
    border-color: #737373 !important
}
.bordered-storm-cloud {
    border-color: #666 !important
}
.bordered-lightcarbon {
    border-color: #555 !important
}
.bordered-carbon {
    border-color: #444 !important
}
.bordered-slate-gray {
    border-color: #333 !important
}
.bordered-darkcarbon {
    border-color: #262626 !important
}
.bordered-blue {
    border-color: #5db2ff !important
}
.bordered-info {
    border-color: #57b5e3 !important
}
.bordered-sky {
    border-color: #11a9cc !important
}
.bordered-primary {
    border-color: #4374e0 !important
}
.bordered-azure {
    border-color: #2dc3e8 !important
}
.bordered-blueberry {
    border-color: #6f85bf !important
}
.bordered-palegreen {
    border-color: #a0d468 !important
}
.bordered-success {
    border-color: #53a93f !important
}
.bordered-green {
    border-color: #8cc474 !important
}
.bordered-lightyellow {
    border-color: #f6d52e !important
}
.bordered-yellow {
    border-color: #ffce55 !important
}
.bordered-warning {
    border-color: #f4b400 !important
}
.bordered-gold {
    border-color: #f9b256 !important
}
.bordered-orange {
    border-color: #fb6e52 !important
}
.bordered-lightred {
    border-color: #e46f61 !important
}
.bordered-darkorange {
    border-color: #ed4e2a !important
}
.bordered-red {
    border-color: #df5138 !important
}
.bordered-danger {
    border-color: #d73d32 !important
}
.bordered-pink {
    border-color: #e75b8d !important
}
.bordered-darkpink {
    border-color: #cc324b !important
}
.bordered-magenta {
    border-color: #bc5679 !important
}
.bordered-purple {
    border-color: #7e3794 !important
}
.bordered-maroon {
    border-color: #981b48 !important
}
.no-padding {
    padding: 0 !important
}
.no-padding-bottom {
    padding-bottom: 0 !important
}
.no-padding-top {
    padding-top: 0 !important
}
.no-padding-left {
    padding-left: 0 !important
}
.no-padding-right {
    padding-right: 0 !important
}
.padding-5 {
    padding: 5px !important
}
.padding-10 {
    padding: 10px !important
}
.padding-20 {
    padding: 20px !important
}
.padding-30 {
    padding: 30px !important
}
.padding-40 {
    padding: 40px !important
}
.padding-50 {
    padding: 50px !important
}
.padding-left-5 {
    padding-left: 5px !important
}
.padding-left-10 {
    padding-left: 10px !important
}
.padding-left-20 {
    padding-left: 20px !important
}
.padding-left-30 {
    padding-left: 30px !important
}
.padding-left-40 {
    padding-left: 40px !important
}
.padding-left-50 {
    padding-left: 50px !important
}
.padding-top-5 {
    padding-top: 5px !important
}
.padding-top-10 {
    padding-top: 10px !important
}
.padding-top-20 {
    padding-top: 20px !important
}
.padding-top-30 {
    padding-top: 30px !important
}
.padding-top-40 {
    padding-top: 40px !important
}
.padding-top-50 {
    padding-top: 50px !important
}
.padding-right-5 {
    padding-right: 5px !important
}
.padding-right-10 {
    padding-right: 10px !important
}
.padding-right-20 {
    padding-right: 20px !important
}
.padding-right-30 {
    padding-right: 30px !important
}
.padding-right-40 {
    padding-right: 40px !important
}
.padding-right-50 {
    padding-right: 50px !important
}
.padding-bottom-5 {
    padding-bottom: 5px !important
}
.padding-bottom-10 {
    padding-bottom: 10px !important
}
.padding-bottom-20 {
    padding-bottom: 20px !important
}
.padding-bottom-30 {
    padding-bottom: 30px !important
}
.padding-bottom-40 {
    padding-bottom: 40px !important
}
.padding-bottom-50 {
    padding-bottom: 50px !important
}
.no-margin {
    margin: 0 !important
}
.no-margin-bottom {
    margin-bottom: 0 !important
}
.no-margin-top {
    margin-top: 0 !important
}
.no-margin-left {
    margin-left: 0 !important
}
.no-margin-right {
    margin-right: 0 !important
}
.margin-5 {
    margin: 5px !important
}
.margin-10 {
    margin: 10px !important
}
.margin-20 {
    margin: 20px !important
}
.margin-30 {
    margin: 30px !important
}
.margin-40 {
    margin: 40px !important
}
.margin-50 {
    margin: 50px !important
}
.margin-left-5 {
    margin-left: 5px !important
}
.margin-left-10 {
    margin-left: 10px !important
}
.margin-left-20 {
    margin-left: 20px !important
}
.margin-left-30 {
    margin-left: 30px !important
}
.margin-left-40 {
    margin-left: 40px !important
}
.margin-left-50 {
    margin-left: 50px !important
}
.margin-top-5 {
    margin-top: 5px !important
}
.margin-top-10 {
    margin-top: 10px !important
}
.margin-top-20 {
    margin-top: 20px !important
}
.margin-top-30 {
    margin-top: 30px !important
}
.margin-top-40 {
    margin-top: 40px !important
}
.margin-top-50 {
    margin-top: 50px !important
}
.margin-right-5 {
    margin-right: 5px !important
}
.margin-right-10 {
    margin-right: 10px !important
}
.margin-right-20 {
    margin-right: 20px !important
}
.margin-right-30 {
    margin-right: 30px !important
}
.margin-right-40 {
    margin-right: 40px !important
}
.margin-right-50 {
    margin-right: 50px !important
}
.margin-bottom-5 {
    margin-bottom: 5px !important
}
.margin-bottom-10 {
    margin-bottom: 10px !important
}
.margin-bottom-20 {
    margin-bottom: 20px !important
}
.margin-bottom-30 {
    margin-bottom: 30px !important
}
.margin-bottom-40 {
    margin-bottom: 40px !important
}
.margin-bottom-50 {
    margin-bottom: 50px !important
}
.font-10 {
    font-size: 10%
}
.font-20 {
    font-size: 20%
}
.font-30 {
    font-size: 30%
}
.font-40 {
    font-size: 40%
}
.font-50 {
    font-size: 50%
}
.font-60 {
    font-size: 60%
}
.font-70 {
    font-size: 70%
}
.font-80 {
    font-size: 80%
}
.font-90 {
    font-size: 90%
}
.font-110 {
    font-size: 110%
}
.font-120 {
    font-size: 120%
}
.font-130 {
    font-size: 130%
}
.font-140 {
    font-size: 140%
}
.font-150 {
    font-size: 150%
}
.font-160 {
    font-size: 160%
}
.font-170 {
    font-size: 170%
}
.font-180 {
    font-size: 180%
}
.font-190 {
    font-size: 190%
}
.font-200 {
    font-size: 200%
}
button:focus,
a:focus {
    outline: none
}
button::-moz-focus-inner,
a::-moz-focus-inner {
    border: 0
}
.image-circular {
    -webkit-border-radius: 50% !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 50% !important;
    -moz-background-clip: padding !important;
    border-radius: 50% !important;
    background-clip: padding-box !important
}
hr {
    border-top: 1px solid #e5e5e5
}
hr.wide {
    margin-right: -15px;
    margin-left: -15px
}
.position-relative {
    position: relative
}
.horizontal-space {
    max-height: 1px;
    min-height: 1px;
    overflow: hidden;
    margin: 6px 0
}
.horizontal-space.space-xs {
    margin: 2px 0
}
.horizontal-space.space-sm {
    margin: 4px 0
}
.horizontal-space.space-lg {
    margin: 8px 0
}
.horizontal-space.space-xl {
    margin: 10px 0
}
.horizontal-space.space-xxl {
    margin: 12px 0
}
.vertical-space {
    max-width: 1px;
    min-width: 1px;
    overflow: hidden;
    margin: 0 12px
}
html {
    min-height: 100%;
    position: relative;
    overflow-x: hidden
}
body {
    padding-bottom: 0;
    min-height: 100%;
    font-family: 'Tahoma';
    font-size: 13px;
    color: #444
}
body:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #eee
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300 !important;
    font-family: 'Tahoma';
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #444
}
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
    padding-top: 10px;
    padding-bottom: 10px
}
h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%
}
h1.row-title,
h2.row-title,
h3.row-title,
h4.row-title,
h5.row-title,
h6.row-title {
    line-height: 17px;
    margin: 10px 0;
    padding: 9px 10px 9px 8px;
    display: inline-block;
    color: #646464;
    font-weight: 400;
    background-color: #fff;
    position: relative;
    -webkit-border-radius: 0 2px 2px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 2px 2px 0;
    -moz-background-clip: padding;
    border-radius: 0 2px 2px 0;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}
h1.row-title:before,
h2.row-title:before,
h3.row-title:before,
h4.row-title:before,
h5.row-title:before,
h6.row-title:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 4px;
    max-width: 4px;
    overflow: hidden;
    background-color: #2dc3e8
}
h1.row-title .fa,
h2.row-title .fa,
h3.row-title .fa,
h4.row-title .fa,
h5.row-title .fa,
h6.row-title .fa,
h1.row-title .glyphicon,
h2.row-title .glyphicon,
h3.row-title .glyphicon,
h4.row-title .glyphicon,
h5.row-title .glyphicon,
h6.row-title .glyphicon,
h1.row-title .typcn,
h2.row-title .typcn,
h3.row-title .typcn,
h4.row-title .typcn,
h5.row-title .typcn,
h6.row-title .typcn,
h1.row-title [class*="wi-"],
h2.row-title [class*="wi-"],
h3.row-title [class*="wi-"],
h4.row-title [class*="wi-"],
h5.row-title [class*="wi-"],
h6.row-title [class*="wi-"] {
    margin-right: 5px;
    line-height: 17px;
    font-size: 17px
}
h1 {
    font-size: 32px
}
h1.smaller {
    font-size: 31px
}
h1.bigger {
    font-size: 33px
}
h1.block {
    margin-bottom: 16px
}
h2 {
    font-size: 26px
}
h2.smaller {
    font-size: 25px
}
h2.bigger {
    font-size: 27px
}
h2.block {
    margin-bottom: 16px
}
h3 {
    font-size: 22px
}
h3.smaller {
    font-size: 21px
}
h3.bigger {
    font-size: 23px
}
h3.block {
    margin-bottom: 16px
}
h4 {
    font-size: 18px
}
h4.smaller {
    font-size: 17px
}
h4.bigger {
    font-size: 19px
}
h4.block {
    margin-bottom: 16px
}
h5 {
    font-size: 15px
}
h5.smaller {
    font-size: 14px
}
h5.bigger {
    font-size: 16px
}
h5.block {
    margin-bottom: 16px
}
h6 {
    font-size: 13px
}
h6.smaller {
    font-size: 12px
}
h6.bigger {
    font-size: 14px
}
h6.block {
    margin-bottom: 16px
}
.text-align-center {
    text-align: center !important
}
.text-align-left {
    text-align: left !important
}
.text-align-right {
    text-align: right !important
}
.text-align-justify {
    text-align: justify !important
}
.block-center {
    margin: 0 auto
}
.uppercase {
    text-transform: uppercase
}
p {
    line-height: 22px
}
a:focus,
a:active {
    text-decoration: none
}
.navbar {
    padding: 0;
    margin: 0;
    border: none;
    height: 45px;
    min-height: 45px;
    z-index: 200
}
.navbar .navbar-inner {
    border: 0;
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 45px;
    position: relative;
    background: #2dc3e8
}
.navbar .navbar-inner .navbar-container {
    padding-left: 10px
}
.navbar .navbar-inner .navbar-header .navbar-account {
    height: 45px
}
.navbar .navbar-inner .navbar-header .navbar-account>div {
    display: inline-block;
    width: auto;
    vertical-align: top
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area {
    position: absolute;
    top: 0;
    right: 50px;
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    list-style: none;
    margin-bottom: 0;
    display: inline-block
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li {
    float: left;
    position: relative;
    height: 45px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a {
    position: relative;
    min-width: 50px;
    height: 45px;
    display: inline-block
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a:hover {
    text-decoration: none
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a .icon {
    display: block;
    color: #fff;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    -webkit-transition: line-height .3s ease;
    -moz-transition: line-height .3s ease;
    -o-transition: line-height .3s ease;
    transition: line-height .3s ease
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a .badge {
    position: absolute;
    top: 6px;
    left: 4px;
    padding: 2px;
    cursor: pointer;
    background-color: transparent !important;
    border: 1px solid #fff;
    width: 18px;
    height: 18px;
    box-shadow: 1px 1px 0 #2dc3e8;
    -webkit-transition: top .3s ease;
    -moz-transition: top .3s ease;
    -o-transition: top .3s ease;
    transition: top .3s ease
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.wave {
    overflow: visible;
    cursor: pointer;
    z-index: 10;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform .3s cubic-bezier(.68, -.55, .265, 1.55);
    -moz-transition: -moz-transform .3s cubic-bezier(.68, -.55, .265, 1.55);
    -o-transition: -o-transform .3s cubic-bezier(.68, -.55, .265, 1.55);
    transition: -webkit-transform .3s cubic-bezier(.68, -.55, .265, 1.55), -moz-transform .3s cubic-bezier(.68, -.55, .265, 1.55), -o-transform .3s cubic-bezier(.68, -.55, .265, 1.55), transform .3s cubic-bezier(.68, -.55, .265, 1.55)
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.wave.in {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.wave.in:after {
    content: "";
    top: 3px;
    left: 5px;
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: 8121991;
    background-image: -webkit-radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #f5f5f5 100%);
    background-image: -moz-radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #f5f5f5 100%);
    background-image: -o-radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #f5f5f5 100%);
    background-image: radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #f5f5f5 100%);
    border: 1px solid #fff;
    -webkit-animation: wave 5s 3s infinite linear;
    -moz-animation: wave 5s 3s infinite linear;
    -o-animation: wave 5s 3s infinite linear;
    animation: wave 5s 3s infinite linear;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse {
    width: 45px;
    height: 45px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse:after,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse:before {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse:after,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse:before {
    -webkit-transform: scale(.91);
    -moz-transform: scale(.91);
    -o-transform: scale(.91);
    -ms-transform: scale(.91);
    transform: scale(.91);
    -webkit-animation: borderPulse 3.5s infinite ease;
    -moz-animation: borderPulse 3.5s infinite ease;
    -o-animation: borderPulse 3.5s infinite ease;
    animation: borderPulse 3.5s infinite ease
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse .badge {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse:before {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse i,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.borderpulse .badge {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -o-animation-delay: .3s;
    animation-delay: .3s
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.blink i,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.blink .badge {
    -webkit-transform: scale(.91);
    -moz-transform: scale(.91);
    -o-transform: scale(.91);
    -ms-transform: scale(.91);
    transform: scale(.91);
    -webkit-animation: borderPulse 3.5s infinite ease;
    -moz-animation: borderPulse 3.5s infinite ease;
    -o-animation: borderPulse 3.5s infinite ease;
    animation: borderPulse 3.5s infinite ease
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a.blink i {
    -webkit-animation-delay: .05s;
    -moz-animation-delay: .05s;
    -o-animation-delay: .05s;
    animation-delay: .05s
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu {
    padding: 0;
    min-width: 240px;
    -webkit-animation-name: dropdownSlideInDown;
    -moz-animation-name: dropdownSlideInDown;
    -o-animation-name: dropdownSlideInDown;
    animation-name: dropdownSlideInDown;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-border-radius: 0 !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 0 !important;
    -moz-background-clip: padding !important;
    border-radius: 0 !important;
    background-clip: padding-box !important
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li {
    background-color: #fff;
    border: 0;
    padding: 0 8px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li:hover {
    background-color: #f5f5f5
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li a {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 10px 2px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li a .progress {
    margin-bottom: 0;
    margin-top: 4px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li a:hover {
    background-color: #f5f5f5
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-header,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-footer {
    -webkit-text-shadow: none;
    text-shadow: none;
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    color: #262626
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-header {
    padding: 0 10px;
    line-height: 34px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cecece
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-header a {
    line-height: 34px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-header[class*="bordered-"] {
    border-bottom-width: 3px;
    border-style: solid
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-header i {
    margin-right: 5px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-footer {
    padding: 8px 5px 8px 10px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-footer[class*="bordered-"] {
    border-top-width: 3px;
    border-style: solid
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-footer a {
    display: inline-block;
    color: #999;
    padding: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu li.dropdown-footer .btn {
    margin-top: -3px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications {
    min-width: 300px;
    padding-top: 10px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li {
    border-bottom: 1px solid #f3f3f3
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-icon {
    float: left
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-icon i {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    font-size: 15px;
    border-radius: 50%
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-body {
    float: left
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-body .title {
    display: block;
    font-size: 13px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-body .description {
    display: block;
    font-size: 10px;
    color: #999
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-extra {
    float: right;
    text-align: right
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-extra i {
    font-size: 14px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li .notification-extra .description {
    display: block;
    font-size: 10px;
    color: #999
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li.dropdown-footer {
    background-color: #11a9cc;
    border-bottom: 0;
    color: #fff
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li.dropdown-footer a {
    color: #fff
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications li.dropdown-footer .weather-icon {
    margin-top: -7px;
    margin-left: 5px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages {
    width: 300px;
    padding-top: 10px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li {
    border-bottom: 1px solid #f3f3f3
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message-avatar {
    max-width: 42px;
    margin-right: 6px;
    margin-top: 5px;
    vertical-align: top
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message {
    display: inline-block;
    position: relative;
    width: 225px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message span {
    display: block
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message-sender {
    font-size: 13px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message-time {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 11px;
    font-weight: 400;
    color: #2dc3e8
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message-subject {
    margin-top: 5px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages li .message-body {
    color: #999;
    max-height: 35px;
    width: 100%;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li {
    border-bottom: 1px solid #f5f5f5
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li:hover,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li a:hover {
    background-color: #fff
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.username {
    display: none;
    text-align: center
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.username,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.username a {
    color: #d0d0d0;
    font-size: 14px;
    font-weight: 300
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.email {
    text-align: center;
    font-size: 12px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.email,
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.email a {
    color: #d0d0d0
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li .avatar-area {
    position: relative;
    text-align: center;
    margin: 10px auto;
    width: 128px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li .avatar-area .avatar {
    width: 128px;
    height: 128px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li .avatar-area .caption {
    background-color: #2dc3e8;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 10px;
    line-height: 9px;
    position: absolute;
    padding: 7px 0;
    text-align: center;
    width: 128px;
    cursor: pointer
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.edit {
    min-height: 35px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.edit a {
    color: #999;
    display: inline-block;
    clear: none;
    font-size: 13px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.edit a:hover {
    color: #11a9cc
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area {
    display: table;
    width: 100%;
    padding-bottom: 5px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .colorpicker {
    padding: 10px 4px;
    width: 100%
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .colorpicker>li {
    display: block;
    float: left;
    width: 23px;
    height: 23px;
    margin: 2px;
    padding: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .colorpicker>li>.colorpick-btn {
    display: block;
    width: 23px;
    height: 23px;
    margin: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    position: relative;
    -webkit-transition: all ease .1s;
    -moz-transition: all ease .1s;
    -o-transition: all ease .1s;
    transition: all ease .1s
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .colorpicker>li>.colorpick-btn:hover {
    text-decoration: none;
    zoom: 1;
    filter: alpha(opacity=80);
    -webkit-opacity: .8;
    -moz-opacity: .8;
    opacity: .8;
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -o-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08)
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .colorpicker>li>.colorpick-btn.selected:after {
    content: "";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.theme-area .btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    vertical-align: middle;
    border-radius: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.dropdown-footer {
    padding: 10px;
    border-top: 3px solid #2dc3e8;
    border-bottom: 0;
    text-align: right
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.dropdown-footer a {
    color: #717171;
    font-size: 13px;
    font-weight: 300
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-arrow:before {
    content: '';
    display: inline-block;
    right: 0;
    position: absolute;
    width: 50px;
    background-color: #fff;
    border: 0;
    top: -46px;
    height: 3px;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 40px rgba(0, 0, 0, .4)
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li.open>a {
    background-color: #2dc3e8;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 40px rgba(0, 0, 0, .4)
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area>li.open .dropdown-menu {
    margin-top: 0;
    right: 0;
    border: 0 !important
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area {
    padding: 8px 20px 10px 20px;
    height: 45px;
    opacity: 1;
    position: relative;
    cursor: pointer
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area .avatar {
    margin-right: 14px;
    display: block;
    float: left;
    border-left: 2px solid #fb6e52;
    width: 29px;
    height: 29px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area .avatar img {
    width: 29px;
    height: 29px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area section {
    width: auto;
    display: block;
    overflow: hidden;
    line-height: 25px;
    float: left
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area section h2 {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    margin: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area section h2 span {
    display: block;
    color: #fff;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    text-decoration: none;
    line-height: 25px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area+.dropdown-menu {
    width: 100%;
    min-width: 100px
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area+.dropdown-arrow:before {
    width: 100%
}
.navbar .navbar-inner .navbar-header .navbar-account .setting {
    width: 50px;
    position: absolute;
    right: 0;
    top: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .setting a {
    position: relative;
    width: 50px;
    height: 45px;
    display: inline-block;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}
.navbar .navbar-inner .navbar-header .navbar-account .setting a:hover {
    text-decoration: none
}
.navbar .navbar-inner .navbar-header .navbar-account .setting a .icon {
    display: block;
    color: #fff;
    font-size: 22px;
    line-height: 45px;
    text-align: center
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container {
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: inline-block;
    width: 505px;
    height: 45px;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    text-align: center;
    padding: 11px 0;
    position: absolute;
    top: 0;
    right: 0
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox] {
    display: inline-block
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]+.text {
    color: #fff;
    margin-right: 8px;
    font-weight: 300
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]+.text:before {
    border-color: #2dc3e8
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]:checked+.text {
    color: #fff
}
.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]:checked+.text:before {
    border-color: #2dc3e8;
    color: #fb6e52
}
.navbar .navbar-inner .navbar-header .navbar-account .account-area,
.navbar .navbar-inner .navbar-header .navbar-account .setting-container,
.navbar .navbar-inner .navbar-header .navbar-account .setting {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: .9s;
    -moz-transition: .9s;
    -o-transition: .9s;
    transition: .9s
}
.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
    right: 505px;
    background-color: #2dc3e8
}
.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting a {
    -webkit-box-shadow: 0 -6px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 -6px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 -6px 40px rgba(0, 0, 0, .4)
}
.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting a:before {
    content: '';
    display: inline-block;
    right: 0;
    position: absolute;
    width: 50px;
    background-color: #fff;
    border: 0;
    top: 0;
    height: 3px
}
.navbar .navbar-inner .navbar-header .navbar-account.setting-open .account-area {
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -o-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    right: 450px
}
.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    padding: 11px
}
.navbar .navbar-brand {
    color: #fff;
    font-size: 24px;
    -webkit-text-shadow: none;
    text-shadow: none;
    padding: 2px 0 2px 0;
    font-weight: 200;
    height: 45px
}
.navbar .navbar-brand small img {
    height: 40px;
    width: 177px
}
.navbar .sidebar-collapse {
    text-align: center;
    padding: 0;
    position: absolute;
    left: 189px;
    top: 0;
    z-index: 2;
    width: 35px;
    height: 45px
}
.navbar .sidebar-collapse:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}
.navbar .sidebar-collapse .collapse-icon {
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding: 11px 5px;
    line-height: 22px;
    position: relative;
    -webkit-transition: background-color .218s ease;
    -moz-transition: background-color .218s ease;
    -o-transition: background-color .218s ease;
    transition: background-color .218s ease;
    -webkit-transition: -webkit-box-shadow .218s ease;
    -moz-transition: -moz-box-shadow .218s ease;
    -o-transition: box-shadow .218s ease;
    transition: box-shadow .218s ease;
    -webkit-transition: color .318s ease;
    -moz-transition: color .318s ease;
    -o-transition: color .318s ease;
    transition: color .318s ease
}
.navbar .sidebar-collapse.active:hover {
    box-shadow: none
}
.navbar .sidebar-collapse.active .collapse-icon {
    font-size: 14px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    padding: 11px
}
.navbar.navbar-fixed-top {
    z-index: 1003
}
.navbar.navbar-fixed-top+.main-container {
    margin-top: 45px
}
@media only screen and (max-width: 767px) {
    .navbar-container {
        padding-left: 2px
    }
}
@media only screen and (max-width: 760px) {
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
        top: 45px
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
        top: 45px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-login-area>li.username {
        display: block
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area {
        padding: 8px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area section {
        display: none
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area .avatar {
        margin-right: 0;
        margin-left: 1px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area+.dropdown-menu {
        min-width: 200px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area .login-area+.dropdown-menu:before {
        width: 50px
    }
}
@media only screen and (max-width: 759px) {
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
        width: 270px;
        top: 0;
        padding: 0 5px;
        text-align: left
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container label {
        margin-bottom: 0
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
        right: 270px;
        top: 0
    }
}
@media only screen and (max-width: 530px) {
    .navbar {
        height: 90px
    }
    .navbar .sidebar-collapse {
        left: auto;
        right: 0;
        width: 50px
    }
    .navbar .sidebar-collapse .collpse-icon {
        padding: 11px 15px
    }
    .navbar .navbar-brand {
        padding-left: 10px
    }
    .navbar .navbar-inner .navbar-header {
        float: none !important;
        display: block
    }
    .navbar .navbar-inner .navbar-header .navbar-account {
        float: right
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area {
        padding-left: 0;
        top: 45px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications {
        left: -135px;
        right: auto
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications.dropdown-arrow:before {
        left: 135px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages {
        left: -135px;
        right: auto
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages.dropdown-arrow:before {
        left: 135px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .setting {
        top: 45px !important
    }
    .navbar .navbar-inner .navbar-header .navbar-account .setting-container {
        top: 45px !important
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
        left: 50px
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
        left: 0
    }
    .navbar .navbar-inner .navbar-container {
        padding-left: 0
    }
    .navbar-fixed-top+.main-container {
        margin-top: 90px !important
    }
    .breadcrumbs-fixed {
        top: 90px !important
    }
    .breadcrumbs-fixed+.page-header {
        margin-top: 40px !important
    }
    .page-header-fixed {
        top: 130px !important
    }
    .page-header-fixed+.page-body {
        margin-top: 80px !important
    }
    .page-sidebar.sidebar-fixed {
        top: 90px !important
    }
}
@media only screen and (max-width: 450px) {
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
        width: 270px;
        padding: 0 5px;
        text-align: left;
        left: 50px
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container label {
        margin-bottom: 0
    }
    .navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
        left: 0
    }
}
@media only screen and (max-width: 400px) {
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications {
        left: -55px;
        right: auto
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-notifications.dropdown-arrow:before {
        left: 55px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages {
        left: -105px;
        right: auto
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-messages.dropdown-arrow:before {
        left: 105px
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-tasks {
        left: -100px;
        right: auto
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-menu.dropdown-tasks.dropdown-arrow:before {
        left: 100px
    }
}
@media only screen and (max-width: 320px) {
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li .dropdown-arrow:before {
        width: 45px !important
    }
    .navbar .navbar-inner .navbar-header .navbar-account .account-area>li>a {
        min-width: 45px
    }
}
.main-container {
    position: static;
    padding: 0
}
.main-container>.page-container {
    position: relative
}
.main-container:after {
    background-color: #fff;
    bottom: 0;
    content: "";
    display: block;
    max-width: inherit;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -2;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3)
}
.page-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 224px;
    display: block
}
.page-sidebar.sidebar-fixed {
    position: fixed;
    top: 45px;
    float: none !important;
    z-index: 1000
}
.page-sidebar.sidebar-fixed:before {
    height: 5000px;
    top: 45px;
    bottom: auto
}
.page-sidebar.hide {
    display: none !important
}
.page-sidebar.hide:before {
    display: none
}
.page-sidebar.hide~.page-content {
    margin-left: 0 !important
}
.page-sidebar.hide~.page-content .breadcrumbs-fixed {
    left: 0 !important
}
.page-sidebar.hide~.page-content .page-header-fixed {
    left: 0 !important
}
.page-sidebar:before {
    content: "";
    position: fixed;
    display: block;
    width: 219px;
    bottom: 0;
    top: 0;
    left: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    z-index: 1
}
.page-sidebar .sidebar-header-wrapper {
    margin-left: 5px;
    height: 40px;
    margin-bottom: 0;
    position: relative
}
.page-sidebar .sidebar-header-wrapper .searchinput {
    height: 41px;
    z-index: 2;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #fff;
    padding-left: 40px;
    width: 100%
}
.page-sidebar .sidebar-header-wrapper .searchinput:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #fff
}
.page-sidebar .sidebar-header-wrapper .searchinput:focus~.searchhelper {
    display: block;
    z-index: 124
}
.page-sidebar .sidebar-header-wrapper .searchinput::-webkit-input-placeholder {
    color: #d0d0d0;
    font-weight: 300;
    font-family: 'Arial'
}
.page-sidebar .sidebar-header-wrapper .searchinput::-moz-placeholder {
    color: #d0d0d0;
    font-weight: 300;
    font-family: 'Arial'
}
.page-sidebar .sidebar-header-wrapper .searchinput:-moz-placeholder {
    color: #d0d0d0;
    font-weight: 300;
    font-family: 'Arial'
}
.page-sidebar .sidebar-header-wrapper .searchinput:-ms-input-placeholder {
    color: #d0d0d0;
    font-weight: 300;
    font-family: 'Arial'
}
.page-sidebar .sidebar-header-wrapper .searchicon {
    cursor: pointer;
    display: inline-block;
    height: 12px;
    left: 20px;
    position: absolute;
    top: 14px;
    width: 12px;
    color: #2dc3e8;
    z-index: 124
}
.page-sidebar .sidebar-header-wrapper .searchhelper {
    -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    background-color: #fff;
    color: #999;
    display: none;
    opacity: 1;
    padding: 10px;
    position: absolute;
    right: 0;
    left: 0;
    top: 40px;
    z-index: 4
}
.page-sidebar .sidebar-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 5px
}
.page-sidebar .sidebar-menu a {
    display: block;
    height: 38px;
    line-height: 36px;
    margin: 0;
    padding: 0 16px 0 7px;
    -webkit-text-shadow: none !important;
    text-shadow: none !important;
    font-size: 13px;
    text-decoration: none;
    color: #737373;
    z-index: 123
}
.page-sidebar .sidebar-menu a:hover {
    color: #262626
}
.page-sidebar .sidebar-menu a:hover:before {
    display: block
}
.page-sidebar .sidebar-menu a .menu-icon {
    display: inline-block;
    vertical-align: middle;
    min-width: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    margin-top: -3px
}
.page-sidebar .sidebar-menu a .menu-expand {
    display: inline-block;
    position: absolute;
    font-size: 13px;
    line-height: 10px;
    height: 10px;
    width: 10px;
    right: 12px;
    top: 15px;
    margin: 0;
    text-align: center;
    padding: 0;
    -webkit-text-shadow: none;
    text-shadow: none;
    color: #666;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: normal
}
.page-sidebar .sidebar-menu a .menu-expand:before {
    content: ""
}
.page-sidebar .sidebar-menu li {
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    position: relative
}
.page-sidebar .sidebar-menu li:before {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 4px;
    max-width: 4px;
    overflow: hidden;
    background-color: #2dc3e8
}
.page-sidebar .sidebar-menu li.open {
    background-color: #fff
}
.page-sidebar .sidebar-menu li.open>a {
    color: #262626
}
.page-sidebar .sidebar-menu li.open>a .menu-expand {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}
.page-sidebar .sidebar-menu li.open>.submenu {
    display: block !important
}
.page-sidebar .sidebar-menu li.active:not(.open):before {
    display: block
}
.page-sidebar .sidebar-menu li.active>a {
    display: block;
    content: "";
    color: #262626;
    background-color: #f5f5f5
}
.page-sidebar .sidebar-menu li:not(.open):hover:before {
    display: block;
    background-color: #fb6e52
}
.page-sidebar .sidebar-menu>li>a {
    border-top: 1px solid #f3f3f3
}
.page-sidebar .sidebar-menu>li>a:hover {
    border-color: #f3f3f3;
    background-color: #fbfbfb
}
.page-sidebar .sidebar-menu>li:last-child>a {
    border-bottom: 1px solid #f3f3f3
}
.page-sidebar .sidebar-menu>li>.submenu {
    background-color: #fbfbfb;
    -webkit-box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15), inset 0 -4px 4px -2px rgba(0, 0, 0, .15);
    -moz-box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15), inset 0 -4px 4px -2px rgba(0, 0, 0, .15);
    box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15), inset 0 -4px 4px -2px rgba(0, 0, 0, .15)
}
.page-sidebar .sidebar-menu>li>.submenu:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 23px;
    top: 0;
    bottom: 19px;
    border-left: 1px solid #e2e2e2
}
.page-sidebar .sidebar-menu>li>.submenu>li>a:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 5px;
    height: 5px;
    left: 21px;
    top: 17px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    z-index: 2
}
.page-sidebar .sidebar-menu>li>.submenu>li.active>a:before {
    background-color: #e2e2e2
}
.page-sidebar .sidebar-menu>li>.submenu>li.active:first-child>a {
    -webkit-box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15);
    -moz-box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15);
    box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .15)
}
.page-sidebar .sidebar-menu>li>.submenu>li.active:last-child a {
    -webkit-box-shadow: inset 0 -4px 4px -2px rgba(0, 0, 0, .15);
    -moz-box-shadow: inset 0 -4px 4px -2px rgba(0, 0, 0, .15);
    box-shadow: inset 0 -4px 4px -2px rgba(0, 0, 0, .15)
}
.page-sidebar .sidebar-menu>li.open>a {
    background-color: #fff
}
.page-sidebar .sidebar-menu .submenu {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #fbfbfb;
    border-top: 0;
    z-index: 123
}
.page-sidebar .sidebar-menu .submenu li {
    margin-left: 0;
    position: relative
}
.page-sidebar .sidebar-menu .submenu li.open:before {
    display: none
}
.page-sidebar .sidebar-menu .submenu li.active>a {
    color: #262626;
    background-color: #f5f5f5
}
.page-sidebar .sidebar-menu .submenu li.active>.submenu {
    display: block
}
.page-sidebar .sidebar-menu .submenu>li>a {
    padding-left: 40px
}
.page-sidebar .sidebar-menu .submenu>li.open>a {
    background-color: #fbfbfb
}
.page-sidebar .sidebar-menu .submenu>li .submenu {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee
}
.page-sidebar .sidebar-menu .submenu>li .submenu>li>a {
    padding-left: 50px
}
.page-sidebar .sidebar-menu .submenu>li .submenu>li .submenu>li>a {
    padding-left: 70px
}
.page-sidebar.menu-compact {
    width: 42px
}
.page-sidebar.menu-compact~.page-content {
    margin-left: 43px
}
.page-sidebar.menu-compact~.page-content .breadcrumbs-fixed {
    left: 47px
}
.page-sidebar.menu-compact~.page-content .page-header-fixed {
    left: 47px
}
.page-sidebar.menu-compact:before {
    width: 42px
}
.page-sidebar.menu-compact .sidebar-header-wrapper {
    width: 42px
}
.page-sidebar.menu-compact .sidebar-header-wrapper:hover {
    width: 238px
}
.page-sidebar.menu-compact .sidebar-header-wrapper:hover .searchinput {
    -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 10px 40px rgba(0, 0, 0, .4)
}
.page-sidebar.menu-compact .sidebar-header-wrapper .searchinput:focus {
    width: 238px;
    -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 10px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 10px 40px rgba(0, 0, 0, .4)
}
.page-sidebar.menu-compact .sidebar-header-wrapper .searchinput:focus~.searchhelper {
    width: 238px
}
.page-sidebar.menu-compact .sidebar-header-wrapper .searchicon {
    left: 15px
}
.page-sidebar.menu-compact .sidebar-menu {
    width: 42px
}
.page-sidebar.menu-compact .sidebar-menu>li.open>.menu-text {
    display: none !important
}
.page-sidebar.menu-compact .sidebar-menu>li.open>.submenu {
    display: none !important
}
.page-sidebar.menu-compact .sidebar-menu>li:hover>a {
    color: #262626
}
.page-sidebar.menu-compact .sidebar-menu>li:hover>a>.menu-text {
    display: block
}
.page-sidebar.menu-compact .sidebar-menu>li:hover>.submenu {
    display: block !important
}
.page-sidebar.menu-compact .sidebar-menu>li>a {
    position: relative;
    z-index: 123
}
.page-sidebar.menu-compact .sidebar-menu>li>a .menu-text {
    display: none;
    position: absolute;
    left: 42px;
    top: 0;
    width: 195px;
    height: 38px;
    line-height: 38px;
    background-color: #fff;
    z-index: 121;
    padding-left: 25px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3);
    -moz-box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3);
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3)
}
.page-sidebar.menu-compact .sidebar-menu>li>a .menu-expand {
    display: none
}
.page-sidebar.menu-compact .sidebar-menu>li>a.menu-dropdown {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.page-sidebar.menu-compact .sidebar-menu>li>a.menu-dropdown>.menu-text {
    border: 0;
    top: 0;
    left: 42px;
    width: 195px;
    border-bottom: 1px solid #f3f3f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.page-sidebar.menu-compact .sidebar-menu>li>a:hover {
    background-color: #fff
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu {
    background: #fbfbfb;
    position: absolute;
    z-index: 120;
    left: 42px;
    top: 0;
    width: 195px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding-top: 38px;
    display: none;
    border-left: 1px solid #f3f3f3;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3);
    -moz-box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3);
    box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px 5px 5px -5px rgba(0, 0, 0, .3), 5px -5px 5px -5px rgba(0, 0, 0, .3)
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li>a {
    margin-left: 0;
    padding-left: 24px
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li.open>.submenu {
    display: block;
    left: 0;
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li .submenu {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li:hover {
    background-color: #fff
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li:hover:before {
    display: block;
    left: 0
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu li.active:not(.open):before {
    left: 0
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu:before {
    display: none
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu>li>a {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important
}
.page-sidebar.menu-compact .sidebar-menu>li>.submenu>li>a:before {
    display: none
}
@media only screen and (max-width: 880px) {
    .page-sidebar:before {
        display: none
    }
    .page-sidebar {
        display: none;
        float: none
    }
    .breadcrumbs-fixed {
        left: 0 !important
    }
    .page-header-fixed {
        left: 0 !important
    }
}
@media(max-width:880px) {
    .page-sidebar.hide {
        display: block !important
    }
    .page-sidebar.hide:before {
        display: block !important
    }
    .page-sidebar.hide~.page-content {
        margin-left: 224px !important
    }
    .page-sidebar.hide~.page-content .breadcrumbs-fixed {
        left: 224px !important
    }
    .page-sidebar.hide~.page-content .page-header-fixed {
        left: 224px !important
    }
    .page-sidebar.menu-compact~.page-content {
        margin-left: 0
    }
    .page-sidebar.menu-compact~.page-content .breadcrumbs-fixed {
        left: 0
    }
    .page-sidebar.menu-compact~.page-content .page-header-fixed {
        left: 0
    }
    .page-sidebar.menu-compact.hide~.page-content {
        margin-left: 46px !important
    }
    .page-sidebar.menu-compact.hide~.page-content .breadcrumbs-fixed {
        left: 46px !important
    }
    .page-sidebar.menu-compact.hide~.page-content .page-header-fixed {
        left: 46px !important
    }
}
@media(max-width:500px) {
    .page-sidebar.hide~.page-content .page-header .header-title {
        display: none
    }
    .page-sidebar.hide~.page-content .page-header .header-buttons .refresh,
    .page-sidebar.hide~.page-content .page-header .header-buttons .fullscreen {
        display: none
    }
    .page-sidebar.menu-compact.hide~.page-content .page-header .header-buttons .refresh,
    .page-sidebar.menu-compact.hide~.page-content .page-header .header-buttons .fullscreen {
        display: inline-block
    }
}
.page-content {
    display: block;
    margin-left: 224px;
    margin-right: 0;
    margin-top: 0;
    min-height: 100%;
    padding: 0
}
@media only screen and (max-width: 880px) {
    .page-content {
        margin-left: 0
    }
}
.page-breadcrumbs {
    position: relative;
    background: #f3f3f3;
    min-height: 40px;
    line-height: 39px;
    padding: 0;
    display: block;
    z-index: 1;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15)
}
.page-breadcrumbs.breadcrumbs-fixed {
    position: fixed;
    right: 0;
    left: 224px;
    top: 45px;
    z-index: 999
}
.page-breadcrumbs.breadcrumbs-fixed+.page-header {
    margin-top: 40px
}
.breadcrumb {
    background-color: transparent;
    display: inline-block;
    line-height: 24px;
    margin: 0 22px 0 12px;
    padding: 0;
    font-size: 13px;
    color: #333;
    border-radius: 0
}
.breadcrumb>li,
.breadcrumb>li.active {
    color: #777;
    -webkit-text-shadow: none;
    text-shadow: none
}
.breadcrumb>li+li:before {
    color: #999
}
.breadcrumb>li>.divider {
    padding: 0 4px
}
.breadcrumb>li>a {
    display: inline-block;
    color: #2dc3e8
}
.breadcrumb>li:first-child>a {
    padding-left: 4px
}
.breadcrumb>li>i {
    margin-left: 4px;
    margin-right: 2px;
    font-size: 20px;
    position: relative;
    top: 2px
}
.breadcrumb .expand-icon {
    height: 22px;
    padding: 0;
    margin: 0;
    position: relative;
    top: 1px;
    font-size: 14px;
    color: #b2b6bf
}
.page-header {
    position: relative;
    margin: 0;
    padding-bottom: 1px;
    padding-left: 12px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YzZjNmMyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(left, #fff, #f3f3f3);
    background-image: -moz-linear-gradient(left, #fff, #f3f3f3);
    background-image: -o-linear-gradient(left, #fff, #f3f3f3);
    background-image: linear-gradient(to right, #fff, #f3f3f3);
    z-index: 0;
    min-height: 40px;
    border-bottom: 1px solid #e5e5e5;
    height: 40px
}
.page-header .header-title {
    display: inline-block;
    position: relative;
    height: 40px
}
.page-header .header-title h1 {
    padding: 0;
    margin: 0 4px;
    font-size: 17px;
    font-weight: lighter;
    color: #737373;
    line-height: 37px;
    display: inline-block
}
.page-header .header-title h1 small {
    margin: 0 1px;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
    color: #888
}
.page-header .header-buttons {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0
}
.page-header .header-buttons a {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    z-index: 1029;
    height: 40px;
    width: 36px;
    margin-right: 5px;
    padding: 8px 10px;
    color: #dfdfdf;
    -webkit-transition: all .218s ease;
    -moz-transition: all .218s ease;
    -o-transition: all .218s ease;
    transition: all .218s ease;
    float: right
}
.page-header .header-buttons a:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    right: 0;
    height: 4px;
    max-height: 4px;
    overflow: hidden;
    background-color: #2dc3e8
}
.page-header .header-buttons a:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    color: #2dc3e8
}
.page-header .header-buttons a.active {
    color: #2dc3e8
}
.page-header .header-buttons a i {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    line-height: 27px
}
.page-header .header-buttons a.fullscreen:before {
    background-color: #fb6e52
}
.page-header .header-buttons a.fullscreen:hover,
.page-header .header-buttons a.fullscreen.active {
    color: #fb6e52
}
.page-header .header-buttons a.refresh:before {
    background-color: #ffce55
}
.page-header .header-buttons a.refresh:hover,
.page-header .header-buttons a.refresh.active {
    color: #ffce55
}
.page-header .header-buttons a.sidebar-toggler:before {
    background-color: #2dc3e8
}
.page-header .header-buttons a.sidebar-toggler:hover,
.page-header .header-buttons a.sidebar-toggler.active {
    color: #2dc3e8
}
.page-header.mail-header {
    border-bottom: 1px solid #eee
}
.page-header.mail-header .header-buttons a:before {
    display: none
}
.page-header.mail-header .header-buttons a:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.page-header.page-header-fixed {
    position: fixed;
    right: 0;
    left: 224px;
    top: 85px;
    z-index: 998;
    margin-top: 0 !important
}
.page-header.page-header-fixed+.page-body {
    margin-top: 80px
}
@media only screen and (max-width: 520px) {
    .header-title h1 small {
        display: none
    }
}
.page-body {
    background: #eee;
    padding: 18px 20px 24px
}
.widget {
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 0 0 30px 0
}
.widget.collapsed .widget-body {
    display: none
}
.widget.collapsed.radius-bordered .widget-header {
    -webkit-border-radius: 3px 3px 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 3px 3px;
    -moz-background-clip: padding;
    border-radius: 3px 3px 3px 3px;
    background-clip: padding-box
}
.widget.no-header .widget-header {
    display: none
}
.widget.no-header.radius-bordered .widget-body {
    -webkit-border-radius: 3px 3px 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 3px 3px;
    -moz-background-clip: padding;
    border-radius: 3px 3px 3px 3px;
    background-clip: padding-box
}
.widget.maximized {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    margin: 0;
    overflow: auto
}
.widget.maximized .widget-body {
    padding: 12px 0
}
.widget.transparent .widget-header,
.widget.transparent .widget-body {
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.widget.transparent .widget-header {
    border-bottom: 1px solid #ccc
}
.widget.bordered-top {
    border-top: 3px solid #fff
}
.widget.bordered-bottom {
    border-bottom: 3px solid #fff
}
.widget.bordered-left {
    border-left: 3px solid #fff
}
.widget.bordered-right {
    border-right: 3px solid #fff
}
.widget.radius-bordered .widget-header {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
}
.widget.radius-bordered .widget-body {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
}
.widget.flat .widget-body,
.widget.flat .widget-header {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.widget.lightshadow .widget-body,
.widget.lightshadow .widget-header {
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2)
}
.widget:hover .compact {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}
.widget:hover .compact i {
    color: #2dc3e8
}
.widget-header {
    position: relative;
    min-height: 35px;
    background: #fff;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    color: #555;
    padding-left: 12px;
    text-align: right
}
.widget-header .widget-icon {
    display: block;
    width: 30px;
    height: 32px;
    position: relative;
    float: left;
    font-size: 111%;
    line-height: 32px;
    text-align: center;
    margin-left: -10px
}
.widget-header>.widget-caption {
    line-height: 34px;
    padding: 0;
    margin: 0;
    float: left;
    text-align: left;
    font-weight: 400 !important;
    font-size: 13px
}
.widget-header.lined {
    border: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding-left: 2%
}
.widget-header.lined:before {
    content: "";
    position: absolute;
    display: block;
    width: 96%;
    bottom: 0;
    top: 35px;
    height: 1px;
    left: 2%;
    z-index: 1;
    border-bottom: 1px solid #e5e5e5
}
.widget-header.lined .widget-buttons {
    padding-right: 2%
}
.widget-header.lined+.widget-body {
    padding-left: 2%;
    padding-right: 2%
}
.widget-header.separated {
    margin-bottom: 5px
}
.widget-header[class*="bg-"] {
    border: 0
}
.widget-header[class*="bg-"] .widget-caption,
.widget-header[class*="bg-"] i {
    color: #fff
}
.widget-header.bordered-left {
    border-left: 3px solid #fff
}
.widget-header.bordered-right {
    border-right: 3px solid #fff
}
.widget-header.bordered-top {
    border-top: 3px solid #fff
}
.widget-header.bordered-bottom {
    border-bottom: 3px solid #fff
}
.widget-header.header-large {
    min-height: 49px;
    padding-left: 18px
}
.widget-header.header-large h5 {
    line-height: 48px;
    font-size: 16px
}
.widget-header.header-large>.widget-buttons {
    line-height: 48px;
    height: 48px
}
.widget-header.header-large>.widget-buttons a {
    min-width: 26px
}
.widget-header.header-large>.widget-buttons a i {
    font-size: 20px
}
.widget-header.header-small {
    min-height: 29px;
    padding-left: 10px
}
.widget-header.header-small h5 {
    line-height: 28px;
    font-size: 12px
}
.widget-header.header-small>.widget-buttons {
    line-height: 29px;
    height: 29px
}
.widget-header.header-small>.widget-buttons a {
    min-width: 16px;
    height: 16px
}
.widget-header.header-small>.widget-buttons a i {
    font-size: 14px
}
.widget-buttons {
    display: inline-block;
    padding: 0 5px;
    line-height: 34px;
    position: relative;
    text-align: left;
    height: 36px
}
.widget-buttons.buttons-bordered {
    border-left: 1px solid #e5e5e5
}
.widget-buttons.compact {
    margin-top: 4px;
    background-color: #f5f5f5;
    line-height: 27px;
    -webkit-transition: background-color .218s ease;
    -moz-transition: background-color .218s ease;
    -o-transition: background-color .218s ease;
    transition: background-color .218s ease;
    -webkit-transition: -webkit-box-shadow .218s ease;
    -moz-transition: -moz-box-shadow .218s ease;
    -o-transition: box-shadow .218s ease;
    transition: box-shadow .218s ease;
    -webkit-transition: color .318s ease;
    -moz-transition: color .318s ease;
    -o-transition: color .318s ease;
    transition: color .318s ease;
    height: 27px
}
.widget-buttons.compact:hover {
    background-color: #2dc3e8;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    box-shadow: 0 0 20px rgba(0, 0, 0, .2)
}
.widget-buttons.compact:hover i {
    color: #fff
}
.widget-buttons.compact:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -4px;
    width: 4px;
    max-width: 4px;
    overflow: hidden;
    background-color: #2dc3e8
}
.widget-buttons.compact a {
    min-width: 14px;
    line-height: 14px
}
.widget-buttons.compact i {
    color: #ccc;
    font-size: 14px
}
.widget-buttons.no-padding {
    padding: 0
}
.widget-buttons.padding-5 {
    padding: 0 5px
}
.widget-buttons.no-border:before {
    display: none
}
.widget-buttons label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 6px
}
.widget-buttons>a {
    font-size: 14px;
    margin: 0 1px;
    display: inline-block;
    padding: 0;
    line-height: 24px;
    min-width: 20px;
    text-align: center
}
.widget-buttons>a:hover {
    text-decoration: none
}
.widget-buttons>a i {
    vertical-align: middle
}
.widget-buttons>[data-toggle]>.fa {
    margin-right: 0
}
.widget-buttons>[data-toggle]:hover {
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.widget-buttons>[data-toggle]:focus {
    text-decoration: none
}
.widget-buttons>[data-toggle="dispose"] {
    color: #777;
    font-size: 14px
}
.widget-buttons>[data-toggle="maximize"] {
    color: #777;
    font-size: 14px
}
.widget-buttons>[data-toggle="collapse"] {
    color: #777;
    font-size: 14px
}
.widget-buttons>[data-toggle="refresh"] {
    color: #777;
    font-size: 14px
}
.widget-buttons>[data-toggle="config"] {
    color: #777;
    font-size: 14px
}
.widget-buttons .progress {
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    min-width: 100px;
    margin-top: -3px
}
.widget-buttons .btn-group {
    margin-top: -2px
}
.widget-buttons .btn-group .dropdown-menu {
    right: 0;
    left: auto
}
.widget-buttons .badge {
    margin-top: -2px
}
.widget-buttons .label {
    padding: 5px 6px 5px 6px
}
.widget-buttons .pagination,
.widget-buttons .pager {
    float: right;
    margin: 5px 2px 1px
}
.widget-buttons .btn {
    margin-top: -2px
}
.widget-body {
    background-color: #fbfbfb;
    -webkit-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    -moz-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    padding: 12px
}
.widget-body.bordered-left {
    border-left: 3px solid #fff
}
.widget-body.bordered-right {
    border-right: 3px solid #fff
}
.widget-body.bordered-bottom {
    border-bottom: 3px solid #fff
}
.widget-body.bordered-top {
    border-top: 3px solid #fff
}
.widget-body[class*="bg-"] {
    color: #fff
}
.widget-body.no-padding {
    padding: 0
}
.widget-body.no-padding .accordion {
    border: 0
}
.widget-body.no-padding .dataTables_filter label {
    margin: 10px
}
.widget-body.no-padding .dataTables_length {
    top: 10px;
    right: 10px
}
.widget-body.no-padding .DTTT.btn-group {
    right: 80px;
    top: 10px
}
.widget-body .accordion.panel-group {
    border-top-width: 1px !important
}
.widget-body>table {
    margin-bottom: 0
}
.widget-body hr.wide {
    margin-left: -12px;
    margin-right: -12px
}
.databox-container {
    text-align: center;
    font-size: 0
}
.databox {
    display: inline-block;
    width: 100%;
    height: 65px;
    padding: 0;
    font-size: 0;
    margin-bottom: 30px;
    vertical-align: top;
    min-width: 130px
}
.databox .databox-icon {
    margin: 0;
    display: inline-block;
    width: 100%;
    text-align: center
}
.databox .databox-icon>.fa,
.databox .databox-icon .typcn,
.databox .databox-icon .glyphicon,
.databox .databox-icon .wi {
    display: inline-block;
    margin: 0;
    text-align: center;
    position: relative;
    margin-top: 3px
}
.databox .databox-icon>.fa:before,
.databox .databox-icon .typcn:before,
.databox .databox-icon .glyphicon:before,
.databox .databox-icon .wi:before {
    font-size: 40px;
    display: block;
    text-align: center
}
.databox .databox-sparkline {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 24px
}
.databox .databox-piechart {
    padding-top: 0;
    margin: 0 auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 300
}
.databox .databox-header {
    display: block;
    font-size: 18px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 400;
    margin: 8px 5px;
    position: relative
}
.databox .databox-header i {
    margin-right: 5px
}
.databox .databox-number {
    display: block;
    font-size: 17px;
    line-height: 26px;
    margin: 2px;
    position: relative;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica
}
.databox .databox-number i {
    margin-right: 5px
}
.databox .databox-number.number-xs {
    font-size: 11px
}
.databox .databox-number.number-sm {
    font-size: 14px
}
.databox .databox-number.number-lg {
    font-size: 20px
}
.databox .databox-number.number-xlg {
    font-size: 23px
}
.databox .databox-number.number-xxlg {
    font-size: 30px
}
.databox .databox-title {
    display: block;
    font-size: 16px;
    font-weight: 300;
    margin: 2px;
    position: relative
}
.databox .databox-title i {
    margin-right: 5px;
    font-size: 13px
}
.databox .databox-text {
    display: block;
    font-size: 11px;
    margin: 4px 0 2px;
    position: relative
}
.databox .databox-text i {
    margin-right: 5px;
    font-size: 13px
}
.databox .databox-inlinetext {
    font-size: 11px;
    margin: 2px;
    position: relative
}
.databox .databox-inlinetext i {
    margin-right: 5px;
    font-size: 13px
}
.databox .databox-stat {
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 5px
}
.databox .databox-stat.stat-left {
    left: 7px;
    right: auto
}
.databox .databox-stat .stat-text {
    display: inline-block;
    font-size: 13px;
    padding-right: 5px;
    font-weight: 500
}
.databox .databox-stat .stat-icon {
    display: inline-block;
    font-size: 13px
}
.databox .databox-stat .stat-icon.icon-lg {
    font-size: 16px
}
.databox .databox-stat .stat-icon.icon-xlg {
    font-size: 18px
}
.databox .databox-stat.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.databox .databox-stat[class*="bg-"] {
    color: #fff
}
.databox .databox-state {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 24px;
    width: 24px;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    background-color: #e5e5e5;
    text-align: center;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    border: 2px solid #fff
}
.databox .databox-state i {
    font-size: 13px;
    color: #fff;
    line-height: 20px
}
.databox .databox-row {
    width: 100%;
    margin: 0;
    position: relative
}
.databox .databox-row[class*="bg-"] {
    color: #fff
}
.databox .databox-row.bordered-bottom {
    border-bottom: 1px solid #fff
}
.databox .databox-row.bordered-top {
    border-top: 1px solid #fff
}
.databox .databox-row.bordered-left {
    border-left: 1px solid #fff
}
.databox .databox-row.bordered-right {
    border-right: 1px solid #fff
}
.databox .databox-row.bordered-thick {
    border-width: 2px
}
.databox .databox-row.row-1 {
    height: 8.33%
}
.databox .databox-row.row-2 {
    height: 16.66%
}
.databox .databox-row.row-3 {
    height: 25%
}
.databox .databox-row.row-4 {
    height: 33.33%
}
.databox .databox-row.row-5 {
    height: 41.66%
}
.databox .databox-row.row-6 {
    height: 50%
}
.databox .databox-row.row-7 {
    height: 58.33%
}
.databox .databox-row.row-8 {
    height: 66.66%
}
.databox .databox-row.row-9 {
    height: 75%
}
.databox .databox-row.row-10 {
    height: 83.33%
}
.databox .databox-row.row-11 {
    height: 91.66%
}
.databox .databox-row.row-12 {
    height: 100%
}
.databox .databox-cell {
    height: 100%;
    margin: 0;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px
}
.databox .databox-cell[class*="bg-"] {
    color: #fff
}
.databox .databox-cell.bordered-bottom {
    border-bottom: 1px solid #fff
}
.databox .databox-cell.bordered-top {
    border-top: 1px solid #fff
}
.databox .databox-cell.bordered-left {
    border-left: 1px solid #fff
}
.databox .databox-cell.bordered-right {
    border-right: 1px solid #fff
}
.databox .databox-cell.bordered-thick {
    border-width: 2px
}
.databox .databox-cell.cell-1 {
    width: 8.33%
}
.databox .databox-cell.cell-2 {
    width: 16.66%
}
.databox .databox-cell.cell-3 {
    width: 25%
}
.databox .databox-cell.cell-4 {
    width: 33.33%
}
.databox .databox-cell.cell-5 {
    width: 41.66%
}
.databox .databox-cell.cell-6 {
    width: 50%
}
.databox .databox-cell.cell-7 {
    width: 58.33%
}
.databox .databox-cell.cell-8 {
    width: 66.66%
}
.databox .databox-cell.cell-9 {
    width: 75%
}
.databox .databox-cell.cell-10 {
    width: 83.33%
}
.databox .databox-cell.cell-11 {
    width: 91.66%
}
.databox .databox-cell.cell-12 {
    width: 100%
}
.databox.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: calc(100% - 65px)
}
.databox.databox-inverted .databox-right {
    width: 65px
}
.databox.databox-transparent .databox-left {
    background-color: transparent !important
}
.databox.databox-transparent .databox-right {
    background-color: transparent !important
}
.databox .databox-left {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0;
    width: 65px;
    height: 65px;
    padding: 10px;
    color: #2dc3e8
}
.databox .databox-left[class*="bg-"] {
    color: #fff
}
.databox .databox-right {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    -lh-property: 0;
    width: -webkit-calc(100% - 65px);
    width: -moz-calc(100% - 65px);
    width: calc(100% - 65px);
    height: 65px;
    color: #555;
    padding: 5px 10px
}
.databox .databox-right.bordered {
    border-left: 1px solid #fff
}
.databox .databox-right.bordered-thick {
    border-left: 3px solid #fff
}
.databox .databox-right[class*="bg-"] {
    color: #fff
}
.databox.databox-shadowed {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    box-shadow: 0 0 3px rgba(0, 0, 0, .2)
}
.databox.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.databox.radius-bordered .databox-left {
    -webkit-border-radius: 3px 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box
}
.databox.radius-bordered .databox-right {
    -webkit-border-radius: 0 3px 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 3px 0;
    background-clip: padding-box
}
.databox.radius-bordered .databox-right .databox-row:first-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
}
.databox.radius-bordered .databox-right .databox-row:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
}
.databox.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNkZGRkZGQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(left, #ddd, #fff);
    background-image: -moz-linear-gradient(left, #ddd, #fff);
    background-image: -o-linear-gradient(left, #ddd, #fff);
    background-image: linear-gradient(to right, #ddd, #fff)
}
.databox.databox-halved .databox-left {
    width: 50% !important
}
.databox.databox-halved .databox-right {
    width: 50% !important
}
.databox.databox-lg {
    height: 80px
}
.databox.databox-lg .databox-icon>.fa:before,
.databox.databox-lg .databox-icon .typcn:before,
.databox.databox-lg .databox-icon .glyphicon:before,
.databox.databox-lg .databox-icon .wi:before {
    font-size: 48px;
    padding: 6px 4px 7px
}
.databox.databox-lg .databox-sparkline {
    margin: 13px auto
}
.databox.databox-lg .databox-number {
    font-size: 20px;
    margin: 4px 0 6px
}
.databox.databox-lg .databox-left {
    width: 80px;
    height: 80px
}
.databox.databox-lg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: calc(100% - 80px);
    height: 80px;
    padding: 10px 15px
}
.databox.databox-lg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 80px);
    width: -moz-calc(100% - 80px);
    width: calc(100% - 80px)
}
.databox.databox-lg.databox-inverted .databox-right {
    width: 80px
}
.databox.databox-xlg {
    height: 170px
}
.databox.databox-xlg .databox-icon>.fa:before,
.databox.databox-xlg .databox-icon .typcn:before,
.databox.databox-xlg .databox-icon .glyphicon:before {
    padding: 6px 4px 7px
}
.databox.databox-xlg .databox-icon .wi:before {
    font-size: 55px !important
}
.databox.databox-xlg .databox-left {
    width: 170px;
    height: 170px
}
.databox.databox-xlg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 170px);
    width: -moz-calc(100% - 170px);
    width: calc(100% - 170px);
    height: 170px
}
.databox.databox-xlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 170px);
    width: -moz-calc(100% - 170px);
    width: calc(100% - 170px)
}
.databox.databox-xlg.databox-inverted .databox-right {
    width: 170px
}
.databox.databox-xxlg {
    height: 250px
}
.databox.databox-xxlg .databox-icon>.fa:before,
.databox.databox-xxlg .databox-icon .typcn:before,
.databox.databox-xxlg .databox-icon .glyphicon:before,
.databox.databox-xxlg .databox-icon .wi:before {
    font-size: 200px;
    padding: 6px 4px 7px
}
.databox.databox-xxlg .databox-left {
    width: 250px;
    height: 250px
}
.databox.databox-xxlg .databox-right {
    -lh-property: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
    height: 250px
}
.databox.databox-xxlg.databox-inverted .databox-left {
    -lh-property: 0;
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px)
}
.databox.databox-xxlg.databox-inverted .databox-right {
    width: 250px
}
.databox.databox-vertical {
    width: 100%;
    height: 100px
}
.databox.databox-vertical .databox-top {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 5px;
    color: #2dc3e8
}
.databox.databox-vertical .databox-top[class*="bg-"] {
    color: #fff
}
.databox.databox-vertical .databox-bottom {
    position: relative;
    width: 100%;
    height: 50px;
    display: inline-block;
    margin: 0;
    color: #555;
    padding: 5px 10px
}
.databox.databox-vertical .databox-bottom.bordered {
    border-top: 1px solid #fff
}
.databox.databox-vertical .databox-bottom.bordered-thick {
    border-top: 3px solid #fff
}
.databox.databox-vertical .databox-bottom[class*="bg-"] {
    color: #fff
}
.databox.databox-vertical .databox-icon>.fa:before,
.databox.databox-vertical .databox-icon .typcn:before,
.databox.databox-vertical .databox-icon .glyphicon:before,
.databox.databox-vertical .databox-icon .wi:before {
    font-size: 38px
}
.databox.databox-vertical.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-top {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:first-child {
    -webkit-border-radius: 3px 0 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 0;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-top .databox-row:first-child .databox-cell:last-child {
    -webkit-border-radius: 0 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 0 0;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-top img {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-bottom {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:first-child {
    -webkit-border-radius: 0 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 0 3px;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-bottom .databox-row:last-child .databox-cell:last-child {
    -webkit-border-radius: 0 0 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 0;
    background-clip: padding-box
}
.databox.databox-vertical.radius-bordered .databox-bottom img {
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box
}
.databox.databox-vertical.databox-graded {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(top, #e5e5e5, #fff);
    background-image: -moz-linear-gradient(top, #e5e5e5, #fff);
    background-image: -o-linear-gradient(top, #e5e5e5, #fff);
    background-image: linear-gradient(to bottom, #e5e5e5, #fff)
}
.databox.databox-vertical.databox-halved .databox-top {
    height: 50%
}
.databox.databox-vertical.databox-halved .databox-bottom {
    height: 50%
}
.databox.databox-vertical.databox-lg {
    height: 150px;
    width: 100%
}
.databox.databox-vertical.databox-lg .databox-number {
    font-size: 17px;
    margin: 2px
}
.databox.databox-vertical.databox-lg .databox-icon>.fa:before,
.databox.databox-vertical.databox-lg .databox-icon .typcn:before,
.databox.databox-vertical.databox-lg .databox-icon .glyphicon:before {
    font-size: 38px !important;
    padding: 0
}
.databox.databox-vertical.databox-lg .databox-icon .wi:before {
    font-size: 50px !important;
    padding: 0
}
.databox.databox-vertical.databox-lg .databox-top {
    height: 50px
}
.databox.databox-vertical.databox-lg .databox-bottom {
    height: 100px
}
.databox.databox-vertical.databox-lg.databox-inverted .databox-icon>.fa:before,
.databox.databox-vertical.databox-lg.databox-inverted .databox-icon .typcn:before,
.databox.databox-vertical.databox-lg.databox-inverted .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-lg.databox-inverted .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
}
.databox.databox-vertical.databox-lg.databox-inverted .databox-top {
    height: 100px
}
.databox.databox-vertical.databox-lg.databox-inverted .databox-bottom {
    height: 50px
}
.databox.databox-vertical.databox-lg.databox-halved .databox-icon>.fa:before,
.databox.databox-vertical.databox-lg.databox-halved .databox-icon .typcn:before,
.databox.databox-vertical.databox-lg.databox-halved .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-lg.databox-halved .databox-icon .wi:before {
    font-size: 58px;
    padding: 0
}
.databox.databox-vertical.databox-lg.databox-halved .databox-top {
    height: 75px
}
.databox.databox-vertical.databox-lg.databox-halved .databox-bottom {
    height: 75px
}
.databox.databox-vertical.databox-xlg {
    height: 200px;
    width: 100%
}
.databox.databox-vertical.databox-xlg .databox-icon>.fa:before,
.databox.databox-vertical.databox-xlg .databox-icon .typcn:before,
.databox.databox-vertical.databox-xlg .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
}
.databox.databox-vertical.databox-xlg .databox-top {
    height: 50px
}
.databox.databox-vertical.databox-xlg .databox-bottom {
    height: 150px
}
.databox.databox-vertical.databox-xlg.databox-inverted .databox-icon>.fa:before,
.databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .typcn:before,
.databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xlg.databox-inverted .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
}
.databox.databox-vertical.databox-xlg.databox-inverted .databox-top {
    height: 150px
}
.databox.databox-vertical.databox-xlg.databox-inverted .databox-bottom {
    height: 50px
}
.databox.databox-vertical.databox-xlg.databox-halved .databox-icon>.fa:before,
.databox.databox-vertical.databox-xlg.databox-halved .databox-icon .typcn:before,
.databox.databox-vertical.databox-xlg.databox-halved .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xlg.databox-halved .databox-icon .wi:before {
    font-size: 85px;
    padding: 0
}
.databox.databox-vertical.databox-xlg.databox-halved .databox-top {
    height: 100px
}
.databox.databox-vertical.databox-xlg.databox-halved .databox-bottom {
    height: 100px
}
.databox.databox-vertical.databox-xxlg {
    height: 300px;
    width: 100%
}
.databox.databox-vertical.databox-xxlg .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxlg .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxlg .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
}
.databox.databox-vertical.databox-xxlg .databox-top {
    height: 50px
}
.databox.databox-vertical.databox-xxlg .databox-bottom {
    height: 250px
}
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
}
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-top {
    height: 250px
}
.databox.databox-vertical.databox-xxlg.databox-inverted .databox-bottom {
    height: 50px
}
.databox.databox-vertical.databox-xxlg.databox-halved .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxlg.databox-halved .databox-icon .wi:before {
    font-size: 120px;
    padding: 10px
}
.databox.databox-vertical.databox-xxlg.databox-halved .databox-top {
    height: 150px
}
.databox.databox-vertical.databox-xxlg.databox-halved .databox-bottom {
    height: 150px
}
.databox.databox-vertical.databox-xxxlg {
    height: 500px;
    width: 100%
}
.databox.databox-vertical.databox-xxxlg .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxxlg .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxxlg .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxxlg .databox-icon .wi:before {
    font-size: 38px;
    padding: 0
}
.databox.databox-vertical.databox-xxxlg .databox-top {
    height: 50px
}
.databox.databox-vertical.databox-xxxlg .databox-bottom {
    height: 450px
}
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-icon .wi:before {
    font-size: 420px;
    padding: 10px
}
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-top {
    height: 450px
}
.databox.databox-vertical.databox-xxxlg.databox-inverted .databox-bottom {
    height: 50px
}
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon>.fa:before,
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .typcn:before,
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .glyphicon:before,
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-icon .wi:before {
    font-size: 220px;
    padding: 10px
}
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-top {
    height: 250px
}
.databox.databox-vertical.databox-xxxlg.databox-halved .databox-bottom {
    height: 250px
}
.databox .chart {
    font-size: 13px
}
.alert {
    margin-bottom: 20px;
    margin-top: 0;
    color: #fff;
    border-width: 0;
    border-left-width: 5px;
    padding: 10px;
    border-radius: 0
}
.alert .close {
    top: 0;
    right: 0;
    line-height: 16px;
    color: #444
}
.alert.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.alert.alert-shadowed {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2)
}
.alert.alert-heading {
    font-weight: 600
}
.alert.alert-danger {
    border-color: #df5138;
    background: #e46f61
}
.alert.alert-warning {
    border-color: #ffce55;
    color: #555;
    background: #fff1a8
}
.alert.alert-success {
    border-color: #8cc474;
    background: #a0d468
}
.alert.alert-info {
    border-color: #11a9cc;
    background: #57b5e3
}
.tab-content {
    background-color: #fbfbfb;
    padding: 16px 12px;
    position: relative;
    -webkit-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    -moz-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3)
}
.tab-content.no-padding {
    padding: 0
}
.tab-content.no-border {
    border: 0;
    padding: 12px
}
.tab-content.radius-bordered {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.tab-content.tabs-flat {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid #e5e5e5
}
.nav-tabs {
    margin-bottom: 0;
    margin-left: 0;
    border: 0;
    top: 2px;
    background-color: #eee;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    box-shadow: 0 0 4px rgba(0, 0, 0, .3)
}
.nav-tabs>li {
    margin-bottom: -2px
}
.nav-tabs>li>a:hover {
    color: #262626;
    background-color: transparent;
    border-color: transparent
}
.nav-tabs>li>a:active,
.nav-tabs>li>a:focus {
    outline: none !important
}
.nav-tabs>li>a,
.nav-tabs>li>a:focus {
    border-radius: 0 !important;
    color: #777;
    margin-right: -1px;
    line-height: 12px;
    position: relative;
    z-index: 11
}
.nav-tabs>li>a>.badge {
    padding: 0 4px;
    line-height: 15px;
    opacity: .75;
    height: 16px;
    min-width: 16px
}
.nav-tabs>li>a .fa,
.nav-tabs>li>a .glyphicon,
.nav-tabs>li>a .typcn,
.nav-tabs>li>a .wi {
    opacity: .75
}
.nav-tabs>li:first-child>a {
    margin-left: 0;
    border-left: 1px solid #fbfbfb
}
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    color: #262626;
    border: 0;
    border-top: 2px solid #2dc3e8;
    border-bottom-color: transparent;
    background-color: #fbfbfb;
    z-index: 12;
    line-height: 16px;
    margin-top: -2px;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, .15)
}
.nav-tabs>li.active>a>.badge,
.nav-tabs>li.active>a>[class*="fa-"] {
    opacity: 1
}
.nav-tabs>li.active.tab-white>a {
    border-color: #fff !important
}
.nav-tabs>li.active.tab-white>a>.fa,
.nav-tabs>li.active.tab-white>a .glyphicon,
.nav-tabs>li.active.tab-white>a .typcn,
.nav-tabs>li.active.tab-white>a .wi {
    color: #fff
}
.nav-tabs>li.active tab-snow>a {
    border-color: #fbfbfb !important
}
.nav-tabs>li.active tab-snow>a>.fa,
.nav-tabs>li.active tab-snow>a .glyphicon,
.nav-tabs>li.active tab-snow>a .typcn,
.nav-tabs>li.active tab-snow>a .wi {
    color: #fbfbfb
}
.nav-tabs>li.active.tab-whitesmoke>a {
    border-color: #f5f5f5 !important
}
.nav-tabs>li.active.tab-whitesmoke>a>.fa,
.nav-tabs>li.active.tab-whitesmoke>a .glyphicon,
.nav-tabs>li.active.tab-whitesmoke>a .typcn,
.nav-tabs>li.active.tab-whitesmoke>a .wi {
    color: #f5f5f5
}
.nav-tabs>li.active.tab-seashell>a {
    border-color: #f3f3f3 !important
}
.nav-tabs>li.active.tab-seashell>a>.fa,
.nav-tabs>li.active.tab-seashell>a .glyphicon,
.nav-tabs>li.active.tab-seashell>a .typcn,
.nav-tabs>li.active.tab-seashell>a .wi {
    color: #f3f3f3
}
.nav-tabs>li.active.tab-ivory>a {
    border-color: #eee !important
}
.nav-tabs>li.active.tab-ivory>a>.fa,
.nav-tabs>li.active.tab-ivory>a .glyphicon,
.nav-tabs>li.active.tab-ivory>a .typcn,
.nav-tabs>li.active.tab-ivory>a .wi {
    color: #eee
}
.nav-tabs>li.active.tab-platinum>a {
    border-color: #e5e5e5 !important
}
.nav-tabs>li.active.tab-platinum>a>.fa,
.nav-tabs>li.active.tab-platinum>a .glyphicon,
.nav-tabs>li.active.tab-platinum>a .typcn,
.nav-tabs>li.active.tab-platinum>a .wi {
    color: #e5e5e5
}
.nav-tabs>li.active.tab-lightgray>a {
    border-color: #d0d0d0 !important
}
.nav-tabs>li.active.tab-lightgray>a>.fa,
.nav-tabs>li.active.tab-lightgray>a .glyphicon,
.nav-tabs>li.active.tab-lightgray>a .typcn,
.nav-tabs>li.active.tab-lightgray>a .wi {
    color: #d0d0d0
}
.nav-tabs>li.active.tab-gray>a {
    border-color: #ccc !important
}
.nav-tabs>li.active.tab-gray>a>.fa,
.nav-tabs>li.active.tab-gray>a .glyphicon,
.nav-tabs>li.active.tab-gray>a .typcn,
.nav-tabs>li.active.tab-gray>a .wi {
    color: #ccc
}
.nav-tabs>li.active.tab-darkgray>a {
    border-color: #999 !important
}
.nav-tabs>li.active.tab-darkgray>a>.fa,
.nav-tabs>li.active.tab-darkgray>a .glyphicon,
.nav-tabs>li.active.tab-darkgray>a .typcn,
.nav-tabs>li.active.tab-darkgray>a .wi {
    color: #999
}
.nav-tabs>li.active.tab-silver>a {
    border-color: #777 !important
}
.nav-tabs>li.active.tab-silver>a>.fa,
.nav-tabs>li.active.tab-silver>a .glyphicon,
.nav-tabs>li.active.tab-silver>a .typcn,
.nav-tabs>li.active.tab-silver>a .wi {
    color: #777
}
.nav-tabs>li.active.tab-sonic-silver>a {
    border-color: #737373 !important
}
.nav-tabs>li.active.tab-sonic-silver>a>.fa,
.nav-tabs>li.active.tab-sonic-silver>a .glyphicon,
.nav-tabs>li.active.tab-sonic-silver>a .typcn,
.nav-tabs>li.active.tab-sonic-silver>a .wi {
    color: #737373
}
.nav-tabs>li.active.tab-storm-cloud>a {
    border-color: #666 !important
}
.nav-tabs>li.active.tab-storm-cloud>a>.fa,
.nav-tabs>li.active.tab-storm-cloud>a .glyphicon,
.nav-tabs>li.active.tab-storm-cloud>a .typcn,
.nav-tabs>li.active.tab-storm-cloud>a .wi {
    color: #666
}
.nav-tabs>li.active.tab-lightcarbon>a {
    border-color: #555 !important
}
.nav-tabs>li.active.tab-lightcarbon>a>.fa,
.nav-tabs>li.active.tab-lightcarbon>a .glyphicon,
.nav-tabs>li.active.tab-lightcarbon>a .typcn,
.nav-tabs>li.active.tab-lightcarbon>a .wi {
    color: #555
}
.nav-tabs>li.active.tab-carbon>a {
    border-color: #444 !important
}
.nav-tabs>li.active.tab-carbon>a>.fa,
.nav-tabs>li.active.tab-carbon>a .glyphicon,
.nav-tabs>li.active.tab-carbon>a .typcn,
.nav-tabs>li.active.tab-carbon>a .wi {
    color: #444
}
.nav-tabs>li.active.tab-primary>a {
    border-color: #4374e0 !important
}
.nav-tabs>li.active.tab-primary>a>.fa,
.nav-tabs>li.active.tab-primary>a .glyphicon,
.nav-tabs>li.active.tab-primary>a .typcn,
.nav-tabs>li.active.tab-primary>a .wi {
    color: #4374e0
}
.nav-tabs>li.active.tab-slate-gray>a {
    border-color: #333 !important
}
.nav-tabs>li.active.tab-slate-gray>a>.fa,
.nav-tabs>li.active.tab-slate-gray>a .glyphicon,
.nav-tabs>li.active.tab-slate-gray>a .typcn,
.nav-tabs>li.active.tab-slate-gray>a .wi {
    color: #333
}
.nav-tabs>li.active.tab-darkcarbon>a {
    border-color: #262626 !important
}
.nav-tabs>li.active.tab-darkcarbon>a>.fa,
.nav-tabs>li.active.tab-darkcarbon>a .glyphicon,
.nav-tabs>li.active.tab-darkcarbon>a .typcn,
.nav-tabs>li.active.tab-darkcarbon>a .wi {
    color: #262626
}
.nav-tabs>li.active.tab-blue>a {
    border-color: #5db2ff !important
}
.nav-tabs>li.active.tab-blue>a>.fa,
.nav-tabs>li.active.tab-blue>a .glyphicon,
.nav-tabs>li.active.tab-blue>a .typcn,
.nav-tabs>li.active.tab-blue>a .wi {
    color: #5db2ff
}
.nav-tabs>li.active.tab-info>a {
    border-color: #57b5e3 !important
}
.nav-tabs>li.active.tab-info>a>.fa,
.nav-tabs>li.active.tab-info>a .glyphicon,
.nav-tabs>li.active.tab-info>a .typcn,
.nav-tabs>li.active.tab-info>a .wi {
    color: #57b5e3
}
.nav-tabs>li.active.tab-sky>a {
    border-color: #11a9cc !important
}
.nav-tabs>li.active.tab-sky>a>.fa,
.nav-tabs>li.active.tab-sky>a .glyphicon,
.nav-tabs>li.active.tab-sky>a .typcn,
.nav-tabs>li.active.tab-sky>a .wi {
    color: #11a9cc
}
.nav-tabs>li.active.tab-primary>a {
    border-color: #4374e0 !important
}
.nav-tabs>li.active.tab-primary>a>.fa,
.nav-tabs>li.active.tab-primary>a .glyphicon,
.nav-tabs>li.active.tab-primary>a .typcn,
.nav-tabs>li.active.tab-primary>a .wi {
    color: #4374e0
}
.nav-tabs>li.active.tab-primary>a {
    border-color: #4374e0 !important
}
.nav-tabs>li.active.tab-primary>a>.fa,
.nav-tabs>li.active.tab-primary>a .glyphicon,
.nav-tabs>li.active.tab-primary>a .typcn,
.nav-tabs>li.active.tab-primary>a .wi {
    color: #4374e0
}
.nav-tabs>li.active.tab-blueberry>a {
    border-color: #6f85bf !important
}
.nav-tabs>li.active.tab-blueberry>a>.fa,
.nav-tabs>li.active.tab-blueberry>a .glyphicon,
.nav-tabs>li.active.tab-blueberry>a .typcn,
.nav-tabs>li.active.tab-blueberry>a .wi {
    color: #6f85bf
}
.nav-tabs>li.active.tab-palegreen>a {
    border-color: #a0d468 !important
}
.nav-tabs>li.active.tab-palegreen>a>.fa,
.nav-tabs>li.active.tab-palegreen>a .glyphicon,
.nav-tabs>li.active.tab-palegreen>a .typcn,
.nav-tabs>li.active.tab-palegreen>a .wi {
    color: #a0d468
}
.nav-tabs>li.active.tab-success>a {
    border-color: #53a93f !important
}
.nav-tabs>li.active.tab-success>a>.fa,
.nav-tabs>li.active.tab-success>a .glyphicon,
.nav-tabs>li.active.tab-success>a .typcn,
.nav-tabs>li.active.tab-success>a .wi {
    color: #53a93f
}
.nav-tabs>li.active.tab-green>a {
    border-color: #8cc474 !important
}
.nav-tabs>li.active.tab-green>a>.fa,
.nav-tabs>li.active.tab-green>a .glyphicon,
.nav-tabs>li.active.tab-green>a .typcn,
.nav-tabs>li.active.tab-green>a .wi {
    color: #8cc474
}
.nav-tabs>li.active.tab-lightyellow>a {
    border-color: #f6d52e !important
}
.nav-tabs>li.active.tab-lightyellow>a>.fa,
.nav-tabs>li.active.tab-lightyellow>a .glyphicon,
.nav-tabs>li.active.tab-lightyellow>a .typcn,
.nav-tabs>li.active.tab-lightyellow>a .wi {
    color: #f6d52e
}
.nav-tabs>li.active.tab-yellow>a {
    border-color: #ffce55 !important
}
.nav-tabs>li.active.tab-yellow>a>.fa,
.nav-tabs>li.active.tab-yellow>a .glyphicon,
.nav-tabs>li.active.tab-yellow>a .typcn,
.nav-tabs>li.active.tab-yellow>a .wi {
    color: #ffce55
}
.nav-tabs>li.active.tab-warning>a {
    border-color: #f4b400 !important
}
.nav-tabs>li.active.tab-warning>a>.fa,
.nav-tabs>li.active.tab-warning>a .glyphicon,
.nav-tabs>li.active.tab-warning>a .typcn,
.nav-tabs>li.active.tab-warning>a .wi {
    color: #f4b400
}
.nav-tabs>li.active.tab-gold>a {
    border-color: #f9b256 !important
}
.nav-tabs>li.active.tab-gold>a>.fa,
.nav-tabs>li.active.tab-gold>a .glyphicon,
.nav-tabs>li.active.tab-gold>a .typcn,
.nav-tabs>li.active.tab-gold>a .wi {
    color: #f9b256
}
.nav-tabs>li.active.tab-orange>a {
    border-color: #fb6e52 !important
}
.nav-tabs>li.active.tab-orange>a>.fa,
.nav-tabs>li.active.tab-orange>a .glyphicon,
.nav-tabs>li.active.tab-orange>a .typcn,
.nav-tabs>li.active.tab-orange>a .wi {
    color: #fb6e52
}
.nav-tabs>li.active.tab-lightred>a {
    border-color: #e46f61 !important
}
.nav-tabs>li.active.tab-lightred>a>.fa,
.nav-tabs>li.active.tab-lightred>a .glyphicon,
.nav-tabs>li.active.tab-lightred>a .typcn,
.nav-tabs>li.active.tab-lightred>a .wi {
    color: #e46f61
}
.nav-tabs>li.active.tab-darkorange>a {
    border-color: #ed4e2a !important
}
.nav-tabs>li.active.tab-darkorange>a>.fa,
.nav-tabs>li.active.tab-darkorange>a .glyphicon,
.nav-tabs>li.active.tab-darkorange>a .typcn,
.nav-tabs>li.active.tab-darkorange>a .wi {
    color: #ed4e2a
}
.nav-tabs>li.active.tab-red>a {
    border-color: #df5138 !important
}
.nav-tabs>li.active.tab-red>a>.fa,
.nav-tabs>li.active.tab-red>a .glyphicon,
.nav-tabs>li.active.tab-red>a .typcn,
.nav-tabs>li.active.tab-red>a .wi {
    color: #df5138
}
.nav-tabs>li.active.tab-pink>a {
    border-color: #e75b8d !important
}
.nav-tabs>li.active.tab-pink>a>.fa,
.nav-tabs>li.active.tab-pink>a .glyphicon,
.nav-tabs>li.active.tab-pink>a .typcn,
.nav-tabs>li.active.tab-pink>a .wi {
    color: #e75b8d
}
.nav-tabs>li.active.tab-danger>a {
    border-color: #d73d32 !important
}
.nav-tabs>li.active.tab-danger>a>.fa,
.nav-tabs>li.active.tab-danger>a .glyphicon,
.nav-tabs>li.active.tab-danger>a .typcn,
.nav-tabs>li.active.tab-danger>a .wi {
    color: #d73d32
}
.nav-tabs>li.active.tab-magenta>a {
    border-color: #bc5679 !important
}
.nav-tabs>li.active.tab-magenta>a>.fa,
.nav-tabs>li.active.tab-magenta>a .glyphicon,
.nav-tabs>li.active.tab-magenta>a .typcn,
.nav-tabs>li.active.tab-magenta>a .wi {
    color: #bc5679
}
.nav-tabs>li.active.tab-purple>a {
    border-color: #7e3794 !important
}
.nav-tabs>li.active.tab-purple>a>.fa,
.nav-tabs>li.active.tab-purple>a .glyphicon,
.nav-tabs>li.active.tab-purple>a .typcn,
.nav-tabs>li.active.tab-purple>a .wi {
    color: #7e3794
}
.nav-tabs>li.active.tab-maroon>a {
    border-color: #981b48 !important
}
.nav-tabs>li.active.tab-maroon>a>.fa,
.nav-tabs>li.active.tab-maroon>a .glyphicon,
.nav-tabs>li.active.tab-maroon>a .typcn,
.nav-tabs>li.active.tab-maroon>a .wi {
    color: #981b48
}
.nav-tabs.nav-justified {
    margin-bottom: -2px
}
.nav-tabs.nav-justified>li>a {
    border: none;
    margin-top: -2px
}
.nav-tabs.nav-justified>li>a:hover {
    background-color: transparent
}
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:focus {
    border: 0;
    border-top: 2px solid #2dc3e8;
    background-color: #fbfbfb
}
.nav-tabs .dropdown-menu {
    margin-top: -3px
}
.nav-tabs.tabs-flat {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0)
}
.tabs-below>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-top: -1px;
    border-bottom-width: 0
}
.tabs-below>.nav-tabs li.active>a,
.tabs-below>.nav-tabs li.active>a:hover,
.tabs-below>.nav-tabs li.active>a:focus {
    border-top-width: 1px;
    border-bottom: 2px solid #5db2ff;
    border-top-color: transparent;
    margin-top: 0;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .15)
}
.tabs-below>.nav-tabs.nav-justified>li>a {
    margin-bottom: -2px
}
.tabs-below>.nav-tabs.nav-justified>.active>a,
.tabs-below>.nav-tabs.nav-justified>.active>a:hover,
.tabs-below>.nav-tabs.nav-justified>.active>a:focus {
    border: 0;
    border-bottom: 2px solid #5db2ff
}
.tabs-left>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    float: left
}
.tabs-left>.nav-tabs>li {
    float: none
}
.tabs-left>.nav-tabs>li>a,
.tabs-left>.nav-tabs>li>a:focus,
.tabs-left>.nav-tabs>li>a:hover {
    margin: 0 -1px 0 0
}
.tabs-left>.nav-tabs>li.active>a,
.tabs-left>.nav-tabs>li.active>a:focus,
.tabs-left>.nav-tabs>li.active>a:hover {
    border: 0;
    border-left: 2px solid #5db2ff;
    border-right-color: transparent;
    margin: 0 -1px 0 -1px;
    -webkit-box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .3);
    -moz-box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .3);
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .3)
}
.tabs-right>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    float: right
}
.tabs-right>.nav-tabs>li {
    float: none
}
.tabs-right>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li>a:hover {
    border-top: 0;
    margin: 0 -1px
}
.tabs-right>.nav-tabs>li.active>a,
.tabs-right>.nav-tabs>li.active>a:focus,
.tabs-right>.nav-tabs>li.active>a:hover {
    border-top-width: 0;
    border-right: 2px solid #5db2ff;
    border-left-color: transparent;
    margin: 0 -2px 0 -1px;
    -webkit-box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .3);
    -moz-box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .3);
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .3)
}
.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
    min-width: 60px
}
.nav-tabs li [class*=" fa-"],
.nav-tabs li [class^="fa-"] {
    width: 1.25em;
    display: inline-block;
    text-align: center
}
.nav-tabs>li.open .dropdown-toggle {
    background-color: #e5e5e5;
    border-color: #e5e5e5
}
.tabs-left .tab-content,
.tabs-right .tab-content {
    overflow: auto
}
.nav-pills .open .dropdown-toggle,
.nav>li.dropdown.open.active>a:hover,
.nav>li.dropdown.open.active>a:focus {
    background-color: #fbfbfb
}
.nav .dropdown-menu>.active>a,
.nav .dropdown-menu>.active>a:hover,
.nav .dropdown-menu>.active>a:focus {
    color: #262626;
    background-color: #e5e5e5
}
.nav-tabs>li:not(.active):not(.open)>a:not(:hover)>[class*="fa-"].disabled:first-child {
    color: #909090 !important
}
.btn {
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
    position: relative;
    display: inline-block;
    color: #fff;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
    -webkit-transition: all .15s ease;
    -moz-transition: all .15s ease;
    -o-transition: all .15s ease;
    transition: all .15s ease;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    font-size: 13px
}
.btn:hover,
.btn:focus {
    color: #fff
}
.btn.btn-link {
    border: none !important;
    background: transparent none !important;
    color: #08c !important;
    -webkit-text-shadow: none !important;
    text-shadow: none !important;
    padding: 4px 12px !important;
    line-height: 20px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important
}
.btn.btn-link:hover {
    background: none !important;
    -webkit-text-shadow: none !important;
    text-shadow: none !important
}
.btn.btn-link.active {
    background: none !important;
    text-decoration: underline;
    color: #009ceb !important
}
.btn.btn-link.active:after {
    display: none
}
.btn.btn-link.disabled,
.btn.btn-link[disabled] {
    background: 0;
    opacity: .65;
    filter: alpha(opacity=65)
}
.btn.btn-link.disabled:hover,
.btn.btn-link[disabled]:hover {
    background: none !important;
    text-decoration: none !important
}
.btn.shiny {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .1);
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.btn.shiny:hover {
    zoom: 1;
    filter: alpha(opacity=90);
    -webkit-opacity: .9;
    -moz-opacity: .9;
    opacity: .9
}
.btn.shiny.active,
.btn.shiny:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}
.btn.shiny.dropdown-toggle {
    border-left-width: 0 !important
}
.btn.btn-circle {
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 11px 12px
}
.btn.btn-circle>.fa,
.btn.btn-circle .typcn,
.btn.btn-circle .glyphicon,
.btn.btn-circle>[class*="wi-"] {
    font-size: 20px;
    margin: 0 auto;
    line-height: 34px
}
.btn.btn-circle.btn-xs {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0
}
.btn.btn-circle.btn-xs>.fa,
.btn.btn-circle.btn-xs .typcn,
.btn.btn-circle.btn-xs .glyphicon,
.btn.btn-circle.btn-xs>[class*="wi-"] {
    font-size: 12px;
    line-height: 14px
}
.btn.btn-circle.btn-sm {
    width: 50px;
    height: 50px;
    padding: 10px 15px
}
.btn.btn-circle.btn-sm>.fa,
.btn.btn-circle.btn-sm .typcn,
.btn.btn-circle.btn-sm .glyphicon,
.btn.btn-circle.btn-sm>[class*="wi-"] {
    font-size: 18px;
    line-height: 26px;
    border-radius: 50%
}
.btn.btn-circle.btn-lg {
    width: 70px;
    height: 70px;
    padding: 10px
}
.btn.btn-circle.btn-lg>.fa,
.btn.btn-circle.btn-lg .typcn,
.btn.btn-circle.btn-lg .glyphicon,
.btn.btn-circle.btn-lg>[class*="wi-"] {
    font-size: 28px;
    line-height: 46px
}
.btn.btn-labeled {
    position: relative;
    padding-left: 0
}
.btn.btn-labeled.btn-block .btn-label {
    margin-right: 0
}
.btn.btn-labeled .btn-label {
    display: block;
    float: left;
    margin: -7px 12px -9px -2px;
    padding: 8px 10px 8px 11px;
    position: relative;
    background: rgba(0, 0, 0, .06);
    line-height: 15px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}
.btn.btn-labeled .btn-label>.fa,
.btn.btn-labeled .btn-label .typcn,
.btn.btn-labeled .btn-label .glyphicon,
.btn.btn-labeled .btn-label>[class*="wi-"] {
    margin-right: 0
}
.btn.icon-only {
    width: 36px;
    height: 34px;
    padding: 5px 3px
}
.btn.icon-only>.fa,
.btn.icon-only .typcn,
.btn.icon-only .glyphicon,
.btn.icon-only>[class*="wi-"] {
    margin: 0;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    font-size: 22px;
    line-height: 22px
}
.btn>.fa,
.btn .typcn,
.btn .glyphicon,
.btn>[class*="wi-"] {
    display: inline;
    margin-right: 4px;
    font-size: 14px
}
.btn>.fa.right,
.btn .typcn.right,
.btn .glyphicon.right,
.btn>[class*="wi-"].right {
    margin-right: 0;
    margin-left: 4px
}
.btn-xs {
    font-size: 11px;
    padding: 2px 7px
}
.btn-xs>.fa,
.btn-xs>.typcn,
.btn-xs>.glyphicon,
.btn-xs>[class*="wi-"] {
    font-size: 12px;
    margin-right: 2px
}
.btn-xs>.fa.right,
.btn-xs>.typcn.right,
.btn-xs>.glyphicon.right,
.btn-xs>[class*="wi-"].right {
    margin-right: 0;
    margin-left: 2px
}
.btn-xs.icon-only {
    width: 24px;
    height: 24px;
    padding: 2px 6px
}
.btn-xs.icon-only>.fa,
.btn-xs.icon-only>.typcn,
.btn-xs.icon-only>.glyphicon,
.btn-xs.icon-only>[class*="wi-"] {
    font-size: 12px;
    line-height: 7px
}
.btn-sm {
    font-size: 12px;
    padding: 4px 9px;
    line-height: 1.39
}
.btn-sm>.fa,
.btn-sm>.typcn,
.btn-sm>.glyphicon,
.btn-sm>[class*="wi-"] {
    font-size: 13px;
    margin-right: 3px
}
.btn-sm>.fa.right,
.btn-sm>.typcn.right,
.btn-sm>.glyphicon.right,
.btn-sm>[class*="wi-"].right {
    margin-right: 0;
    margin-left: 3px
}
.btn-sm.icon-only {
    width: 31px;
    height: 30px;
    padding: 3px !important
}
.btn-sm.icon-only>.fa,
.btn-sm.icon-only>.typcn,
.btn-sm.icon-only>.glyphicon,
.btn-sm.icon-only>[class*="wi-"] {
    font-size: 20px;
    line-height: 21px
}
.btn-lg {
    line-height: 1.35;
    padding: 9px 18px;
    font-size: 14px
}
.btn-lg.icon-only {
    width: 40px;
    height: 38px;
    padding: 6px !important
}
.btn-lg.icon-only>.fa,
.btn-lg.icon-only>.typcn,
.btn-lg.icon-only>.glyphicon,
.btn-lg.icon-only>[class*="wi-"] {
    font-size: 24px;
    line-height: 26px
}
.btn-lg>.fa,
.btn-lg>.typcn,
.btn-lg>.glyphicon,
.btn-lg>[class*="wi-"] {
    font-size: 16px;
    margin-right: 6px
}
.btn-lg>.fa.right,
.btn-lg>.typcn.right,
.btn-lg>.glyphicon.right,
.btn-lg>[class*="wi-"].right {
    margin-right: 0;
    margin-left: 6px
}
.btn,
.btn-default,
.btn:focus,
.btn-default:focus {
    color: #444;
    background-color: #fff;
    border-color: #ccc
}
.btn:hover,
.btn-default:hover {
    color: #444
}
.btn.shiny,
.btn-default.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VkZWRlZCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(top, #fff 0, #ededed 100%);
    background-image: -moz-linear-gradient(top, #fff 0, #ededed 100%);
    background-image: -o-linear-gradient(top, #fff 0, #ededed 100%);
    background-image: linear-gradient(to bottom, #fff 0, #ededed 100%)
}
.btn.dropdown-toggle:not(:first-child),
.btn-default.dropdown-toggle:not(:first-child) {
    border-left-width: 0 !important;
    margin-left: -1px
}
.btn-default:hover,
.open .btn-default.dropdown-toggle {
    border-color: #b8b8b8;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .1)
}
.open .btn-default.dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}
.btn-primary,
.btn-primary:focus {
    background-color: #427fed !important;
    border-color: #427fed;
    color: #fff
}
.btn-primary:hover {
    color: #fff
}
.btn-primary.dropdown-toggle {
    border-left-color: #6a99ee !important
}
.btn-primary.active {
    background-color: #2c56b1 !important;
    border-color: #21448d
}
.btn-primary.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iNSUiIHN0b3AtY29sb3I9IiMwMDk1ZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwNWVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(center top, #0095ff 5%, #005eff 100%);
    background-image: -moz-linear-gradient(center top, #0095ff 5%, #005eff 100%);
    background-image: -o-linear-gradient(center top, #0095ff 5%, #005eff 100%);
    background-image: linear-gradient(center to bottom, #0095ff 5%, #005eff 100%)
}
.btn-primary:hover,
.open .btn-primary.dropdown-toggle {
    background-color: #4285f4 !important;
    border-color: #427fed
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #427fed !important;
    border-color: #427fed;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-info,
.btn-info:focus {
    background-color: #57b5e3 !important;
    border-color: #57b5e3;
    color: #fff
}
.btn-info:hover {
    color: #fff
}
.btn-info.dropdown-toggle {
    border-left-color: #8bcdee !important
}
.btn-info.active {
    background-color: #5fa6d3 !important;
    border-color: #4396cb
}
.btn-info.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMGFkZWUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwNzhhNSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#00adee), to(#0078a5))
}
.btn-info:hover,
.open .btn-info.dropdown-toggle {
    background-color: #6abce2 !important;
    border-color: #6abce2
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
    background-color: #57b5e3 !important;
    border-color: #57b5e3;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-success,
.btn-success:focus {
    background-color: #53a93f !important;
    border-color: #53a93f !important;
    color: #fff
}
.btn-success:hover {
    color: #fff
}
.btn-success.dropdown-toggle {
    border-left-color: #a0d468 !important
}
.btn-success.active {
    background-color: #3e802f !important;
    border-color: #2f6124
}
.btn-success.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM3ZGI3MmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzRlN2QwZSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#7db72f), to(#4e7d0e))
}
.btn-success:hover,
.open .btn-success.dropdown-toggle {
    background-color: #65b951 !important;
    border-color: #65b951
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
    background-color: #53a93f !important;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-warning,
.btn-warning:focus {
    background-color: #f4b400 !important;
    border-color: #f4b400;
    color: #fff
}
.btn-warning:hover {
    color: #fff
}
.btn-warning.dropdown-toggle {
    border-left-color: #ffce55 !important
}
.btn-warning.active {
    background-color: #f2a73e !important;
    border-color: #f0981c
}
.btn-warning.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmYWE1MWEiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y0N2EyMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#faa51a), to(#f47a20))
}
.btn-warning:hover,
.open .btn-warning.dropdown-toggle {
    background-color: #f6c12a !important;
    border-color: #f6c12a
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
    background-color: #f4b400 !important;
    border-color: #f4b400;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-danger,
.btn-danger:focus {
    background-color: #d73d32 !important;
    border-color: #d73d32;
    color: #fff
}
.btn-danger:hover {
    color: #fff
}
.btn-danger.dropdown-toggle {
    border-left-color: #e46f61 !important
}
.btn-danger.active {
    background-color: #be3e2e !important;
    border-color: #9a3323
}
.btn-danger.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlZDFjMjQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2FhMTMxNyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#ed1c24), to(#aa1317));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#ed1c24), to(#aa1317))
}
.btn-danger:hover,
.open .btn-danger.dropdown-toggle {
    background-color: #e74b37 !important;
    border-color: #e74b37
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
    background-color: #d73d32 !important;
    border-color: #d73d32;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-blue,
.btn-blue:focus {
    background-color: #5db2ff !important;
    border-color: #5db2ff;
    color: #fff
}
.btn-blue:hover {
    color: #fff
}
.btn-blue.dropdown-toggle {
    border-left-color: #81c1fb !important
}
.btn-blue.active {
    background-color: #449cec !important;
    border-color: #3d90db
}
.btn-blue.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM3MGJhZmQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ0OWNlYyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#70bafd), to(#449cec));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#70bafd), to(#449cec));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#70bafd), to(#449cec));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#70bafd), to(#449cec))
}
.btn-blue:hover,
.open .btn-blue.dropdown-toggle {
    background-color: #70bafd !important;
    border-color: #70bafd;
    color: #fff
}
.btn-blue.disabled,
.btn-blue[disabled],
fieldset[disabled] .btn-blue,
.btn-blue.disabled:hover,
.btn-blue[disabled]:hover,
fieldset[disabled] .btn-blue:hover,
.btn-blue.disabled:focus,
.btn-blue[disabled]:focus,
fieldset[disabled] .btn-blue:focus,
.btn-blue.disabled:active,
.btn-blue[disabled]:active,
fieldset[disabled] .btn-blue:active,
.btn-blue.disabled.active,
.btn-blue[disabled].active,
fieldset[disabled] .btn-blue.active {
    background-color: #5db2ff !important;
    border-color: #5db2ff;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-sky,
.btn-sky:focus {
    background-color: #11a9cc !important;
    border-color: #11a9cc;
    color: #fff
}
.btn-sky:hover {
    color: #fff
}
.btn-sky.dropdown-toggle {
    border-left-color: #81c1fb !important
}
.btn-sky.active {
    background-color: #2095b0 !important;
    border-color: #2f97af
}
.btn-sky.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMyOGIzZDMiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIwOTViMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#28b3d3), to(#2095b0));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#28b3d3), to(#2095b0));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#28b3d3), to(#2095b0));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#28b3d3), to(#2095b0))
}
.btn-sky:hover,
.open .btn-sky.dropdown-toggle {
    background-color: #28b3d3 !important;
    border-color: #28b3d3;
    color: #fff
}
.btn-sky.disabled,
.btn-sky[disabled],
fieldset[disabled] .btn-sky,
.btn-sky.disabled:hover,
.btn-sky[disabled]:hover,
fieldset[disabled] .btn-sky:hover,
.btn-sky.disabled:focus,
.btn-sky[disabled]:focus,
fieldset[disabled] .btn-sky:focus,
.btn-sky.disabled:active,
.btn-sky[disabled]:active,
fieldset[disabled] .btn-sky:active,
.btn-sky.disabled.active,
.btn-sky[disabled].active,
fieldset[disabled] .btn-sky.active {
    background-color: #11a9cc !important;
    border-color: #11a9cc;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-azure,
.btn-azure:focus {
    background-color: #2dc3e8 !important;
    border-color: #2dc3e8;
    color: #fff
}
.btn-azure:hover {
    color: #fff
}
.btn-azure.dropdown-toggle {
    border-left-color: #3ecdf1 !important
}
.btn-azure.active {
    background-color: #2badce !important;
    border-color: #2badce
}
.btn-azure.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzZWNkZjEiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzJiYWRjZSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#3ecdf1), to(#2badce));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#3ecdf1), to(#2badce));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#3ecdf1), to(#2badce));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#3ecdf1), to(#2badce))
}
.btn-azure:hover,
.open .btn-azure.dropdown-toggle {
    background-color: #3ecdf1 !important;
    border-color: #3ecdf1;
    color: #fff
}
.btn-azure.disabled,
.btn-azure[disabled],
fieldset[disabled] .btn-azure,
.btn-azure.disabled:hover,
.btn-azure[disabled]:hover,
fieldset[disabled] .btn-azure:hover,
.btn-azure.disabled:focus,
.btn-azure[disabled]:focus,
fieldset[disabled] .btn-azure:focus,
.btn-azure.disabled:active,
.btn-azure[disabled]:active,
fieldset[disabled] .btn-azure:active,
.btn-azure.disabled.active,
.btn-azure[disabled].active,
fieldset[disabled] .btn-azure.active {
    background-color: #2dc3e8 !important;
    border-color: #2dc3e8;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-palegreen,
.btn-palegreen:focus {
    background-color: #a0d468 !important;
    border-color: #a0d468;
    color: #fff
}
.btn-palegreen:hover {
    color: #fff
}
.btn-palegreen.dropdown-toggle {
    border-left-color: #a6d791 !important
}
.btn-palegreen.active {
    background-color: #74aa5d !important;
    border-color: #699755
}
.btn-palegreen.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM5Y2Q1ODQiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzc0YWE1ZCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#9cd584), to(#74aa5d));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#9cd584), to(#74aa5d));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#9cd584), to(#74aa5d));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#9cd584), to(#74aa5d))
}
.btn-palegreen:hover,
.open .btn-palegreen.dropdown-toggle {
    background-color: #9cd584 !important;
    border-color: #9cd584;
    color: #fff
}
.btn-palegreen.disabled,
.btn-palegreen[disabled],
fieldset[disabled] .btn-palegreen,
.btn-palegreen.disabled:hover,
.btn-palegreen[disabled]:hover,
fieldset[disabled] .btn-palegreen:hover,
.btn-palegreen.disabled:focus,
.btn-palegreen[disabled]:focus,
fieldset[disabled] .btn-palegreen:focus,
.btn-palegreen.disabled:active,
.btn-palegreen[disabled]:active,
fieldset[disabled] .btn-palegreen:active,
.btn-palegreen.disabled.active,
.btn-palegreen[disabled].active,
fieldset[disabled] .btn-palegreen.active {
    background-color: #a0d468 !important;
    border-color: #a0d468;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-yellow,
.btn-yellow:focus {
    background-color: #ffce55 !important;
    border-color: #ffce55;
    color: #fff
}
.btn-yellow:hover {
    color: #fff
}
.btn-yellow.dropdown-toggle {
    border-left-color: #f6d52e !important
}
.btn-yellow.active {
    background-color: #fbc220 !important;
    border-color: #f3ba17;
    color: #fff !important
}
.btn-yellow.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZGQzNWEiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiYzIyMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#fdd35a), to(#fbc220));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#fdd35a), to(#fbc220));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#fdd35a), to(#fbc220));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#fdd35a), to(#fbc220))
}
.btn-yellow:hover,
.open .btn-yellow.dropdown-toggle {
    background-color: #fdd35a !important;
    border-color: #fdd35a;
    color: #fff
}
.btn-yellow.disabled,
.btn-yellow[disabled],
fieldset[disabled] .btn-yellow,
.btn-yellow.disabled:hover,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow:hover,
.btn-yellow.disabled:focus,
.btn-yellow[disabled]:focus,
fieldset[disabled] .btn-yellow:focus,
.btn-yellow.disabled:active,
.btn-yellow[disabled]:active,
fieldset[disabled] .btn-yellow:active,
.btn-yellow.disabled.active,
.btn-yellow[disabled].active,
fieldset[disabled] .btn-yellow.active {
    background-color: #ffce55 !important;
    border-color: #ffce55;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-darkorange,
.btn-darkorange:focus {
    background-color: #ed4e2a !important;
    border-color: #ed4e2a;
    color: #fff
}
.btn-darkorange:hover {
    color: #fff
}
.btn-darkorange.dropdown-toggle {
    border-left-color: #f07154 !important
}
.btn-darkorange.active {
    background-color: #da411f !important;
    border-color: #cc3918
}
.btn-darkorange.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlZjY5NGIiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2RhNDExZiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#ef694b), to(#da411f));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#ef694b), to(#da411f));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#ef694b), to(#da411f));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#ef694b), to(#da411f))
}
.btn-darkorange:hover,
.open .btn-darkorange.dropdown-toggle {
    background-color: #ef694b !important;
    border-color: #ef694b;
    color: #fff
}
.btn-darkorange.disabled,
.btn-darkorange[disabled],
fieldset[disabled] .btn-darkorange,
.btn-darkorange.disabled:hover,
.btn-darkorange[disabled]:hover,
fieldset[disabled] .btn-darkorange:hover,
.btn-darkorange.disabled:focus,
.btn-darkorange[disabled]:focus,
fieldset[disabled] .btn-darkorange:focus,
.btn-darkorange.disabled:active,
.btn-darkorange[disabled]:active,
fieldset[disabled] .btn-darkorange:active,
.btn-darkorange.disabled.active,
.btn-darkorange[disabled].active,
fieldset[disabled] .btn-darkorange.active {
    background-color: #ed4e2a !important;
    border-color: #ed4e2a;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-magenta,
.btn-magenta:focus {
    background-color: #bc5679 !important;
    border-color: #bc5679;
    color: #fff
}
.btn-magenta:hover {
    color: #fff
}
.btn-magenta.dropdown-toggle {
    border-left-color: #c96d8c !important
}
.btn-magenta.active {
    background-color: #a5325a !important;
    border-color: #9f3156
}
.btn-magenta.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNjNDYyODMiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2E1MzI1YSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#c46283), to(#a5325a));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#c46283), to(#a5325a));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#c46283), to(#a5325a));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#c46283), to(#a5325a))
}
.btn-magenta:hover,
.open .btn-magenta.dropdown-toggle {
    background-color: #c46283 !important;
    border-color: #c46283;
    color: #fff
}
.btn-magenta.disabled,
.btn-magenta[disabled],
fieldset[disabled] .btn-magenta,
.btn-magenta.disabled:hover,
.btn-magenta[disabled]:hover,
fieldset[disabled] .btn-magenta:hover,
.btn-magenta.disabled:focus,
.btn-magenta[disabled]:focus,
fieldset[disabled] .btn-magenta:focus,
.btn-magenta.disabled:active,
.btn-magenta[disabled]:active,
fieldset[disabled] .btn-magenta:active,
.btn-magenta.disabled.active,
.btn-magenta[disabled].active,
fieldset[disabled] .btn-magenta.active {
    background-color: #bc5679 !important;
    border-color: #bc5679;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-purple,
.btn-purple:focus {
    background-color: #7e3794 !important;
    border-color: #7e3794;
    color: #fff
}
.btn-purple:hover {
    color: #fff
}
.btn-purple.dropdown-toggle {
    border-left-color: #9852ae !important
}
.btn-purple.active {
    background-color: #69257e !important;
    border-color: #601e74
}
.btn-purple.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM4YzQ0YTIiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY5MjU3ZSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#8c44a2), to(#69257e));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#8c44a2), to(#69257e));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#8c44a2), to(#69257e));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#8c44a2), to(#69257e))
}
.btn-purple:hover,
.open .btn-purple.dropdown-toggle {
    background-color: #8c44a2 !important;
    border-color: #8c44a2;
    color: #fff
}
.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
    background-color: #7e3794 !important;
    border-color: #7e3794;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn-maroon,
.btn-maroon:focus {
    background-color: #981b48 !important;
    border-color: #981b48;
    color: #fff
}
.btn-maroon:hover {
    color: #fff
}
.btn-maroon.dropdown-toggle {
    border-left-color: #b55274 !important
}
.btn-maroon.active {
    background-color: #7c1138 !important;
    border-color: #9f3156
}
.btn-maroon.shiny {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNhODI4NTYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzg2MTIzYyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(linear, left top, left bottom, from(#a82856), to(#86123c));
    background-image: -moz-linear-gradient(linear, left top, left bottom, from(#a82856), to(#86123c));
    background-image: -o-linear-gradient(linear, left top, left bottom, from(#a82856), to(#86123c));
    background-image: linear-gradient(linear, left to bottom, left bottom, from(#a82856), to(#86123c))
}
.btn-maroon:hover,
.open .btn-maroon.dropdown-toggle {
    background-color: #a82856 !important;
    border-color: #a82856;
    color: #fff
}
.btn-maroon.disabled,
.btn-maroon[disabled],
fieldset[disabled] .btn-maroon,
.btn-maroon.disabled:hover,
.btn-maroon[disabled]:hover,
fieldset[disabled] .btn-maroon:hover,
.btn-maroon.disabled:focus,
.btn-maroon[disabled]:focus,
fieldset[disabled] .btn-maroon:focus,
.btn-maroon.disabled:active,
.btn-maroon[disabled]:active,
fieldset[disabled] .btn-maroon:active,
.btn-maroon.disabled.active,
.btn-maroon[disabled].active,
fieldset[disabled] .btn-maroon.active {
    background-color: #981b48 !important;
    border-color: #981b48;
    zoom: 1;
    filter: alpha(opacity=50);
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5
}
.btn.disabled.active,
.btn[disabled].active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:active,
.btn[disabled]:active {
    outline: 0
}
.btn.disabled:active,
.btn[disabled]:active {
    top: 0;
    left: 0
}
.open .btn.dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}
.btn-group>.btn:not(:first-child):not(.dropdown-toggle) {
    border-left: 0
}
.btn-group>.btn,
.btn-group>.btn+.btn {
    margin: 0
}
.btn-group>.btn:first-child {
    margin: 0;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box
}
.btn-group>.btn:last-child {
    -webkit-border-radius: 0 2px 2px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 2px 2px 0;
    -moz-background-clip: padding;
    border-radius: 0 2px 2px 0;
    background-clip: padding-box
}
.btn-group>.btn>.caret {
    margin-left: 1px;
    border-width: 5px;
    border-top-color: #eee;
    margin-top: 1px
}
.btn-group>.btn.btn-default>.caret {
    border-top-color: #333
}
.btn-group>.btn+.btn.dropdown-toggle {
    padding-left: 7px;
    padding-right: 7px
}
.btn-group>.btn+.btn-lg.dropdown-toggle {
    padding-right: 10px;
    padding-left: 10px
}
.btn-group>.btn+.btn-sm.dropdown-toggle {
    padding-right: 6px;
    padding-left: 6px
}
.btn-group>.btn+.btn-sm.dropdown-toggle .fa {
    line-height: 13px
}
.btn-group>.btn+.btn-xs.dropdown-toggle {
    padding-right: 5px;
    padding-left: 5px
}
.btn-group>.btn+.btn-xs.dropdown-toggle .fa {
    line-height: 13px
}
.btn-group .dropdown-toggle {
    -webkit-border-radius: 0 2px 2px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 2px 2px 0;
    -moz-background-clip: padding;
    border-radius: 0 2px 2px 0;
    background-clip: padding-box
}
.btn-group .dropdown-toggle .fa {
    display: inline-block;
    margin-top: 1px;
    font-size: 14px;
    line-height: 14px;
    margin-right: 0;
    margin-left: 0
}
.btn-group>.btn,
.btn-group+.btn {
    margin: 0
}
.btn-group>.btn.active:after,
.btn-group+.btn.active:after {
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-bottom-width: 1px
}
.btn-group-vertical>.btn:not(:last-child) {
    border-bottom: 0
}
.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-right-radius: 2px
}
.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-bottom-left-radius: 2px
}
.btn-group-vertical>.btn,
.btn-group-vertical>.btn+.btn {
    margin: 0
}
.btn-group-vertical>.btn:first-child {
    margin-right: 0
}
.accordion.spaced.panel-group .panel+.panel {
    margin-top: 3px
}
.accordion.panel-group {
    border: 1px solid #d8d8d8;
    border-top-width: 0
}
.accordion.panel-group .panel {
    border-radius: 0;
    border: 0;
    background-color: #fff;
    box-shadow: none
}
.accordion.panel-group .panel:last-child {
    border-bottom-width: 1px
}
.accordion.panel-group .panel .collapse {
    background-color: #fff
}
.accordion.panel-group .panel+.panel {
    margin-top: 0
}
.accordion.panel-group .panel-heading {
    padding: 0
}
.accordion.panel-group .panel-heading+.panel-collapse .panel-body {
    border-top-color: #d5d5d5 !important
}
.accordion.panel-group .panel-heading .accordion-toggle {
    color: #555;
    background-color: #fff;
    position: relative;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    padding: 10px;
    display: block
}
.accordion.panel-group .panel-heading .accordion-toggle:after {
    -webkit-transition: all .218s;
    -moz-transition: all .218s;
    -o-transition: all .218s;
    transition: all .218s;
    position: absolute;
    right: 20px;
    top: 8px;
    font-family: 'FontAwesome';
    font-size: 17px;
    font-weight: bold;
    content: "";
    color: #bebebe
}
.accordion.panel-group .panel-heading .accordion-toggle.collapsed:after {
    content: ""
}
.accordion.panel-group .panel-heading .accordion-toggle.collapsed {
    color: #737373;
    font-weight: normal;
    background-color: #fff;
    -webkit-transition: border .218s;
    -moz-transition: border .218s;
    -o-transition: border .218s;
    transition: border .218s;
    border-bottom: 1px solid #f5f5f5
}
.accordion.panel-group .panel-heading .accordion-toggle:hover {
    color: #262626;
    text-decoration: none
}
.accordion.panel-group .panel-heading .accordion-toggle:hover>[class*="icon-"]:first-child {
    text-decoration: none
}
.accordion.panel-group .panel-heading .accordion-toggle:hover:after {
    color: #262626
}
.accordion.panel-group .panel-heading .accordion-toggle:focus,
.accordion.panel-group .panel-heading .accordion-toggle:active {
    outline: 0;
    text-decoration: none
}
.accordion.panel-group .panel-heading .accordion-toggle>[class*="fa-"]:first-child {
    width: 16px
}
.accordion.panel-group .panel-body {
    background-color: #eee;
    border-left: 2px solid #eee;
    color: #262626 !important
}
.accordion.panel-group.no-padding {
    padding: 0
}
.label,
.badge {
    display: inline-block;
    font-weight: 300;
    -webkit-text-shadow: none !important;
    text-shadow: none !important
}
.label {
    font-size: 12px;
    padding: 4px 6px 4px 6px;
    -webkit-border-radius: 2px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 2px !important;
    -moz-background-clip: padding !important;
    border-radius: 2px !important;
    background-clip: padding-box !important
}
.label.label-sm {
    font-size: 12px;
    padding: 1px 4px 1px 4px
}
.badge {
    font-size: 11px !important;
    font-weight: 300;
    text-align: center;
    background-color: #ccc;
    height: 18px;
    padding: 3px 6px 3px 6px;
    -webkit-border-radius: 12px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 12px !important;
    -moz-background-clip: padding !important;
    border-radius: 12px !important;
    background-clip: padding-box !important;
    -webkit-text-shadow: none !important;
    text-shadow: none !important;
    vertical-align: middle
}
.badge.badge-square {
    -webkit-border-radius: 2px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 2px !important;
    -moz-background-clip: padding !important;
    border-radius: 2px !important;
    background-clip: padding-box !important
}
.badge.badge-empty {
    display: inline-block;
    width: 10px;
    height: 10px;
    padding: 0;
    vertical-align: baseline
}
.badge-default,
.label-default {
    background-color: #ccc !important
}
.badge-default.graded,
.label-default.graded {
    background: linear-gradient(to right, #ccc, #e5e5e5) !important
}
.badge-blue,
.label-blue {
    background-color: #5db2ff !important
}
.badge-blue.graded,
.label-blue.graded {
    background: linear-gradient(to right, #5db2ff, #80c1fb) !important
}
.label-info,
.badge-info {
    background-color: #57b5e3;
    background-image: none !important
}
.label-info.graded,
.badge-info.graded {
    background: linear-gradient(to right, #57b5e3, #c4e6f6) !important
}
.label-sky,
.badge-sky {
    background-color: #11a9cc;
    background-image: none !important
}
.label-sky.graded,
.badge-sky.graded {
    background: linear-gradient(to right, #11a9cc, #c4e6f6) !important
}
.badge-primary,
.label-primary {
    background-color: #4374e0 !important
}
.badge-primary.graded,
.label-primary.graded {
    background: linear-gradient(to right, #4374e0, #aec9fa) !important
}
.label-azure,
.badge-azure {
    background-color: #2dc3e8;
    background-image: none !important
}
.label-azure.graded,
.badge-azure.graded {
    background: linear-gradient(to right, #2dc3e8, #5edfff) !important
}
.label-blueberry,
.badge-blueberry {
    background-color: #6f85bf;
    background-image: none !important
}
.label-blueberry.graded,
.badge-blueberry.graded {
    background: linear-gradient(to right, #6f85bf, #ced5e9) !important
}
.label-palegreen,
.badge-palegreen {
    background-color: #a0d468;
    background-image: none !important
}
.label-palegreen.graded,
.badge-palegreen.graded {
    background: linear-gradient(to right, #a0d468, #c5e9b6) !important
}
.label-success,
.badge-success {
    background-color: #53a93f;
    background-image: none !important
}
.label-success.graded,
.badge-success.graded {
    background: linear-gradient(to right, #53a93f, #b0e0a4) !important
}
.label-green,
.badge-green {
    background-color: #8cc474;
    background-image: none !important
}
.label-green.graded,
.badge-green.graded {
    background: linear-gradient(to right, #8cc474, #a0d468) !important
}
.label-lightyellow,
.badge-lightyellow {
    background-color: #f6d52e;
    background-image: none !important
}
.label-lightyellow.graded,
.badge-lightyellow.graded {
    background: linear-gradient(to right, #f6d52e, #f8e26d) !important
}
.label-yellow,
.badge-yellow {
    background-color: #ffce55;
    background-image: none !important
}
.label-yellow.graded,
.badge-yellow.graded {
    background: linear-gradient(to right, #ffce55, #f6d52e) !important
}
.label-warning,
.badge-warning {
    background-color: #f4b400;
    background-image: none !important
}
.label-warning.graded,
.badge-warning.graded {
    background: linear-gradient(to right, #f4b400, #f8df95) !important
}
.label-gold,
.badge-gold {
    background-color: #f9b256;
    background-image: none !important
}
.label-gold.graded,
.badge-gold.graded {
    background: linear-gradient(to right, #f9b256, #fece90) !important
}
.label-orange,
.badge-orange {
    background-color: #fb6e52;
    background-image: none !important
}
.label-orange.graded,
.badge-orange.graded {
    background: linear-gradient(to right, #fb6e52, #fb9f8d) !important
}
.label-lightred,
.badge-lightred {
    background-color: #e46f61;
    background-image: none !important
}
.label-lightred.graded,
.badge-lightred.graded {
    background: linear-gradient(to right, #e46f61, #f88f83) !important
}
.label-darkorange,
.badge-darkorange {
    background-color: #ed4e2a;
    background-image: none !important
}
.label-darkorange.graded,
.badge-darkorange.graded {
    background: linear-gradient(to right, #ed4e2a, #f5a998) !important
}
.label-red,
.badge-red {
    background-color: #df5138;
    background-image: none !important
}
.label-red.graded,
.badge-red.graded {
    background: linear-gradient(to right, #df5138, #f5836f) !important
}
.label-pink,
.badge-pink {
    background-color: #e75b8d;
    background-image: none !important
}
.label-pink.graded,
.badge-pink.graded {
    background: linear-gradient(to right, #e75b8d, #f299b9) !important
}
.label-darkpink,
.badge-darkpink {
    background-color: #cc324b;
    background-image: none !important
}
.label-darkpink.graded,
.badge-darkpink.graded {
    background: linear-gradient(to right, #cc324b, #fb6880) !important
}
.label-danger,
.badge-danger {
    background-color: #d73d32;
    background-image: none !important
}
.label-danger.graded,
.badge-danger.graded {
    background: linear-gradient(to right, #d73d32, #f7b5b0) !important
}
.label-magenta,
.badge-magenta {
    background-color: #bc5679;
    background-image: none !important
}
.label-magenta.graded,
.badge-magenta.graded {
    background: linear-gradient(to right, #bc5679, #e9abc0) !important
}
.label-purple,
.badge-purple {
    background-color: #7e3794;
    background-image: none !important
}
.label-purple.graded,
.badge-purple.graded {
    background: linear-gradient(to right, #7e3794, #daafe8) !important
}
.label-maroon,
.badge-maroon {
    background-color: #981b48;
    background-image: none !important
}
.label-maroon.graded,
.badge-maroon.graded {
    background: linear-gradient(to right, #981b48, #eab6c9) !important
}
.progress {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #e5e5e5;
    height: 18px;
    position: relative
}
.progress:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: 8121991;
    background-image: -webkit-radial-gradient(9px 9px 0, circle cover, #0ff 0, rgba(0, 0, 255, 0) 100%, #00f 95%);
    background-image: -moz-radial-gradient(9px 9px 0, circle cover, #0ff 0, rgba(0, 0, 255, 0) 100%, #00f 95%);
    background-image: -o-radial-gradient(9px 9px 0, circle cover, #0ff 0, rgba(0, 0, 255, 0) 100%, #00f 95%);
    background-image: radial-gradient(9px 9px 0, circle cover, #0ff 0, rgba(0, 0, 255, 0) 100%, #00f 95%)
}
.progress .progress-bar {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    line-height: 18px
}
.progress.progress-xlg {
    height: 26px
}
.progress.progress-xlg .progress-bar {
    line-height: 25px
}
.progress.progress-lg {
    height: 22px
}
.progress.progress-lg .progress-bar {
    line-height: 21px
}
.progress.progress-sm {
    height: 12px
}
.progress.progress-sm .progress-bar {
    line-height: 10px;
    font-size: 11px
}
.progress.progress-xs {
    height: 9px
}
.progress.progress-xs .progress-bar {
    line-height: 8px;
    font-size: 11px
}
.progress.progress-xxs {
    height: 4px
}
.progress.progress-xxs .progress-bar {
    line-height: 4px;
    font-size: 0
}
.progress.progress-right .progress-bar {
    float: right
}
.progress.progress-shadowed {
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    box-shadow: 0 0 4px rgba(0, 0, 0, .2)
}
.progress.progress-no-radius {
    -webkit-border-radius: 0 !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 0 !important;
    -moz-background-clip: padding !important;
    border-radius: 0 !important;
    background-clip: padding-box !important
}
.progress.progress-vertical {
    width: 25px;
    height: 100%;
    min-height: 150px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 0
}
.progress.progress-vertical .progress-bar {
    position: absolute;
    width: 25px;
    overflow: hidden;
    line-height: 20px;
    height: 0;
    -webkit-transition: height .5s ease;
    -moz-transition: height .5s ease;
    -o-transition: height .5s ease;
    transition: height .5s ease
}
.progress.progress-vertical .progress-bar span {
    width: 100%;
    font-size: 12px;
    text-align: center
}
.progress.progress-vertical.progress-bottom {
    position: relative
}
.progress.progress-vertical.progress-bottom .progress-bar {
    position: absolute;
    bottom: 0
}
.progress.progress-vertical.progress-bottom .progress-bar span {
    position: absolute;
    left: 0;
    bottom: 0
}
.progress.progress-vertical.progress-xlg {
    width: 40px
}
.progress.progress-vertical.progress-xlg .progress-bar {
    width: 40px
}
.progress.progress-vertical.progress-lg {
    width: 30px
}
.progress.progress-vertical.progress-lg .progress-bar {
    width: 30px
}
.progress.progress-vertical.progress-sm {
    width: 20px
}
.progress.progress-vertical.progress-sm .progress-bar {
    width: 20px
}
.progress.progress-vertical.progress-sm .progress-bar span {
    font-size: 8px
}
.progress.progress-vertical.progress-xs {
    width: 10px
}
.progress.progress-vertical.progress-xs .progress-bar {
    width: 10px
}
.progress.progress-vertical.progress-xs .progress-bar span {
    font-size: 0
}
.progress.progress-vertical.progress-xxs {
    width: 5px
}
.progress.progress-vertical.progress-xxs .progress-bar {
    width: 5px
}
.progress.progress-vertical.progress-xxs .progress-bar span {
    font-size: 0
}
.progress.progress-vertical.progress-bordered {
    width: 31px
}
.progress.progress-vertical.progress-bordered.progress-bottom .progress-bar {
    bottom: 3px
}
.progress.progress-vertical.progress-bordered.progress-xlg {
    width: 48px
}
.progress.progress-vertical.progress-bordered.progress-lg {
    width: 38px
}
.progress.progress-vertical.progress-bordered.progress-sm {
    width: 28px
}
.progress.progress-vertical.progress-bordered.progress-xs {
    width: 18px
}
.progress.progress-vertical.progress-bordered.progress-xxs {
    width: 13px
}
.progress.progress-bordered {
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;
    padding: 3px;
    height: 24px
}
.progress.progress-bordered.progress-xlg {
    height: 32px
}
.progress.progress-bordered.progress-lg {
    height: 28px
}
.progress.progress-bordered.progress-sm {
    height: 18px
}
.progress.progress-bordered.progress-xs {
    height: 15px
}
.progress.progress-bordered.progress-xxs {
    height: 10px
}
.progress-bar {
    background-color: #2dc3e8
}
.progress-bar-danger {
    background-color: #d73d32 !important
}
.progress-bar-success {
    background-color: #53a93f !important
}
.progress-bar-warning {
    background-color: #f4b400 !important
}
.progress-bar-silver {
    background-color: #777 !important
}
.progress-bar-inverse {
    background-color: #444 !important
}
.progress-bar-blue {
    background-color: #5db2ff !important
}
.progress-bar-info {
    background-color: #57b5e3 !important
}
.progress-bar-azure {
    background-color: #2dc3e8 !important
}
.progress-bar-sky {
    background-color: #11a9cc !important
}
.progress-bar-blueberry {
    background-color: #6f85bf !important
}
.progress-bar-palegreen {
    background-color: #a0d468 !important
}
.progress-bar-orange {
    background-color: #fb6e52 !important
}
.progress-bar-darkorange {
    background-color: #ed4e2a !important
}
.progress-bar-magenta {
    background-color: #bc5679 !important
}
.progress-bar-purple {
    background-color: #7e3794 !important
}
.progress-bar-maroon {
    background-color: #981b48 !important
}
.progress-striped .progress-bar {
    background-image: 8121991;
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)
}
.well {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    background-color: #fbfbfb;
    border: 0;
    color: #262626;
    position: relative;
    margin-bottom: 30px;
    min-height: 0
}
.well.attached {
    position: relative;
    top: -1px;
    bottom: -1px;
    border-radius: 0;
    margin: 0;
    box-shadow: 0 0 0 1px #ddd
}
.well.attached.top {
    top: 0;
    bottom: -1px;
    margin-top: 1em;
    margin-bottom: 0;
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    border-bottom: 0
}
.well.attached.bottom {
    top: -1px;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 1em;
    -webkit-border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 3px 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 3px 3px;
    background-clip: padding-box;
    border-top: 0
}
.well.attached.bottom:last-child {
    margin-bottom: 0
}
.well.bordered-top {
    border-top: 5px solid #2dc3e8
}
.well.bordered-bottom {
    border-bottom: 5px solid #2dc3e8
}
.well.bordered-left {
    border-left: 5px solid #2dc3e8
}
.well.bordered-right {
    border-right: 5px solid #2dc3e8
}
.well[class*="bg-"] {
    color: #f5f5f5
}
.well h1,
.well h2,
.well h3,
.well h4,
.well h5,
.well h6 {
    margin-top: 0
}
.well h1,
.well h2,
.well h3 {
    line-height: 36px
}
.well.with-header {
    padding-top: 60px
}
.well.with-footer {
    padding-bottom: 60px
}
.well .header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-bottom: 1px solid #e5e5e5
}
.well .header[class*="bg-"] {
    color: #fff;
    border-bottom: 0
}
.well .header[class*="bordered-"] {
    border-bottom: 3px solid #fff
}
.well .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px;
    border-top: 1px solid #e5e5e5;
    border-radius: 0 0 2px 2px
}
.well .footer[class*="bordered-"] {
    border-top: 3px solid #fff
}
.well hr.wide {
    margin-right: -19px;
    margin-left: -19px
}
.popover {
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    padding: 0;
    border-color: #ccc;
    border-width: 1px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    color: #444;
    background-color: #fbfbfb
}
.popover .popover.bottom .arrow:after {
    top: 1px;
    margin-left: -10px;
    border-bottom-color: #f7f7f7;
    border-top-width: 0
}
.popover.inverted {
    background-color: #262626;
    color: #f5f5f5
}
.popover.inverted .popover-title {
    background-color: #262626;
    color: #f5f5f5
}
.popover.inverted.top .arrow:after {
    border-top-color: #262626
}
.popover.inverted.bottom .arrow:after {
    border-bottom-color: #262626
}
.popover.inverted.left .arrow:after {
    border-left-color: #262626
}
.popover.inverted.right .arrow:after {
    border-right-color: #262626
}
.popover.dark {
    background-color: #e5e5e5
}
.popover.dark .popover-title {
    background-color: #e5e5e5
}
.popover.dark.top .arrow:after {
    border-top-color: #e5e5e5
}
.popover.dark.bottom .arrow:after {
    border-bottom-color: #e5e5e5
}
.popover.dark.left .arrow:after {
    border-left-color: #e5e5e5
}
.popover.dark.right .arrow:after {
    border-right-color: #e5e5e5
}
.popover-title {
    -webkit-border-radius: 3px 3px 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 3px 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    color: #000;
    border-bottom: 3px solid #2dc3e8
}
.popover-notitle+.popover .popover-title {
    display: none
}
.popover-notitle+.popover.top .arrow:after {
    border-top-color: #fff
}
.popover-notitle+.popover.bottom .arrow:after {
    border-bottom-color: #fff
}
.popover-notitle+.popover.left .arrow:after {
    border-left-color: #fff
}
.popover-notitle+.popover.right .arrow:after {
    border-left-color: #fff
}
.tooltip.in {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.tooltip.top .tooltip-arrow {
    border-top-color: #262626
}
.tooltip.right .tooltip-arrow {
    border-right-color: #262626
}
.tooltip.left .tooltip-arrow {
    border-left-color: #262626
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #262626
}
.tooltip-lg+.tooltip>.tooltip-inner {
    font-size: 15px !important
}
.tooltip-inner {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    -webkit-box-shadow: 1px 1px rgba(229, 229, 229, .5);
    -moz-box-shadow: 1px 1px rgba(229, 229, 229, .5);
    box-shadow: 1px 1px rgba(229, 229, 229, .5);
    -webkit-transition: visibility 0, opacity .13s ease-in;
    -moz-transition: visibility 0, opacity .13s ease-in;
    -o-transition: visibility 0, opacity .13s ease-in;
    transition: visibility 0, opacity .13s ease-in;
    background-color: #262626;
    border: 1px solid #262626;
    color: #aaa;
    padding: 8px 8px;
    font-size: 13px
}
.tooltip-white+.tooltip>.tooltip-inner {
    border: 1px solid #fff;
    background-color: #fff;
    color: #262626
}
.tooltip-white+.tooltip.top .tooltip-arrow {
    border-top-color: #fff
}
.tooltip-white+.tooltip.right .tooltip-arrow {
    border-right-color: #fff
}
.tooltip-white+.tooltip.left .tooltip-arrow {
    border-left-color: #fff
}
.tooltip-white+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #fff
}
.tooltip-whitesmoke+.tooltip>.tooltip-inner {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    color: #262626
}
.tooltip-whitesmoke+.tooltip.top .tooltip-arrow {
    border-top-color: #f5f5f5
}
.tooltip-whitesmoke+.tooltip.right .tooltip-arrow {
    border-right-color: #f5f5f5
}
.tooltip-whitesmoke+.tooltip.left .tooltip-arrow {
    border-left-color: #f5f5f5
}
.tooltip-whitesmoke+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f5f5f5
}
.tooltip-snow+.tooltip>.tooltip-inner {
    border: 1px solid #fbfbfb;
    background-color: #fbfbfb;
    color: #262626
}
.tooltip-snow+.tooltip.top .tooltip-arrow {
    border-top-color: #fbfbfb
}
.tooltip-snow+.tooltip.right .tooltip-arrow {
    border-right-color: #fbfbfb
}
.tooltip-snow+.tooltip.left .tooltip-arrow {
    border-left-color: #fbfbfb
}
.tooltip-snow+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #fbfbfb
}
.tooltip-seashell+.tooltip>.tooltip-inner {
    border: 1px solid #f3f3f3;
    background-color: #f3f3f3;
    color: #262626
}
.tooltip-seashell+.tooltip.top .tooltip-arrow {
    border-top-color: #f3f3f3
}
.tooltip-seashell+.tooltip.right .tooltip-arrow {
    border-right-color: #f3f3f3
}
.tooltip-seashell+.tooltip.left .tooltip-arrow {
    border-left-color: #f3f3f3
}
.tooltip-seashell+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f3f3f3
}
.tooltip-ivory+.tooltip>.tooltip-inner {
    border: 1px solid #eee;
    background-color: #eee;
    color: #262626
}
.tooltip-ivory+.tooltip.top .tooltip-arrow {
    border-top-color: #eee
}
.tooltip-ivory+.tooltip.right .tooltip-arrow {
    border-right-color: #eee
}
.tooltip-ivory+.tooltip.left .tooltip-arrow {
    border-left-color: #eee
}
.tooltip-ivory+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #eee
}
.tooltip-platinum+.tooltip>.tooltip-inner {
    border: 1px solid #e5e5e5;
    background-color: #e5e5e5;
    color: #262626
}
.tooltip-platinum+.tooltip.top .tooltip-arrow {
    border-top-color: #e5e5e5
}
.tooltip-platinum+.tooltip.right .tooltip-arrow {
    border-right-color: #e5e5e5
}
.tooltip-platinum+.tooltip.left .tooltip-arrow {
    border-left-color: #e5e5e5
}
.tooltip-platinum+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #e5e5e5
}
.tooltip-lightgray+.tooltip>.tooltip-inner {
    border: 1px solid #d0d0d0;
    background-color: #d0d0d0;
    color: #262626
}
.tooltip-lightgray+.tooltip.top .tooltip-arrow {
    border-top-color: #d0d0d0
}
.tooltip-lightgray+.tooltip.right .tooltip-arrow {
    border-right-color: #d0d0d0
}
.tooltip-lightgray+.tooltip.left .tooltip-arrow {
    border-left-color: #d0d0d0
}
.tooltip-lightgray+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #d0d0d0
}
.tooltip-gray+.tooltip>.tooltip-inner {
    border: 1px solid #ccc;
    background-color: #ccc;
    color: #262626
}
.tooltip-gray+.tooltip.top .tooltip-arrow {
    border-top-color: #ccc
}
.tooltip-gray+.tooltip.right .tooltip-arrow {
    border-right-color: #ccc
}
.tooltip-gray+.tooltip.left .tooltip-arrow {
    border-left-color: #ccc
}
.tooltip-gray+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #ccc
}
.tooltip-darkgray+.tooltip>.tooltip-inner {
    border: 1px solid #999;
    background-color: #999;
    color: #262626
}
.tooltip-darkgray+.tooltip.top .tooltip-arrow {
    border-top-color: #999
}
.tooltip-darkgray+.tooltip.right .tooltip-arrow {
    border-right-color: #999
}
.tooltip-darkgray+.tooltip.left .tooltip-arrow {
    border-left-color: #999
}
.tooltip-darkgray+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #999
}
.tooltip-silver+.tooltip>.tooltip-inner {
    border: 1px solid #777;
    background-color: #777;
    color: #fff
}
.tooltip-silver+.tooltip.top .tooltip-arrow {
    border-top-color: #777
}
.tooltip-silver+.tooltip.right .tooltip-arrow {
    border-right-color: #777
}
.tooltip-silver+.tooltip.left .tooltip-arrow {
    border-left-color: #777
}
.tooltip-silver+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #777
}
.tooltip-sonic-silver+.tooltip>.tooltip-inner {
    border: 1px solid #737373;
    background-color: #737373;
    color: #fff
}
.tooltip-sonic-silver+.tooltip.top .tooltip-arrow {
    border-top-color: #737373
}
.tooltip-sonic-silver+.tooltip.right .tooltip-arrow {
    border-right-color: #737373
}
.tooltip-sonic-silver+.tooltip.left .tooltip-arrow {
    border-left-color: #737373
}
.tooltip-sonic-silver+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #737373
}
.tooltip-storm-cloud+.tooltip>.tooltip-inner {
    border: 1px solid #666;
    background-color: #666;
    color: #fff
}
.tooltip-storm-cloud+.tooltip.top .tooltip-arrow {
    border-top-color: #666
}
.tooltip-storm-cloud+.tooltip.right .tooltip-arrow {
    border-right-color: #666
}
.tooltip-storm-cloud+.tooltip.left .tooltip-arrow {
    border-left-color: #666
}
.tooltip-storm-cloud+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #666
}
.tooltip-lightcarbon+.tooltip>.tooltip-inner {
    border: 1px solid #555;
    background-color: #555;
    color: #fff
}
.tooltip-lightcarbon+.tooltip.top .tooltip-arrow {
    border-top-color: #555
}
.tooltip-lightcarbon+.tooltip.right .tooltip-arrow {
    border-right-color: #555
}
.tooltip-lightcarbon+.tooltip.left .tooltip-arrow {
    border-left-color: #555
}
.tooltip-lightcarbon+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #555
}
.tooltip-carbon+.tooltip>.tooltip-inner {
    border: 1px solid #444;
    background-color: #444;
    color: #fff
}
.tooltip-carbon+.tooltip.top .tooltip-arrow {
    border-top-color: #444
}
.tooltip-carbon+.tooltip.right .tooltip-arrow {
    border-right-color: #444
}
.tooltip-carbon+.tooltip.left .tooltip-arrow {
    border-left-color: #444
}
.tooltip-carbon+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #444
}
.tooltip-slate-gray+.tooltip>.tooltip-inner {
    border: 1px solid #333;
    background-color: #333;
    color: #fff
}
.tooltip-slate-gray+.tooltip.top .tooltip-arrow {
    border-top-color: #333
}
.tooltip-slate-gray+.tooltip.right .tooltip-arrow {
    border-right-color: #333
}
.tooltip-slate-gray+.tooltip.left .tooltip-arrow {
    border-left-color: #333
}
.tooltip-slate-gray+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #333
}
.tooltip-darkcarbon+.tooltip>.tooltip-inner {
    border: 1px solid #262626;
    background-color: #262626;
    color: #fff
}
.tooltip-darkcarbon+.tooltip.top .tooltip-arrow {
    border-top-color: #262626
}
.tooltip-darkcarbon+.tooltip.right .tooltip-arrow {
    border-right-color: #262626
}
.tooltip-darkcarbon+.tooltip.left .tooltip-arrow {
    border-left-color: #262626
}
.tooltip-darkcarbon+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #262626
}
.tooltip-blue+.tooltip>.tooltip-inner {
    border: 1px solid #5db2ff;
    background-color: #5db2ff;
    color: #fff
}
.tooltip-blue+.tooltip.top .tooltip-arrow {
    border-top-color: #5db2ff
}
.tooltip-blue+.tooltip.right .tooltip-arrow {
    border-right-color: #5db2ff
}
.tooltip-blue+.tooltip.left .tooltip-arrow {
    border-left-color: #5db2ff
}
.tooltip-blue+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #5db2ff
}
.tooltip-info+.tooltip>.tooltip-inner {
    border: 1px solid #57b5e3;
    background-color: #57b5e3;
    color: #fff
}
.tooltip-info+.tooltip.top .tooltip-arrow {
    border-top-color: #57b5e3
}
.tooltip-info+.tooltip.right .tooltip-arrow {
    border-right-color: #57b5e3
}
.tooltip-info+.tooltip.left .tooltip-arrow {
    border-left-color: #57b5e3
}
.tooltip-info+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #57b5e3
}
.tooltip-sky+.tooltip>.tooltip-inner {
    border: 1px solid #11a9cc;
    background-color: #11a9cc;
    color: #fff
}
.tooltip-sky+.tooltip.top .tooltip-arrow {
    border-top-color: #11a9cc
}
.tooltip-sky+.tooltip.right .tooltip-arrow {
    border-right-color: #11a9cc
}
.tooltip-sky+.tooltip.left .tooltip-arrow {
    border-left-color: #11a9cc
}
.tooltip-sky+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #11a9cc
}
.tooltip-primary+.tooltip>.tooltip-inner {
    border: 1px solid #4374e0;
    background-color: #4374e0;
    color: #fff
}
.tooltip-primary+.tooltip.top .tooltip-arrow {
    border-top-color: #4374e0
}
.tooltip-primary+.tooltip.right .tooltip-arrow {
    border-right-color: #4374e0
}
.tooltip-primary+.tooltip.left .tooltip-arrow {
    border-left-color: #4374e0
}
.tooltip-primary+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #4374e0
}
.tooltip-azure+.tooltip>.tooltip-inner {
    border: 1px solid #2dc3e8;
    background-color: #2dc3e8;
    color: #fff
}
.tooltip-azure+.tooltip.top .tooltip-arrow {
    border-top-color: #2dc3e8
}
.tooltip-azure+.tooltip.right .tooltip-arrow {
    border-right-color: #2dc3e8
}
.tooltip-azure+.tooltip.left .tooltip-arrow {
    border-left-color: #2dc3e8
}
.tooltip-azure+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #2dc3e8
}
.tooltip-blueberry+.tooltip>.tooltip-inner {
    border: 1px solid #6f85bf;
    background-color: #6f85bf;
    color: #fff
}
.tooltip-blueberry+.tooltip.top .tooltip-arrow {
    border-top-color: #6f85bf
}
.tooltip-blueberry+.tooltip.right .tooltip-arrow {
    border-right-color: #6f85bf
}
.tooltip-blueberry+.tooltip.left .tooltip-arrow {
    border-left-color: #6f85bf
}
.tooltip-blueberry+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #6f85bf
}
.tooltip-palegreen+.tooltip>.tooltip-inner {
    border: 1px solid #a0d468;
    background-color: #a0d468;
    color: #fff
}
.tooltip-palegreen+.tooltip.top .tooltip-arrow {
    border-top-color: #a0d468
}
.tooltip-palegreen+.tooltip.right .tooltip-arrow {
    border-right-color: #a0d468
}
.tooltip-palegreen+.tooltip.left .tooltip-arrow {
    border-left-color: #a0d468
}
.tooltip-palegreen+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #a0d468
}
.tooltip-success+.tooltip>.tooltip-inner {
    border: 1px solid #53a93f;
    background-color: #53a93f;
    color: #fff
}
.tooltip-success+.tooltip.top .tooltip-arrow {
    border-top-color: #53a93f
}
.tooltip-success+.tooltip.right .tooltip-arrow {
    border-right-color: #53a93f
}
.tooltip-success+.tooltip.left .tooltip-arrow {
    border-left-color: #53a93f
}
.tooltip-success+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #53a93f
}
.tooltip-green+.tooltip>.tooltip-inner {
    border: 1px solid #8cc474;
    background-color: #8cc474;
    color: #fff
}
.tooltip-green+.tooltip.top .tooltip-arrow {
    border-top-color: #8cc474
}
.tooltip-green+.tooltip.right .tooltip-arrow {
    border-right-color: #8cc474
}
.tooltip-green+.tooltip.left .tooltip-arrow {
    border-left-color: #8cc474
}
.tooltip-green+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #8cc474
}
.tooltip-lightyellow+.tooltip>.tooltip-inner {
    border: 1px solid #f6d52e;
    background-color: #f6d52e;
    color: #fff
}
.tooltip-lightyellow+.tooltip.top .tooltip-arrow {
    border-top-color: #f6d52e
}
.tooltip-lightyellow+.tooltip.right .tooltip-arrow {
    border-right-color: #f6d52e
}
.tooltip-lightyellow+.tooltip.left .tooltip-arrow {
    border-left-color: #f6d52e
}
.tooltip-lightyellow+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f6d52e
}
.tooltip-yellow+.tooltip>.tooltip-inner {
    border: 1px solid #ffce55;
    background-color: #ffce55;
    color: #fff
}
.tooltip-yellow+.tooltip.top .tooltip-arrow {
    border-top-color: #ffce55
}
.tooltip-yellow+.tooltip.right .tooltip-arrow {
    border-right-color: #ffce55
}
.tooltip-yellow+.tooltip.left .tooltip-arrow {
    border-left-color: #ffce55
}
.tooltip-yellow+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #ffce55
}
.tooltip-warning+.tooltip>.tooltip-inner {
    border: 1px solid #f4b400;
    background-color: #f4b400;
    color: #fff
}
.tooltip-warning+.tooltip.top .tooltip-arrow {
    border-top-color: #f4b400
}
.tooltip-warning+.tooltip.right .tooltip-arrow {
    border-right-color: #f4b400
}
.tooltip-warning+.tooltip.left .tooltip-arrow {
    border-left-color: #f4b400
}
.tooltip-warning+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f4b400
}
.tooltip-gold+.tooltip>.tooltip-inner {
    border: 1px solid #f9b256;
    background-color: #f9b256;
    color: #fff
}
.tooltip-gold+.tooltip.top .tooltip-arrow {
    border-top-color: #f9b256
}
.tooltip-gold+.tooltip.right .tooltip-arrow {
    border-right-color: #f9b256
}
.tooltip-gold+.tooltip.left .tooltip-arrow {
    border-left-color: #f9b256
}
.tooltip-gold+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f9b256
}
.tooltip-orange+.tooltip>.tooltip-inner {
    border: 1px solid #fb6e52;
    background-color: #fb6e52;
    color: #fff
}
.tooltip-orange+.tooltip.top .tooltip-arrow {
    border-top-color: #fb6e52
}
.tooltip-orange+.tooltip.right .tooltip-arrow {
    border-right-color: #fb6e52
}
.tooltip-orange+.tooltip.left .tooltip-arrow {
    border-left-color: #fb6e52
}
.tooltip-orange+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #fb6e52
}
.tooltip-lightred+.tooltip>.tooltip-inner {
    border: 1px solid #e46f61;
    background-color: #e46f61;
    color: #fff
}
.tooltip-lightred+.tooltip.top .tooltip-arrow {
    border-top-color: #e46f61
}
.tooltip-lightred+.tooltip.right .tooltip-arrow {
    border-right-color: #e46f61
}
.tooltip-lightred+.tooltip.left .tooltip-arrow {
    border-left-color: #e46f61
}
.tooltip-lightred+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #e46f61
}
.tooltip-darkorange+.tooltip>.tooltip-inner {
    border: 1px solid #ed4e2a;
    background-color: #ed4e2a;
    color: #fff
}
.tooltip-darkorange+.tooltip.top .tooltip-arrow {
    border-top-color: #ed4e2a
}
.tooltip-darkorange+.tooltip.right .tooltip-arrow {
    border-right-color: #ed4e2a
}
.tooltip-darkorange+.tooltip.left .tooltip-arrow {
    border-left-color: #ed4e2a
}
.tooltip-darkorange+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #ed4e2a
}
.tooltip-red+.tooltip>.tooltip-inner {
    border: 1px solid #df5138;
    background-color: #df5138;
    color: #fff
}
.tooltip-red+.tooltip.top .tooltip-arrow {
    border-top-color: #df5138
}
.tooltip-red+.tooltip.right .tooltip-arrow {
    border-right-color: #df5138
}
.tooltip-red+.tooltip.left .tooltip-arrow {
    border-left-color: #df5138
}
.tooltip-red+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #df5138
}
.tooltip-pink+.tooltip>.tooltip-inner {
    border: 1px solid #e75b8d;
    background-color: #e75b8d;
    color: #fff
}
.tooltip-pink+.tooltip.top .tooltip-arrow {
    border-top-color: #e75b8d
}
.tooltip-pink+.tooltip.right .tooltip-arrow {
    border-right-color: #e75b8d
}
.tooltip-pink+.tooltip.left .tooltip-arrow {
    border-left-color: #e75b8d
}
.tooltip-pink+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #e75b8d
}
.tooltip-darkpink+.tooltip>.tooltip-inner {
    border: 1px solid #cc324b;
    background-color: #cc324b;
    color: #fff
}
.tooltip-darkpink+.tooltip.top .tooltip-arrow {
    border-top-color: #cc324b
}
.tooltip-darkpink+.tooltip.right .tooltip-arrow {
    border-right-color: #cc324b
}
.tooltip-darkpink+.tooltip.left .tooltip-arrow {
    border-left-color: #cc324b
}
.tooltip-darkpink+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #cc324b
}
.tooltip-danger+.tooltip>.tooltip-inner {
    border: 1px solid #d73d32;
    background-color: #d73d32;
    color: #fff
}
.tooltip-danger+.tooltip.top .tooltip-arrow {
    border-top-color: #d73d32
}
.tooltip-danger+.tooltip.right .tooltip-arrow {
    border-right-color: #d73d32
}
.tooltip-danger+.tooltip.left .tooltip-arrow {
    border-left-color: #d73d32
}
.tooltip-danger+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #d73d32
}
.tooltip-magenta+.tooltip>.tooltip-inner {
    border: 1px solid #bc5679;
    background-color: #bc5679;
    color: #fff
}
.tooltip-magenta+.tooltip.top .tooltip-arrow {
    border-top-color: #bc5679
}
.tooltip-magenta+.tooltip.right .tooltip-arrow {
    border-right-color: #bc5679
}
.tooltip-magenta+.tooltip.left .tooltip-arrow {
    border-left-color: #bc5679
}
.tooltip-magenta+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #bc5679
}
.tooltip-purple+.tooltip>.tooltip-inner {
    border: 1px solid #7e3794;
    background-color: #7e3794;
    color: #fff
}
.tooltip-purple+.tooltip.top .tooltip-arrow {
    border-top-color: #7e3794
}
.tooltip-purple+.tooltip.right .tooltip-arrow {
    border-right-color: #7e3794
}
.tooltip-purple+.tooltip.left .tooltip-arrow {
    border-left-color: #7e3794
}
.tooltip-purple+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #7e3794
}
.tooltip-maroon+.tooltip>.tooltip-inner {
    border: 1px solid #981b48;
    background-color: #981b48;
    color: #fff
}
.tooltip-maroon+.tooltip.top .tooltip-arrow {
    border-top-color: #981b48
}
.tooltip-maroon+.tooltip.right .tooltip-arrow {
    border-right-color: #981b48
}
.tooltip-maroon+.tooltip.left .tooltip-arrow {
    border-left-color: #981b48
}
.tooltip-maroon+.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #981b48
}
.toast-title {
    font-weight: bold
}
.toast-message {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}
.toast-message a,
.toast-message label {
    color: #fff
}
.toast-message a:hover {
    color: #ccc;
    text-decoration: none
}
.toast-close-button {
    position: relative;
    right: -.3em;
    top: -.5em;
    float: right;
    font-size: 18px;
    font-family: 'FontAwesome';
    font-weight: bold;
    color: #fff;
    -webkit-text-shadow: 0 1px 0 #fff;
    text-shadow: 0 1px 0 #fff;
    zoom: 1;
    filter: alpha(opacity=80);
    -webkit-opacity: .8;
    -moz-opacity: .8;
    opacity: .8
}
.toast-close-button:hover,
.toast-close-button:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    zoom: 1;
    filter: alpha(opacity=40);
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4
}
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%
}
.toast-top-left {
    top: 12px;
    left: 12px
}
.toast-top-right {
    top: 12px;
    right: 12px
}
.toast-bottom-right {
    right: 12px;
    bottom: 12px
}
.toast-bottom-left {
    bottom: 12px;
    left: 12px
}
#toast-container {
    position: fixed;
    z-index: 999999
}
#toast-container>div {
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    background-position: 15px center;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0 0 12px #999;
    -moz-box-shadow: 0 0 12px #999;
    box-shadow: 0 0 12px #999;
    color: #fff;
    zoom: 1;
    filter: alpha(opacity=80);
    -webkit-opacity: .8;
    -moz-opacity: .8;
    opacity: .8
}
#toast-container>:hover {
    -webkit-box-shadow: 0 0 12px #000;
    -moz-box-shadow: 0 0 12px #000;
    box-shadow: 0 0 12px #000;
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    cursor: pointer
}
#toast-container>.toast {
    background-image: none !important
}
#toast-container>.toast:before {
    position: fixed;
    font-family: FontAwesome;
    font-size: 24px;
    line-height: 18px;
    float: left;
    color: #fff;
    padding-right: .5em;
    margin: auto .5em auto -1.5em
}
#toast-container.toast-top-full-width>div,
#toast-container.toast-bottom-full-width>div {
    width: 96%;
    margin: auto
}
.toast {
    background-color: #030303
}
.toast-white {
    background-color: #fff !important
}
.toast-snow {
    background-color: #fbfbfb !important
}
.toast-whitesmoke {
    background-color: #f5f5f5 !important
}
.toast-seashell {
    background-color: #f3f3f3 !important
}
.toast-ivory {
    background-color: #eee !important
}
.toast-platinum {
    background-color: #e5e5e5 !important
}
.toast-lightgray {
    background-color: #d0d0d0 !important
}
.toast-gray {
    background-color: #ccc !important
}
.toast-darkgray {
    background-color: #999 !important
}
.toast-silver {
    background-color: #777 !important
}
.toast-sonic-silver {
    background-color: #737373 !important
}
.toast-storm-cloud {
    background-color: #666 !important
}
.toast-lightcarbon {
    background-color: #555 !important
}
.toast-carbon {
    background-color: #444 !important
}
.toast-slate-gray {
    background-color: #333 !important
}
.toast-darkcarbon {
    background-color: #262626 !important
}
.toast-blue {
    background-color: #5db2ff !important
}
.toast-info {
    background-color: #57b5e3 !important
}
.toast-sky {
    background-color: #11a9cc !important
}
.toast-primary {
    background-color: #4374e0 !important
}
.toast-blueberry {
    background-color: #6f85bf !important
}
.toast-palegreen {
    background-color: #a0d468 !important
}
.toast-success {
    background-color: #53a93f !important
}
.toast-green {
    background-color: #8cc474 !important
}
.toast-lightyellow {
    background-color: #f6d52e !important
}
.toast-yellow {
    background-color: #ffce55 !important
}
.toast-warning {
    background-color: #f4b400 !important
}
.toast-gold {
    background-color: #f9b256 !important
}
.toast-orange {
    background-color: #fb6e52 !important
}
.toast-lightred {
    background-color: #e46f61 !important
}
.toast-darkorange {
    background-color: #ed4e2a !important
}
.toast-red {
    background-color: #df5138 !important
}
.toast-danger {
    background-color: #d73d32 !important
}
.toast-pink {
    background-color: #e75b8d !important
}
.toast-darkpink {
    background-color: #cc324b !important
}
.toast-magenta {
    background-color: #bc5679 !important
}
.toast-purple {
    background-color: #7e3794 !important
}
.toast-maroon {
    background-color: #981b48 !important
}
@media all and (max-width: 239px) {
    #toast-container>div {
        padding: 8px 8px 8px 50px;
        width: 11em
    }
    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }
}
@media all and (min-width: 240px) and (max-width: 479px) {
    #toast-container>div {
        padding: 8px 8px 8px 50px;
        width: 18em
    }
    #toast-container .toast-close-button {
        right: -.2em;
        top: -.2em
    }
}
@media all and (min-width: 480px) and (max-width: 767px) {
    #toast-container>div {
        padding: 15px 15px 15px 50px;
        width: 25em
    }
}
.modal-blue .modal-header {
    border-bottom: 3px solid #5db2ff
}
.modal-danger .modal-header {
    border-bottom: 3px solid #d73d32
}
.modal-success .modal-header {
    border-bottom: 3px solid #53a93f
}
.modal-warning .modal-header {
    border-bottom: 3px solid #f4b400
}
.modal-silver .modal-header {
    border-bottom: 3px solid #777
}
.modal-inverse .modal-header {
    border-bottom: 3px solid #444
}
.modal-blueberry .modal-header {
    border-bottom: 3px solid #6f85bf
}
.modal-palegreen .modal-header {
    border-bottom: 3px solid #a0d468
}
.modal-orange .modal-header {
    border-bottom: 3px solid #fb6e52
}
.modal-darkorange .modal-header {
    border-bottom: 3px solid #ed4e2a
}
.modal-magenta .modal-header {
    border-bottom: 3px solid #bc5679
}
.modal-purple .modal-header {
    border-bottom: 3px solid #7e3794
}
.modal-maroon .modal-header {
    border-bottom: 3px solid #981b48
}
.modal-primary .modal-header {
    border-bottom: 3px solid #4374e0
}
.modal-content {
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .5);
    -moz-box-shadow: 0 0 40px rgba(0, 0, 0, .5);
    box-shadow: 0 0 40px rgba(0, 0, 0, .5);
    color: #000;
    background-color: #fff;
    border: rgba(0, 0, 0, 0)
}
.modal-content .close {
    font-size: 26px
}
.modal-footer {
    padding-top: 12px;
    padding-bottom: 14px;
    border-top: 0;
    background-color: #f5f5f5
}
.modal-header {
    padding: 10px 15px 10px 20px;
    background-color: #f5f5f5
}
.modal-header .close {
    font-size: 26px
}
.modal-backdrop {
    background-color: #fff
}
.modal-backdrop.in {
    zoom: 1;
    filter: alpha(opacity=75);
    -webkit-opacity: .75;
    -moz-opacity: .75;
    opacity: .75
}
.modal-message .modal-dialog {
    width: 300px
}
.modal-message .modal-body,
.modal-message .modal-footer,
.modal-message .modal-header,
.modal-message .modal-title {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0 20px;
    text-align: center !important
}
.modal-message .modal-header {
    color: #fff;
    margin-bottom: 10px;
    padding: 15px 0 8px
}
.modal-message .modal-header .fa,
.modal-message .modal-header .glyphicon,
.modal-message .modal-header .typcn,
.modal-message .modal-header .wi {
    font-size: 30px
}
.modal-message .modal-title {
    font-size: 17px;
    color: #737373;
    margin-bottom: 3px
}
.modal-message .modal-body {
    color: #737373
}
.modal-message .modal-footer {
    margin: 25px 0 20px;
    padding-bottom: 10px
}
.modal-message.modal-info .modal-header {
    color: #57b5e3;
    border-bottom: 3px solid #57b5e3
}
.modal-message.modal-danger .modal-header {
    color: #d73d32;
    border-bottom: 3px solid #e46f61
}
.modal-message.modal-warning .modal-header {
    color: #f4b400;
    border-bottom: 3px solid #ffce55
}
.modal-message.modal-success .modal-header {
    color: #53a93f;
    border-bottom: 3px solid #a0d468
}
.dropdown-menu {
    border: 1px solid #ccc !important;
    -webkit-border-radius: 2px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 2px !important;
    -moz-background-clip: padding !important;
    border-radius: 2px !important;
    background-clip: padding-box !important;
    -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    box-shadow: 0 2px 40px rgba(0, 0, 0, .4);
    -webkit-transition: opacity .218s;
    -moz-transition: opacity .218s;
    -o-transition: opacity .218s;
    transition: opacity .218s;
    margin: 1px 0 0;
    min-width: 170px
}
.dropdown-menu .dropdown-icon {
    display: inline-block;
    font-size: 13px;
    margin-right: 5px
}
.dropdown-menu>li>a {
    font-size: 13px;
    padding-left: 11px;
    padding-right: 11px;
    margin-bottom: 1px;
    margin-top: 1px
}
.dropdown-menu>li>a .dropdown-expand {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 13px
}
.dropdown-menu>li>a .badge,
.dropdown-menu>li>a .label {
    font-size: 10px !important
}
.dropdown-menu>li>a .label {
    padding: 3px 6px 5px 6px
}
.dropdown-menu>.dropdown-menu {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box
}
.dropdown-menu>.dropdown-menu>a:after {
    margin-right: -5px
}
.dropdown-menu .divider {
    background-color: #ebebeb;
    margin-bottom: -1px
}
.dropdown-menu.pull-right {
    left: auto;
    right: 0
}
.dropdown-menu.dropdown-arrow.pull-right:before {
    left: auto;
    right: 9px
}
.dropdown-menu.dropdown-arrow:before {
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px
}
.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active,
.dropdown-menu li.active a,
.dropdown-menu li.active a:hover,
.dropdown-menu .dropdown-submenu:hover>a,
.nav-tabs .dropdown-menu li>a:focus {
    background: #ebebeb;
    color: #262626
}
.dropdown-info li a:hover,
.dropdown-info li a:focus,
.dropdown-info li a:active,
.dropdown-info li.active a,
.dropdown-info li.active a:hover,
.dropdown-info .dropdown-submenu:hover>a,
.nav-tabs .dropdown-info li>a:focus {
    background: #57b5e3;
    color: #fff
}
.dropdown-primary li a:hover,
.dropdown-primary li a:focus,
.dropdown-primary li a:active,
.dropdown-primary li.active a,
.dropdown-primary li.active a:hover,
.dropdown-primary .dropdown-submenu:hover>a,
.nav-tabs .dropdown-primary li>a:focus {
    background: #4374e0;
    color: #fff
}
.dropdown-success li a:hover,
.dropdown-success li a:focus,
.dropdown-success li a:active,
.dropdown-success li.active a,
.dropdown-success li.active a:hover,
.dropdown-success .dropdown-submenu:hover>a,
.nav-tabs .dropdown-success li>a:focus {
    background: #53a93f;
    color: #fff
}
.dropdown-warning li a:hover,
.dropdown-warning li a:focus,
.dropdown-warning li a:active,
.dropdown-warning li.active a,
.dropdown-warning li.active a:hover,
.dropdown-warning .dropdown-submenu:hover>a,
.nav-tabs .dropdown-warning li>a:focus {
    background: #f4b400;
    color: #fff
}
.dropdown-danger li a:hover,
.dropdown-danger li a:focus,
.dropdown-danger li a:active,
.dropdown-danger li.active a,
.dropdown-danger li.active a:hover,
.dropdown-danger .dropdown-submenu:hover>a,
.nav-tabs .dropdown-danger li>a:focus {
    background: #d73d32;
    color: #fff
}
.dropdown-inverse li a:hover,
.dropdown-inverse li a:focus,
.dropdown-inverse li a:active,
.dropdown-inverse li.active a,
.dropdown-inverse li.active a:hover,
.dropdown-inverse .dropdown-submenu:hover>a,
.nav-tabs .dropdown-inverse li>a:focus {
    background: #262626;
    color: #fff
}
.dropdown-blue li a:hover,
.dropdown-blue li a:focus,
.dropdown-blue li a:active,
.dropdown-blue li.active a,
.dropdown-blue li.active a:hover,
.dropdown-blue .dropdown-submenu:hover>a,
.nav-tabs .dropdown-blue li>a:focus {
    background: #5db2ff;
    color: #fff
}
.dropdown-blueberry li a:hover,
.dropdown-blueberry li a:focus,
.dropdown-blueberry li a:active,
.dropdown-blueberry li.active a,
.dropdown-blueberry li.active a:hover,
.dropdown-blueberry .dropdown-submenu:hover>a,
.nav-tabs .dropdown-blueberry li>a:focus {
    background: #6f85bf;
    color: #fff
}
.dropdown-palegreen li a:hover,
.dropdown-palegreen li a:focus,
.dropdown-palegreen li a:active,
.dropdown-palegreen li.active a,
.dropdown-palegreen li.active a:hover,
.dropdown-palegreen .dropdown-submenu:hover>a,
.nav-tabs .dropdown-palegreen li>a:focus {
    background: #a0d468;
    color: #fff
}
.dropdown-orange li a:hover,
.dropdown-orange li a:focus,
.dropdown-orange li a:active,
.dropdown-orange li.active a,
.dropdown-orange li.active a:hover,
.dropdown-orange .dropdown-submenu:hover>a,
.nav-tabs .dropdown-orange li>a:focus {
    background: #fb6e52;
    color: #fff
}
.dropdown-darkorange li a:hover,
.dropdown-darkorange li a:focus,
.dropdown-darkorange li a:active,
.dropdown-darkorange li.active a,
.dropdown-darkorange li.active a:hover,
.dropdown-darkorange .dropdown-submenu:hover>a,
.nav-tabs .dropdown-darkorange li>a:focus {
    background: #ed4e2a;
    color: #fff
}
.dropdown-magenta li a:hover,
.dropdown-magenta li a:focus,
.dropdown-magenta li a:active,
.dropdown-magenta li.active a,
.dropdown-magenta li.active a:hover,
.dropdown-magenta .dropdown-submenu:hover>a,
.nav-tabs .dropdown-magenta li>a:focus {
    background: #bc5679;
    color: #fff
}
.dropdown-purple li a:hover,
.dropdown-purple li a:focus,
.dropdown-purple li a:active,
.dropdown-purple li.active a,
.dropdown-purple li.active a:hover,
.dropdown-purple .dropdown-submenu:hover>a,
.nav-tabs .dropdown-purple li>a:focus {
    background: #7e3794;
    color: #fff
}
.dropdown-maroon li a:hover,
.dropdown-maroon li a:focus,
.dropdown-maroon li a:active,
.dropdown-maroon li.active a,
.dropdown-maroon li.active a:hover,
.dropdown-maroon .dropdown-submenu:hover>a,
.nav-tabs .dropdown-maroon li>a:focus {
    background: #981b48;
    color: #fff
}
.dropdown-hover {
    position: relative
}
.dropdown-hover:hover>.dropdown-menu {
    display: block;
    left: 100%;
    top: -2px
}
.dropdown-hover.dropup:hover>.dropdown-menu {
    bottom: 0;
    top: auto
}
.pagination,
.pager li>a {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box
}
.pagination,
.pager {
    margin: 0
}
.pagination>li>a,
.pager>li>a {
    color: #444;
    background-color: #fff;
    margin: 0 -1px 0 0;
    border-color: #ccc
}
.pagination>li>a:hover,
.pager>li>a:hover {
    background-color: #ebebeb;
    border-color: #b8b8b8
}
.pagination>li>a:focus,
.pager>li>a:focus,
.pagination>li>a:focus,
.pager>li>a:focus {
    background-color: #ebebeb;
    border-color: #b8b8b8
}
.pagination>li.disabled>a,
.pager>li.disabled>a,
.pagination>li.disabled>a:hover,
.pager>li.disabled>a:hover {
    background-color: #f9f9f9;
    border-color: #d9d9d9
}
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    -webkit-border-radius: 0 2px 2px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 2px 2px 0;
    -moz-background-clip: padding;
    border-radius: 0 2px 2px 0;
    background-clip: padding-box
}
.pagination>li:first-child>a,
.pagination>li:first-child>span {
    -webkit-border-radius: 2px 0 0 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px 0 0 2px;
    -moz-background-clip: padding;
    border-radius: 2px 0 0 2px;
    background-clip: padding-box
}
.pagination>li>a,
.pager>li>a,
.pagination>li>span,
.pager>li>span {
    border-width: 1px
}
.pagination>li.active>a,
.pagination>li.active>a:hover {
    background-color: #2dc3e8;
    border-color: #2dc3e8;
    color: #fff;
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25)
}
.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 7px 16px;
    font-size: 16px
}
.table {
    background-color: #fff;
    margin-bottom: 0
}
.table tfoot>tr>th {
    font-weight: 300
}
.table thead>tr>th {
    border-bottom: 0;
    font-size: 13px;
    font-weight: 600
}
.table thead[class*="colored-"]>tr>th {
    color: #fff;
    font-weight: 400
}
.table thead.colored-blue>tr>th {
    background-color: #5db2ff
}
.table thead.colored-primary>tr>th {
    background-color: #4374e0
}
.table thead.colored-danger>tr>th {
    background-color: #d73d32
}
.table thead.colored-success>tr>th {
    background-color: #53a93f
}
.table thead.colored-warning>tr>th {
    background-color: #f4b400
}
.table thead.colored-silver>tr>th {
    background-color: #777
}
.table thead.colored-inverse>tr>th {
    background-color: #444
}
.table thead.colored-blueberry>tr>th {
    background-color: #6f85bf
}
.table thead.colored-palegreen>tr>th {
    background-color: #a0d468
}
.table thead.colored-orange>tr>th {
    background-color: #fb6e52
}
.table thead.colored-darkorange>tr>th {
    background-color: #ed4e2a
}
.table thead.colored-magenta>tr>th {
    background-color: #bc5679
}
.table thead.colored-purple>tr>th {
    background-color: #7e3794
}
.table thead.colored-maroon>tr>th {
    background-color: #981b48
}
.table thead.bordered-blue>tr>th {
    border-bottom: 3px solid #5db2ff
}
.table thead.bordered-primary>tr>th {
    border-bottom: 3px solid #4374e0
}
.table thead.bordered-danger>tr>th {
    border-bottom: 3px solid #d73d32
}
.table thead.bordered-success>tr>th {
    border-bottom: 3px solid #53a93f
}
.table thead.bordered-warning>tr>th {
    border-bottom: 3px solid #f4b400
}
.table thead.bordered-silver>tr>th {
    border-bottom: 3px solid #777
}
.table thead.bordered-inverse>tr>th {
    border-bottom: 3px solid #444
}
.table thead.bordered-blueberry>tr>th {
    border-bottom: 3px solid #6f85bf
}
.table thead.bordered-palegreen>tr>th {
    border-bottom: 3px solid #a0d468
}
.table thead.bordered-orange>tr>th {
    border-bottom: 3px solid #fb6e52
}
.table thead.bordered-darkorange>tr>th {
    border-bottom: 3px solid #ed4e2a
}
.table thead.bordered-magenta>tr>th {
    border-bottom: 3px solid #bc5679
}
.table thead.bordered-purple>tr>th {
    border-bottom: 3px solid #7e3794
}
.table thead.bordered-maroon>tr>th {
    border-bottom: 3px solid #981b48
}
.table tr.success td:first-child {
    -webkit-box-shadow: 2px 0 0 #53a93f inset;
    -moz-box-shadow: 2px 0 0 #53a93f inset;
    box-shadow: 2px 0 0 #53a93f inset
}
.table tr.danger td:first-child {
    -webkit-box-shadow: 2px 0 0 #d73d32 inset;
    -moz-box-shadow: 2px 0 0 #d73d32 inset;
    box-shadow: 2px 0 0 #d73d32 inset
}
.table tr.warning td:first-child {
    -webkit-box-shadow: 2px 0 0 #f4b400 inset;
    -moz-box-shadow: 2px 0 0 #f4b400 inset;
    box-shadow: 2px 0 0 #f4b400 inset
}
.table tr.active td:first-child {
    -webkit-box-shadow: 2px 0 0 #737373 inset;
    -moz-box-shadow: 2px 0 0 #737373 inset;
    box-shadow: 2px 0 0 #737373 inset
}
.table tr td.success {
    -webkit-box-shadow: 2px 0 0 #53a93f inset;
    -moz-box-shadow: 2px 0 0 #53a93f inset;
    box-shadow: 2px 0 0 #53a93f inset
}
.table tr td.danger {
    -webkit-box-shadow: 2px 0 0 #d73d32 inset;
    -moz-box-shadow: 2px 0 0 #d73d32 inset;
    box-shadow: 2px 0 0 #d73d32 inset
}
.table tr td.warning {
    -webkit-box-shadow: 2px 0 0 #f4b400 inset;
    -moz-box-shadow: 2px 0 0 #f4b400 inset;
    box-shadow: 2px 0 0 #f4b400 inset
}
.table tr td.active {
    -webkit-box-shadow: 2px 0 0 #737373 inset;
    -moz-box-shadow: 2px 0 0 #737373 inset;
    box-shadow: 2px 0 0 #737373 inset
}
.table-bordered thead>tr {
    background-color: #eee;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmMmYyZjIiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZhZmFmYSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
    background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
    background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
    background-image: linear-gradient(to bottom, #f2f2f2 0, #fafafa 100%)
}
.table>thead>tr>.active,
.table>tbody>tr>.active,
.table>tfoot>tr>.active,
.table>thead>.active>td,
.table>tbody>.active>td,
.table>tfoot>.active>td,
.table>thead>.active>th,
.table>tbody>.active>th,
.table>tfoot>.active>th,
.table-striped>tbody>tr.active:nth-child(odd)>td,
.table-striped>tbody>tr>.active:nth-child(odd)>th {
    background-color: #f1f1f1;
    color: #222
}
.table-hover>tbody>tr>.active:hover,
.table-hover>tbody>.active:hover>td,
.table-hover>tbody>.active:hover>th,
.table-striped>tbody>tr.active:hover:nth-child(odd)>td,
.table-striped>tbody>tr>.active:hover:nth-child(odd)>th {
    background-color: #e5e5e5 !important
}
.table>thead>tr>.success,
.table>tbody>tr>.success,
.table>tfoot>tr>.success,
.table>thead>.success>td,
.table>tbody>.success>td,
.table>tfoot>.success>td,
.table>thead>.success>th,
.table>tbody>.success>th,
.table>tfoot>.success>th {
    color: #119000
}
.table>thead>tr>.danger,
.table>tbody>tr>.danger,
.table>tfoot>tr>.danger,
.table>thead>.danger>td,
.table>tbody>.danger>td,
.table>tfoot>.danger>td,
.table>thead>.danger>th,
.table>tbody>.danger>th,
.table>tfoot>.danger>th {
    color: #cd2929
}
.table>thead>tr>.warning,
.table>tbody>tr>.warning,
.table>tfoot>tr>.warning,
.table>thead>.warning>td,
.table>tbody>.warning>td,
.table>tfoot>.warning>td,
.table>thead>.warning>th,
.table>tbody>.warning>th,
.table>tfoot>.warning>th {
    color: #fb6e52
}
.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #ddd
}
.table-scrollable>.table {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 0;
    background-color: #fff
}
.table-scrollable>.table>thead>tr>th,
.table-scrollable>.table>tbody>tr>th,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>thead>tr>td,
.table-scrollable>.table>tbody>tr>td,
.table-scrollable>.table>tfoot>tr>td {
    white-space: nowrap
}
.table-scrollable>.table-bordered {
    border: 0
}
.table-scrollable>.table-bordered>thead>tr>th:first-child,
.table-scrollable>.table-bordered>tbody>tr>th:first-child,
.table-scrollable>.table-bordered>tfoot>tr>th:first-child,
.table-scrollable>.table-bordered>thead>tr>td:first-child,
.table-scrollable>.table-bordered>tbody>tr>td:first-child,
.table-scrollable>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0
}
.table-scrollable>.table-bordered>thead>tr>th:last-child,
.table-scrollable>.table-bordered>tbody>tr>th:last-child,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child,
.table-scrollable>.table-bordered>thead>tr>td:last-child,
.table-scrollable>.table-bordered>tbody>tr>td:last-child,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0
}
.table-scrollable>.table-bordered>thead>tr>th:last-child>td,
.table-scrollable>.table-bordered>tbody>tr>th:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child>td,
.table-scrollable>.table-bordered>thead>tr>td:last-child>td,
.table-scrollable>.table-bordered>tbody>tr>td:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child>td {
    border-bottom: 0
}
.flip-scroll table {
    width: 100%
}
@media only screen and (max-width: 800px) {
    .flip-scroll .flip-content:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0
    }
    .flip-scroll html .flip-content {
        -ms-zoom: 1;
        zoom: 1
    }
    .flip-scroll *:first-child+html .flip-content {
        -ms-zoom: 1;
        zoom: 1
    }
    .flip-scroll table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative
    }
    .flip-scroll th,
    .flip-scroll td {
        margin: 0;
        vertical-align: top
    }
    .flip-scroll th:last-child,
    .flip-scroll td:last-child {
        border-bottom: 1px solid #ddd
    }
    .flip-scroll th {
        border: 0 !important;
        border-right: 1px solid #ddd !important;
        font-size: 13px !important;
        padding: 5px;
        width: auto !important;
        display: block;
        text-align: right;
        border-bottom: 0;
        border-left: 0
    }
    .flip-scroll td {
        display: block;
        min-height: 1.25em;
        text-align: left;
        border-top: 0 !important;
        border-left: 0;
        border-right: 0;
        border-bottom: 0
    }
    .flip-scroll thead {
        display: block;
        float: left
    }
    .flip-scroll thead tr {
        display: block;
        border: !important
    }
    .flip-scroll thead.bordered-blue>tr>th {
        border-right: 3px solid #5db2ff !important
    }
    .flip-scroll thead.bordered-primary>tr>th {
        border-right: 3px solid #4374e0 !important
    }
    .flip-scroll thead.bordered-danger>tr>th {
        border-right: 3px solid #d73d32 !important
    }
    .flip-scroll thead.bordered-success>tr>th {
        border-right: 3px solid #53a93f !important
    }
    .flip-scroll thead.bordered-warning>tr>th {
        border-right: 3px solid #f4b400 !important
    }
    .flip-scroll thead.bordered-silver>tr>th {
        border-right: 3px solid #777 !important
    }
    .flip-scroll thead.bordered-inverse>tr>th {
        border-right: 3px solid #444 !important
    }
    .flip-scroll thead.bordered-blueberry>tr>th {
        border-right: 3px solid #6f85bf !important
    }
    .flip-scroll thead.bordered-palegreen>tr>th {
        border-right: 3px solid #a0d468 !important
    }
    .flip-scroll thead.bordered-orange>tr>th {
        border-right: 3px solid #fb6e52 !important
    }
    .flip-scroll thead.bordered-darkorange>tr>th {
        border-right: 3px solid #ed4e2a !important
    }
    .flip-scroll thead.bordered-magenta>tr>th {
        border-right: 3px solid #bc5679 !important
    }
    .flip-scroll thead.bordered-purple>tr>th {
        border-right: 3px solid #7e3794 !important
    }
    .flip-scroll thead.bordered-maroon>tr>th {
        border-right: 3px solid #981b48 !important
    }
    .flip-scroll tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap
    }
    .flip-scroll tbody tr {
        display: inline-block;
        vertical-align: top;
        margin-left: -5px;
        border-left: 1px solid #ddd
    }
}
.table-toolbar {
    padding: 10px 0
}
.dropdown-checkboxes {
    padding: 5px
}
.dropdown-checkboxes .checker {
    display: block;
    font-weight: 300;
    color: #333;
    margin: 0 4px;
    line-height: 24px
}
.dropdown-checkboxes .checker input {
    margin-top: 5px
}
.dropdown-checkboxes .checker span {
    padding-left: 5px
}
.table input[type=checkbox]~.text,
.table input[type=radio]~.text {
    margin-right: -7px;
    margin-bottom: 0
}
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    max-width: 600px;
    list-style: none;
    line-height: 20px
}
.dd.shadowed .dd-handle,
.dd.shadowed .dd2-content {
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    box-shadow: 0 0 4px rgba(0, 0, 0, .3)
}
.dd.bordered .dd-handle,
.dd.bordered .dd2-content {
    border: 1px solid #cfcfcf
}
.dd.darker .dd-handle,
.dd.darker .dd2-content {
    background-color: #eee
}
.dd.darker .dd2-handle,
.dd.darker .dd-dragel>li>.dd2-handle {
    background-color: #e5e5e5
}
.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none
}
.dd-list>li>.dd-handle[class*="bg-"],
.dd-list>li>.dd2-content[class*="bg-"] {
    color: #fff
}
.dd-list .dd-list {
    padding-left: 30px
}
.dd-collapsed .dd-list {
    display: none
}
.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 20px
}
.dd-handle,
.dd2-content {
    display: block;
    min-height: 38px;
    margin: 5px 0;
    padding: 8px 12px;
    background: #f5f5f5;
    color: #737373;
    font-size: 13px;
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.dd-handle:hover,
.dd2-content:hover,
.dd-handle:hover,
.dd2-content:hover {
    color: #262626;
    background: #eee
}
.dd-handle[class*="btn-"],
.dd2-content[class*="btn-"] {
    color: #fff;
    border: 0;
    padding: 9px 12px
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"]:hover {
    opacity: .85;
    color: #fff
}
.dd2-handle+.dd2-content,
.dd2-handle+.dd2-content[class*="btn-"] {
    padding-left: 44px
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"] .dd2-handle[class*="btn-"]:hover+.dd2-content[class*="btn-"] {
    color: #fff
}
.dd-item>button:hover~.dd-handle[class*="btn-"],
.dd-item>button:hover~.dd2-content[class*="btn-"] {
    opacity: .85;
    color: #fff
}
.dd2-handle:hover~.dd2-content {
    color: #262626;
    background: #f5f5f5
}
.dd2-item.dd-item>button {
    margin-left: 38px
}
.dd-item>button {
    display: block;
    position: relative;
    z-index: 1;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 1px 5px 5px;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
    top: 4px;
    left: 1px;
    color: #737373
}
.dd-item>button:before {
    font-family: FontAwesome;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
    font-weight: normal;
    font-size: 14px
}
.dd-item>button[data-action="collapse"]:before {
    content: ''
}
.dd-item>button:hover {
    color: #737373
}
.dd-item .dd-colored>button,
.dd-item.dd-colored>button:hover {
    color: #f5f5f5
}
.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f5f5f5;
    border: 1px dashed #d0d0d0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.dd-empty {
    border-color: #aaa;
    border-style: solid;
    background-color: #e5e5e5
}
.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 999;
    zoom: 1;
    filter: alpha(opacity=80);
    -webkit-opacity: .8;
    -moz-opacity: .8;
    opacity: .8
}
.dd-dragel>li>.dd-handle {
    color: #000;
    background: #fbfbfb;
    border-left: 2px solid #444;
    position: relative
}
.dd-dragel>.dd-item>.dd-handle {
    margin-top: 0
}
.dd-list>li[class*="bordered-"] {
    border-width: 0;
    padding: 0
}
.dd-list>li[class*="bordered-"]>.dd-handle {
    border-left: 2px solid;
    border-left-color: inherit
}
.dd-list>li.bordered-blue>.dd-handle {
    border-left-color: #5db2ff
}
.dd-list>li.bordered-primary>.dd-handle {
    border-left-color: #4374e0
}
.dd-list>li.bordered-danger>.dd-handle {
    border-left-color: #d73d32
}
.dd-list>li.bordered-warning>.dd-handle {
    border-left-color: #f4b400
}
.dd-list>li.bordered-success>.dd-handle {
    border-left-color: #53a93f
}
.dd-list>li.bordered-silver>.dd-handle {
    border-left-color: #777
}
.dd-list>li.bordered-inverse>.dd-handle {
    border-left-color: #444
}
.dd-list>li.bordered-blueberry>.dd-handle {
    border-left-color: #6f85bf
}
.dd-list>li.bordered-palegreen>.dd-handle {
    border-left-color: #a0d468
}
.dd-list>li.bordered-orange>.dd-handle {
    border-left-color: #fb6e52
}
.dd-list>li.bordered-darkorange>.dd-handle {
    border-left-color: #ed4e2a
}
.dd-list>li.bordered-magenta>.dd-handle {
    border-left-color: #bc5679
}
.dd-list>li.bordered-purple>.dd-handle {
    border-left-color: #7e3794
}
.dd-list>li.bordered-maroon>.dd-handle {
    border-left-color: #981b48
}
.dd-list>li>.dd-handle .sticker {
    position: absolute;
    right: 0;
    top: 0
}
.dd2-handle,
.dd-dragel>li>.dd2-handle {
    left: 0;
    top: 0;
    width: 38px;
    margin: 0;
    border-width: 1px 1px 0 0;
    text-align: center;
    padding: 0 !important;
    line-height: 38px;
    height: 38px;
    background: #fff;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    z-index: 1
}
.dd2-handle .normal-icon,
.dd-dragel>li>.dd2-handle .normal-icon,
.dd2-handle .drag-icon,
.dd-dragel>li>.dd2-handle .drag-icon {
    font-size: 16px
}
.dd2-handle:hover,
.dd-dragel>li>.dd2-handle {
    background: #f3f3f3
}
.dd-item>.dd2-handle .drag-icon {
    display: none
}
.dd-dragel>.dd-item>.dd2-handle .drag-icon {
    display: inline
}
.dd-dragel>.dd-item>.dd2-handle .normal-icon {
    display: none
}
.tree {
    padding-left: 14px;
    overflow: auto;
    position: relative
}
.tree:before {
    width: 1px;
    z-index: 1;
    display: block;
    content: "";
    position: absolute;
    top: -21px;
    bottom: 16px;
    left: 0;
    border-left: 1px dotted #666
}
.tree.tree-solid-line {
    padding-left: 12px
}
.tree.tree-solid-line:before {
    border-left: 1px solid #999
}
.tree.tree-solid-line .tree-folder .tree-folder-content:before {
    border-left: 1px solid #999
}
.tree.tree-no-line {
    padding-left: 0
}
.tree.tree-no-line:before {
    display: none
}
.tree.tree-no-line .tree-folder .tree-folder-content:before {
    display: none
}
.tree .tree-folder {
    width: auto;
    min-height: 20px;
    cursor: pointer
}
.tree .tree-folder .tree-folder-header {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    min-width: 100px
}
.tree .tree-folder .tree-folder-header:hover {
    background-color: #eee
}
.tree .tree-folder .tree-folder-header .tree-folder-name {
    margin-left: 2px
}
.tree .tree-folder img {
    margin-left: 4px
}
.tree .tree-folder .tree-folder-content {
    margin-left: 23px;
    position: relative
}
.tree .tree-folder .tree-folder-content:before {
    display: inline-block;
    content: "";
    position: absolute;
    width: 1px;
    z-index: 1;
    top: -9px;
    bottom: 16px;
    left: -12px;
    border-left: 1px dotted #666
}
.tree .tree-item {
    position: relative;
    min-height: 20px;
    line-height: 20px;
    min-width: 100px;
    cursor: pointer
}
.tree .tree-item:hover {
    background-color: #eee
}
.tree .tree-item .tree-item-name {
    margin-left: 2px
}
.tree .tree-folder,
.tree .tree-item {
    position: relative
}
.tree .tree-folder:before,
.tree .tree-item:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 14px;
    left: -13px;
    width: 18px;
    height: 0;
    border-top: 1px dotted #666;
    z-index: 1
}
.tree .tree-selected {
    background-color: #eee;
    color: #262626
}
.tree .tree-selected:hover {
    background-color: #e5e5e5
}
.tree .tree-folder .tree-folder-header .tree-folder-name,
.tree .tree-item .tree-item-name {
    display: inline;
    z-index: 2
}
.tree.tree-solid-line .tree-folder:before,
.tree.tree-solid-line .tree-item:before {
    border-top: 1px solid #999
}
.tree.tree-no-line .tree-folder:before,
.tree.tree-no-line .tree-item:before {
    display: none
}
.tree .tree-item,
.tree .tree-folder {
    border: 1px solid transparent
}
.tree .tree-item,
.tree .tree-folder .tree-folder-header {
    margin: 0;
    padding: 4px 5px 6px 5px;
    color: #333;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.tree .tree-item .tree-item-name>i,
.tree .tree-folder .tree-folder-header>i {
    color: #666;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.tree .tree-item.tree-selected i,
.tree .tree-item .tree-item-name i {
    margin-left: 2px;
    width: 14px;
    display: inline-block;
    text-align: center;
    margin-right: 1px;
    color: #262626
}
.tree.tree-plus-minus .tree-folder-header .fa-folder-open:before {
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    display: inline-block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABWCAYAAADVJVbkAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAM1JREFUeNrsmFsKxCAMRRtxSe5/Be4pnYF+lMEppiZUZ44fBQvmaF5eFFXdIkd+f0opZkqtVboBlgXWDaUteCSvnc55ArcsunLLeW5JhK+As5HDuBz/F3FR40RxQb7j8+dc5FFUrSHR3XT9SgYAYJULB9kCIFa2jF6hucfICzqvbEmdRUUWIVsAAADwH83uTkOyCa/eBdYNPR4D3ouajyF6MRcPgHwYF1rFcLVOk0UaCZCfDTIAAMgWZAsAL9kydIVmDyMEGdkSK1t2AQYAa5tGM0Yy5xYAAAAASUVORK5CYII=') no-repeat;
    *background: url("img/tree-icons.png") no-repeat;
    background-position: 0 -21px;
    content: ""
}
.tree.tree-plus-minus .tree-folder-header .fa-folder:before {
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    display: inline-block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABWCAYAAADVJVbkAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAM1JREFUeNrsmFsKxCAMRRtxSe5/Be4pnYF+lMEppiZUZ44fBQvmaF5eFFXdIkd+f0opZkqtVboBlgXWDaUteCSvnc55ArcsunLLeW5JhK+As5HDuBz/F3FR40RxQb7j8+dc5FFUrSHR3XT9SgYAYJULB9kCIFa2jF6hucfICzqvbEmdRUUWIVsAAADwH83uTkOyCa/eBdYNPR4D3ouajyF6MRcPgHwYF1rFcLVOk0UaCZCfDTIAAMgWZAsAL9kydIVmDyMEGdkSK1t2AQYAa5tGM0Yy5xYAAAAASUVORK5CYII=') no-repeat;
    *background: url("img/tree-icons.png") no-repeat;
    background-position: 0 2px;
    content: ""
}
.ie .tree.tree-plus-minus .tree-folder-header .fa-folder:before,
.ie .tree.tree-plus-minus .tree-folder-header .fa-folder-open:before {
    margin-top: -5px
}
.tree.tree-plus-minus .tree-folder-name {
    margin-left: 0 !important
}
.tree .tree-actions {
    display: none;
    position: absolute;
    margin-top: 1px;
    right: 4px
}
.tree .tree-item:hover .tree-actions,
.tree .tree-folder-header:hover .tree-actions {
    display: inline-block
}
.tree .tree-actions>i {
    font-weight: 300;
    padding: 1px 3px;
    text-align: center;
    font-size: 14px;
    color: #333;
    margin-right: 6px;
    margin-top: 0;
    display: inline-block
}
.tree .tree-actions>i:hover {
    font-weight: bold
}
.tree-loading {
    color: #2dc3e8;
    margin-left: 30px
}
.wizard {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    background-color: #fff;
    position: relative;
    overflow: hidden
}
.wizard.wizard-tabbed {
    background: #fbfbfb
}
.wizard.wizard-tabbed ul li {
    padding: 0 30px;
    background-color: #fbfbfb
}
.wizard.wizard-tabbed ul li .chevron {
    display: none
}
.wizard.wizard-tabbed ul li .chevron:before {
    display: none
}
.wizard.wizard-tabbed ul li.complete {
    background-color: #fbfbfb
}
.wizard.wizard-tabbed ul li.active {
    background: #fff
}
.wizard.wizard-wired {
    padding-top: 10px;
    display: block;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #eee
}
.wizard.wizard-wired ul {
    display: table;
    width: 100%;
    position: relative
}
.wizard.wizard-wired ul li {
    display: table-cell;
    text-align: center;
    background-color: #fff;
    width: 20%;
    padding: 0;
    margin: 0;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease
}
.wizard.wizard-wired ul li .chevron {
    display: none
}
.wizard.wizard-wired ul li .chevron:before {
    display: none
}
.wizard.wizard-wired ul li.complete {
    background-color: #fff
}
.wizard.wizard-wired ul li.complete .step:before {
    font-size: 22px;
    line-height: 36px
}
.wizard.wizard-wired ul li.complete:hover {
    background-color: #fff
}
.wizard.wizard-wired ul li.active {
    background-color: #fff
}
.wizard.wizard-wired ul li .step {
    border-width: 2px;
    width: 40px;
    height: 40px;
    line-height: 34px;
    font-size: 15px;
    z-index: 2;
    background-color: #fff
}
.wizard.wizard-wired ul li .title {
    display: block;
    margin-top: 4px;
    margin-bottom: 6px;
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    z-index: 104;
    text-align: center;
    table-layout: fixed;
    -ms-word-wrap: break-word;
    word-wrap: break-word
}
.wizard.wizard-wired ul li:before {
    display: block;
    content: "";
    width: 100%;
    height: 2px !important;
    font-size: 0;
    overflow: hidden;
    background-color: #e5e5e5;
    position: relative !important;
    top: 25px;
    z-index: 1 !important
}
.wizard.wizard-wired ul li:first-child:before {
    max-width: 51%;
    left: 50%
}
.wizard.wizard-wired ul li:last-child:before {
    max-width: 50%;
    width: 50%
}
.wizard ul {
    list-style: none outside none;
    padding: 0;
    margin: 0;
    width: 4000px
}
.wizard ul.previous-disabled li.complete {
    cursor: default
}
.wizard ul.previous-disabled li.complete:hover {
    background: #f3f3f3;
    cursor: default
}
.wizard ul.previous-disabled li.complete:hover .chevron:before {
    border-left-color: #f3f3f3
}
.wizard ul li {
    float: left;
    margin: 0;
    padding: 0 20px 0 30px;
    line-height: 46px;
    position: relative;
    background: #f5f5f5;
    color: #d0d0d0;
    font-size: 16px;
    cursor: default;
    -webkit-transition: all .218s ease;
    -moz-transition: all .218s ease;
    -o-transition: all .218s ease;
    transition: all .218s ease
}
.wizard ul li .step {
    border: 2px solid #e5e5e5;
    color: #ccc;
    font-size: 13px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    margin-right: 10px
}
.wizard ul li .chevron {
    border: 24px solid transparent;
    border-left: 14px solid #d4d4d4;
    border-right: 0;
    display: block;
    position: absolute;
    right: -14px;
    top: 0;
    z-index: 1
}
.wizard ul li .chevron:before {
    border: 24px solid transparent;
    border-left: 14px solid #f5f5f5;
    border-right: 0;
    content: "";
    display: block;
    position: absolute;
    right: 1px;
    top: -24px;
    -webkit-transition: all .218s ease;
    -moz-transition: all .218s ease;
    -o-transition: all .218s ease;
    transition: all .218s ease
}
.wizard ul li.complete {
    background: #f5f5f5;
    color: #444
}
.wizard ul li.complete:before {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: -1px;
    height: 2px;
    max-height: 2px;
    overflow: hidden;
    background-color: #a0d468;
    z-index: 10000
}
.wizard ul li.complete:hover {
    background: #eee;
    cursor: pointer
}
.wizard ul li.complete:hover .chevron:before {
    border-left: 14px solid #eee
}
.wizard ul li.complete .chevron:before {
    border-left: 14px solid #f5f5f5
}
.wizard ul li.complete .step {
    color: #a0d468;
    border-color: #a0d468
}
.wizard ul li.complete .step:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 20px;
    text-align: center;
    border-radius: 100%;
    content: "";
    background-color: #fff;
    z-index: 3;
    font-family: FontAwesome;
    font-size: 12px;
    color: #a0d468
}
.wizard ul li.active {
    background: #fff;
    color: #262626
}
.wizard ul li.active .step {
    border-color: #2dc3e8;
    color: #2dc3e8
}
.wizard ul li.active:before {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: -1px;
    height: 2px;
    max-height: 2px;
    overflow: hidden;
    background-color: #2dc3e8;
    z-index: 10000
}
.wizard ul li.active .chevron:before {
    border-left: 14px solid #fff
}
.wizard ul li .badge {
    margin-right: 8px
}
.wizard ul li:first-child {
    -webkit-border-radius: 2px 0 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px 0 0 0;
    -moz-background-clip: padding;
    border-radius: 2px 0 0 0;
    background-clip: padding-box;
    padding-left: 20px
}
.actions {
    z-index: 1000;
    position: absolute;
    right: 0;
    line-height: 42px;
    float: right;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 4px;
    vertical-align: middle;
    border-left: 1px solid #d4d4d4
}
.actions a {
    line-height: 45px;
    font-size: 12px;
    margin-right: 8px
}
.actions .btn-prev i {
    margin-right: 5px
}
.actions .btn-next i {
    margin-left: 5px
}
.actions.actions-footer {
    margin-top: -10px;
    position: relative;
    float: none;
    text-align: right;
    border: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    box-shadow: 0 0 4px rgba(0, 0, 0, .3)
}
.step-content {
    border-top: 0;
    -webkit-border-radius: 0 0 2px 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 2px 2px;
    -moz-background-clip: padding;
    border-radius: 0 0 2px 2px;
    background-clip: padding-box;
    padding: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    -moz-box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    box-shadow: 1px 0 10px 1px rgba(0, 0, 0, .3);
    background-color: #fbfbfb
}
.step-content .step-pane {
    display: none
}
.step-content .active {
    display: block
}
.step-content .active .btn-group .active {
    display: inline-block
}
.chart {
    height: 220px;
    margin: 5px
}
.chart-sm {
    height: 100px
}
.chart-lg {
    height: 250px
}
.chart-xl {
    height: 350px
}
#flotTip {
    padding: 3px 5px;
    background-color: #f5f5f5;
    z-index: 9999;
    color: #262626;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    box-shadow: 0 1px 8px rgba(0, 0, 0, .3);
    font-size: 13px;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    border: 1px solid #ccc
}
#flotTip span {
    color: #5db2ff;
    font-weight: 700
}
.flot-donut-caption {
    position: absolute;
    -lh-property: 0;
    top: -webkit-calc(50% - 20px);
    top: -moz-calc(50% - 20px);
    top: calc(50% - 20px);
    -lh-property: 0;
    left: -webkit-calc(50% - 25px);
    left: -moz-calc(50% - 25px);
    left: calc(50% - 25px)
}
flot-donut-caption .legendLabel span {
    display: block;
    margin: 0 5px
}
.legendColorBox {
    padding-left: 10px;
    vertical-align: top;
    padding-top: 5px
}
.legendColorBox div>div {
    width: 4px;
    height: 4px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.morris-hover.morris-default-style {
    -webkit-border-radius: 5px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 5px;
    -moz-background-clip: padding;
    border-radius: 5px;
    background-clip: padding-box;
    padding: 3px;
    color: #444;
    background: rgba(255, 255, 255, .8);
    border: solid 2px rgba(230, 230, 230, .8);
    font-family: sans-serif;
    font-size: 12px;
    text-align: center
}
.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: 700;
    margin: .25em 0
}
.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: .1em 0
}
.morris-hover {
    position: absolute;
    z-index: 1001
}
.has-legend {
    margin-top: 30px !important
}
.has-legend-unique {
    margin-top: 19px !important
}
.easyPieChart {
    position: relative;
    text-align: center
}
.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0
}
.easy-pie-chart {
    display: inline-block
}
.easy-pie-chart .percent {
    color: #444;
    font-size: 12px;
    font-weight: 700
}
.easy-pie-title {
    display: inline-block;
    margin: 10px 6px 0;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    width: 100px;
    height: 19px;
    white-space: nowrap;
    overflow: hidden
}
.jqstooltip {
    padding: 7px !important;
    border-radius: 3px !important;
    border: 0 !important;
    background-color: #000 !important;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.jqstooltip .jqsfield {
    font-family: 'Tahoma';
    font-size: 11px
}
.form-bordered .form-group {
    border-top: 1px solid #e5e5e5;
    padding-top: 15px;
    margin-left: -12px;
    margin-right: -12px
}
.form-bordered .form-group:first-child {
    border-top: 0;
    padding-top: 0
}
.form-bordered .form-group:last-child {
    margin-bottom: 0
}
.form-title {
    display: block;
    padding: 8px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, .2);
    font-size: 15px;
    font-weight: 300;
    color: #262626;
    margin-bottom: 15px
}
label {
    font-weight: normal;
    font-size: 13px;
    vertical-align: middle
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    -webkit-border-radius: 0 !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 0 !important;
    -moz-background-clip: padding !important;
    border-radius: 0 !important;
    background-clip: padding-box !important;
    color: #858585;
    background-color: #fbfbfb;
    border: 1px solid #d5d5d5;
    font-family: inherit;
    -webkit-transition: -webkit-box-shadow .45s, border-color .45s ease-in-out;
    -moz-transition: -moz-box-shadow .45s, border-color .45s ease-in-out;
    -o-transition: box-shadow .45s, border-color .45s ease-in-out;
    transition: box-shadow .45s, border-color .45s ease-in-out;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
textarea:hover,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="date"]:hover,
input[type="month"]:hover,
input[type="time"]:hover,
input[type="week"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="color"]:hover {
    border-color: rgba(0, 0, 0, .2)
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    color: #262626;
    background-color: #fff;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-color: rgba(0, 0, 0, .25);
    outline: 0
}
.form-control,
select {
    font-size: 13px;
    color: #858585;
    background-color: #fbfbfb;
    border: 1px solid #d5d5d5;
    padding: 6px 12px
}
.form-control:focus,
select:focus {
    color: #262626;
    background-color: #fff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    border-color: rgba(0, 0, 0, .25)
}
textarea.form-control {
    padding: 5px 9px;
    -webkit-transition: height .2s;
    -moz-transition: height .2s;
    -o-transition: height .2s;
    transition: height .2s
}
.input-xs {
    height: 24px;
    padding: 2px 10px;
    font-size: 11px;
    line-height: 1.5
}
.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5
}
.input-lg {
    font-weight: 300;
    font-size: 18px;
    height: 46px;
    padding: 10px 16px;
    line-height: 1.33
}
.input-xl {
    height: 52px;
    padding: 12px 18px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: #fbfbfb;
    border-color: #e5e5e5
}
.form-control[disabled]:hover,
.form-control[readonly]:hover,
fieldset[disabled] .form-control:hover {
    border-color: #eee;
    background-color: #fff
}
.form-group.has-success input,
.input-group.has-success input,
.form-group.has-success select,
.input-group.has-success select,
.form-group.has-success textarea,
.input-group.has-success textarea {
    border-color: #b0dd9c;
    color: #a0d468;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f3f7f1
}
.form-group.has-success input:focus,
.input-group.has-success input:focus,
.form-group.has-success select:focus,
.input-group.has-success select:focus,
.form-group.has-success textarea:focus,
.input-group.has-success textarea:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #8cc474;
    border-color: #8cc474
}
.form-group.has-success .form-control-feedback:before,
.input-group.has-success .form-control-feedback:before {
    color: #8cc474 !important;
    color: inherit
}
.form-group.has-success .control-label,
.input-group.has-success .control-label,
.form-group.has-success .help-block,
.input-group.has-success .help-block,
.form-group.has-success .help-inline,
.input-group.has-success .help-inline {
    color: #8cc474
}
.form-group.has-error input,
.input-group.has-error input,
.form-group.has-error .select,
.input-group.has-error .select,
.form-group.has-error textarea,
.input-group.has-error textarea {
    border-color: #e7bebe;
    color: #e46f61;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #fffafa
}
.form-group.has-error input:focus,
.input-group.has-error input:focus,
.form-group.has-error .select:focus,
.input-group.has-error .select:focus,
.form-group.has-error textarea:focus,
.input-group.has-error textarea:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #d73d32;
    border-color: #e46f61
}
.form-group.has-error .form-control-feedback:before,
.input-group.has-error .form-control-feedback:before {
    color: #e46f61 !important;
    color: inherit
}
.form-group.has-error .control-label,
.input-group.has-error .control-label,
.form-group.has-error .help-block,
.input-group.has-error .help-block,
.form-group.has-error .help-inline,
.input-group.has-error .help-inline {
    color: #e46f61
}
.form-group.has-warning input,
.input-group.has-warning input,
.form-group.has-warning .select,
.input-group.has-warning .select,
.form-group.has-warning textarea,
.input-group.has-warning textarea {
    border-color: #fecf49;
    color: #f4b400;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #fefbf1
}
.form-group.has-warning input:focus,
.input-group.has-warning input:focus,
.form-group.has-warning .select:focus,
.input-group.has-warning .select:focus,
.form-group.has-warning textarea:focus,
.input-group.has-warning textarea:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #f4b400;
    border-color: #f4b400
}
.form-group.has-warning .form-control-feedback:before,
.input-group.has-warning .form-control-feedback:before {
    color: #f4b400 !important;
    color: inherit
}
.form-group.has-warning .control-label,
.input-group.has-warning .control-label,
.form-group.has-warning .help-block,
.input-group.has-warning .help-block,
.form-group.has-warning .help-inline,
.input-group.has-warning .help-inline {
    color: #f4b400
}
.form-group.has-info input,
.input-group.has-info input,
.form-group.has-info .select,
.input-group.has-info .select,
.form-group.has-info textarea,
.input-group.has-info textarea {
    border-color: #a4d3fe;
    color: #57b5e3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #f9fcff
}
.form-group.has-info input:focus,
.input-group.has-info input:focus,
.form-group.has-info .select:focus,
.input-group.has-info .select:focus,
.form-group.has-info textarea:focus,
.input-group.has-info textarea:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #57b5e3;
    border-color: #57b5e3
}
.form-group.has-info .form-control-feedback:before,
.input-group.has-info .form-control-feedback:before {
    color: #57b5e3 !important;
    color: inherit
}
.form-group.has-info .control-label,
.input-group.has-info .control-label,
.form-group.has-info .help-block,
.input-group.has-info .help-block,
.form-group.has-info .help-inline,
.input-group.has-info .help-inline {
    color: #57b5e3
}
span.input-icon {
    display: inline-block
}
.input-icon {
    position: relative;
    width: 100%
}
.input-icon.inverted>[class*="fa-"],
.input-icon.inverted>[class*="glyphicon-"] {
    color: #fff;
    background-color: #262626
}
.input-icon>input {
    padding-left: 36px;
    padding-right: 6px
}
.input-icon>input:focus+[class*="fa-"],
.input-icon>input:focus+[class*="glyphicon-"] {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.input-icon>input:focus+.circular {
    -webkit-box-shadow: 0 0 0 .1em rgba(0, 0, 0, .15) inset;
    -moz-box-shadow: 0 0 0 .1em rgba(0, 0, 0, .15) inset;
    box-shadow: 0 0 0 .1em rgba(0, 0, 0, .15) inset
}
.input-icon>input.input-xs {
    padding-left: 26px
}
.input-icon>input.input-xs+[class*="fa-"],
.input-icon>input.input-xs+[class*="glyphicon-"] {
    width: 24px;
    font-size: 10px;
    padding-top: 0;
    line-height: 24px
}
.input-icon>input.input-xs+.circular {
    width: 14px;
    height: 14px;
    font-size: 10px;
    line-height: 14px
}
.input-icon>input.input-sm {
    padding-left: 32px
}
.input-icon>input.input-sm+[class*="fa-"],
.input-icon>input.input-sm+[class*="glyphicon-"] {
    width: 30px;
    font-size: 12px;
    padding-top: 0;
    line-height: 30px
}
.input-icon>input.input-sm+.circular {
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 20px
}
.input-icon>input.input-lg {
    padding-left: 48px
}
.input-icon>input.input-lg+[class*="fa-"],
.input-icon>input.input-lg+[class*="glyphicon-"] {
    width: 46px;
    font-size: 18px;
    padding-top: 5px;
    line-height: 34px
}
.input-icon>input.input-lg+.circular {
    width: 36px;
    height: 36px;
    font-size: 18px;
    line-height: 26px
}
.input-icon>input.input-xl {
    padding-left: 54px
}
.input-icon>input.input-xl+[class*="fa-"],
.input-icon>input.input-xl+[class*="glyphicon-"] {
    width: 52px;
    font-size: 20px;
    padding-top: 8px;
    line-height: 34px
}
.input-icon>input.input-xl+.circular {
    width: 42px;
    height: 42px;
    font-size: 20px;
    line-height: 26px
}
.input-icon>[class*="fa-"],
.input-icon>[class*="glyphicon-"] {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 34px;
    height: 100%;
    line-height: 28px;
    display: inline-block;
    color: #555;
    font-size: 14px;
    text-align: center;
    padding-top: 3px;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7
}
.input-icon>[class*="fa-"].circular,
.input-icon>[class*="glyphicon-"].circular {
    border-radius: 500em !important;
    box-shadow: 0 0 0 .1em rgba(0, 0, 0, .1) inset;
    -webkit-transition: -webkit-box-shadow .3s ease;
    -moz-transition: -moz-box-shadow .3s ease;
    -o-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    padding: 0;
    top: 5px;
    bottom: 5px;
    left: 7px;
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 24px
}
.input-icon.icon-right>input {
    padding-left: 6px;
    padding-right: 36px
}
.input-icon.icon-right>input.input-xs {
    padding-right: 26px
}
.input-icon.icon-right>input.input-sm {
    padding-right: 32px
}
.input-icon.icon-right>input.input-lg {
    padding-right: 48px
}
.input-icon.icon-right>input.input-xl {
    padding-right: 54px
}
.input-icon.icon-right>[class*="fa-"],
.input-icon.icon-right>[class*="glyphicon-"] {
    left: auto;
    right: 0
}
.input-icon.icon-right .circular {
    left: auto;
    right: 5px
}
.input-icon~.help-inline {
    padding-left: 8px
}
.input-group .input-group-addon {
    border-radius: 0 !important;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlZWVlZWUiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZiZmJmYiIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(top, #eee 0, #fbfbfb 100%);
    background-image: -moz-linear-gradient(top, #eee 0, #fbfbfb 100%);
    background-image: -o-linear-gradient(top, #eee 0, #fbfbfb 100%);
    background-image: linear-gradient(to bottom, #eee 0, #fbfbfb 100%)
}
.input-group .input-group-addon[class*="bg-"] {
    background-image: none;
    color: #fff
}
.input-group-xl>.form-control,
.input-group-xl>.input-group-addon,
.input-group-xl>.input-group-btn>.btn {
    height: 52px;
    padding: 10px 18px;
    font-size: 20px;
    line-height: 1.4
}
.input-group-xs>.form-control,
.input-group-xs>.input-group-addon,
.input-group-xs>.input-group-btn>.btn {
    height: 24px;
    padding: 2px 6px 2px 7px;
    font-size: 11px;
    line-height: 1.4
}
.has-success .input-group-addon {
    border-color: #b0dd9c !important;
    color: #8cc474;
    background-image: none
}
.has-error .input-group-addon {
    border-color: #e7bebe;
    color: #e46f61;
    background-image: none
}
.has-warning .input-group-addon {
    border-color: #fecf49;
    color: #f4b400;
    background-image: none
}
.has-info .input-group-addon {
    border-color: #a4d3fe;
    color: #57b5e3;
    background-color: #e4f0fc;
    background-image: none
}
.input-group-btn .btn-label {
    padding: 6px 10px !important
}
.input-group-btn>.btn {
    line-height: 20px;
    border-radius: 0 !important
}
.input-group-btn>.btn.dropdown-toggle>[class*="fa-"] {
    margin-right: 0;
    font-size: 14px
}
.input-group>.btn.btn-sm {
    line-height: 22px
}
.input-group>.btn+.btn {
    margin-left: 1px
}
.input-group>.btn-group>.btn {
    line-height: 23px
}
.input-group>.btn-group>.btn.btn-sm {
    line-height: 26px
}
.input-group>.btn>.caret,
.input-group>.btn-group>.btn>.caret,
.input-group>.btn.btn-sm>.caret,
.input-group>.btn-group>.btn.btn-sm>.caret {
    margin-top: 10px
}
.btn-file {
    position: relative;
    overflow: hidden
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block
}
input[type=checkbox],
input[type=radio] {
    opacity: 0;
    position: absolute;
    left: -9999px;
    z-index: 12;
    width: 18px;
    height: 18px;
    cursor: pointer
}
input[type=checkbox].inverted:checked~.text:before,
input[type=radio].inverted:checked~.text:before {
    background-color: #333;
    border-color: #333;
    color: #fff
}
input[type=checkbox].colored-white~.text,
input[type=radio].colored-white~.text {
    color: #fff
}
input[type=checkbox].colored-white~.text:before,
input[type=radio].colored-white~.text:before {
    border-color: #fafafa
}
input[type=checkbox].colored-white:checked~.text,
input[type=radio].colored-white:checked~.text {
    color: #fff
}
input[type=checkbox].colored-white:checked~.text:before,
input[type=radio].colored-white:checked~.text:before {
    border-color: #333;
    color: #333
}
input[type=checkbox].colored-blue:checked~.text,
input[type=radio].colored-blue:checked~.text {
    color: #5db2ff
}
input[type=checkbox].colored-blue:checked~.text:before,
input[type=radio].colored-blue:checked~.text:before {
    border-color: #5db2ff;
    color: #5db2ff
}
input[type=checkbox].colored-primary:checked~.text,
input[type=radio].colored-primary:checked~.text {
    color: #4374e0
}
input[type=checkbox].colored-primary:checked~.text:before,
input[type=radio].colored-primary:checked~.text:before {
    border-color: #4374e0;
    color: #4374e0
}
input[type=checkbox].colored-danger:checked~.text,
input[type=radio].colored-danger:checked~.text {
    color: #d73d32
}
input[type=checkbox].colored-danger:checked~.text:before,
input[type=radio].colored-danger:checked~.text:before {
    border-color: #d73d32;
    color: #d73d32
}
input[type=checkbox].colored-success:checked~.text,
input[type=radio].colored-success:checked~.text {
    color: #53a93f
}
input[type=checkbox].colored-success:checked~.text:before,
input[type=radio].colored-success:checked~.text:before {
    border-color: #53a93f;
    color: #53a93f
}
input[type=checkbox].colored-warning:checked~.text,
input[type=radio].colored-warning:checked~.text {
    color: #f4b400
}
input[type=checkbox].colored-warning:checked~.text:before,
input[type=radio].colored-warning:checked~.text:before {
    border-color: #f4b400;
    color: #f4b400
}
input[type=checkbox].colored-silver:checked~.text,
input[type=radio].colored-silver:checked~.text {
    color: #777
}
input[type=checkbox].colored-silver:checked~.text:before,
input[type=radio].colored-silver:checked~.text:before {
    border-color: #777;
    color: #777
}
input[type=checkbox].colored-blueberry:checked~.text,
input[type=radio].colored-blueberry:checked~.text {
    color: #6f85bf
}
input[type=checkbox].colored-blueberry:checked~.text:before,
input[type=radio].colored-blueberry:checked~.text:before {
    border-color: #6f85bf;
    color: #6f85bf
}
input[type=checkbox].colored-palegreen:checked~.text,
input[type=radio].colored-palegreen:checked~.text {
    color: #a0d468
}
input[type=checkbox].colored-palegreen:checked~.text:before,
input[type=radio].colored-palegreen:checked~.text:before {
    border-color: #a0d468;
    color: #a0d468
}
input[type=checkbox].colored-orange:checked~.text,
input[type=radio].colored-orange:checked~.text {
    color: #fb6e52
}
input[type=checkbox].colored-orange:checked~.text:before,
input[type=radio].colored-orange:checked~.text:before {
    border-color: #fb6e52;
    color: #fb6e52
}
input[type=checkbox].colored-darkorange:checked~.text,
input[type=radio].colored-darkorange:checked~.text {
    color: #ed4e2a
}
input[type=checkbox].colored-darkorange:checked~.text:before,
input[type=radio].colored-darkorange:checked~.text:before {
    border-color: #ed4e2a;
    color: #ed4e2a
}
input[type=checkbox].colored-magenta:checked~.text,
input[type=radio].colored-magenta:checked~.text {
    color: #bc5679
}
input[type=checkbox].colored-magenta:checked~.text:before,
input[type=radio].colored-magenta:checked~.text:before {
    border-color: #bc5679;
    color: #bc5679
}
input[type=checkbox].colored-purple:checked~.text,
input[type=radio].colored-purple:checked~.text {
    color: #7e3794
}
input[type=checkbox].colored-purple:checked~.text:before,
input[type=radio].colored-purple:checked~.text:before {
    border-color: #7e3794;
    color: #7e3794
}
input[type=checkbox].colored-maroon:checked~.text,
input[type=radio].colored-maroon:checked~.text {
    color: #981b48
}
input[type=checkbox].colored-maroon:checked~.text:before,
input[type=radio].colored-maroon:checked~.text:before {
    border-color: #981b48;
    color: #981b48
}
input[type=checkbox]:checked,
input[type=radio]:checked,
input[type=checkbox]:focus,
input[type=radio]:focus {
    outline: none !important
}
input[type=checkbox]:checked~.text:before,
input[type=radio]:checked~.text:before {
    display: inline-block;
    content: '';
    background-color: #f5f8fc;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05), inset 15px 10px -12px rgba(255, 255, 255, .1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05), inset 15px 10px -12px rgba(255, 255, 255, .1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05), inset 15px 10px -12px rgba(255, 255, 255, .1);
    border-color: #333
}
input[type=checkbox]:hover~.text:before,
input[type=radio]:hover~.text:before {
    border-color: #737373
}
input[type=checkbox]:active~.text:before,
input[type=radio]:active~.text:before {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1)
}
input[type=checkbox]:disabled~.text,
input[type=radio]:disabled~.text,
input[type=checkbox][disabled]~.text,
input[type=radio][disabled]~.text,
input[type=checkbox].disabled~.text,
input[type=radio].disabled~.text {
    color: rgba(0, 0, 0, .4)
}
input[type=checkbox]:disabled~.text:before,
input[type=radio]:disabled~.text:before,
input[type=checkbox][disabled]~.text:before,
input[type=radio][disabled]~.text:before,
input[type=checkbox].disabled~.text:before,
input[type=radio].disabled~.text:before {
    background-color: #f5f5f5 !important;
    border-color: #e5e5e5 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    color: #e5e5e5
}
input[type=checkbox]~.text,
input[type=radio]~.text {
    position: relative;
    z-index: 11;
    display: inline-block;
    margin: 0;
    line-height: 20px;
    min-height: 18px;
    min-width: 18px;
    font-weight: normal
}
input[type=checkbox]~.text:before,
input[type=radio]~.text:before {
    font-family: fontAwesome;
    font-weight: bold;
    font-size: 13px;
    color: #333;
    content: " ";
    background-color: #fafafa;
    border: 1px solid #c8c8c8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    border-radius: 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 18px;
    line-height: 16px;
    min-width: 18px;
    margin-right: 5px;
    margin-bottom: 2px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}
input[type=checkbox]~.text:hover:before,
input[type=radio]~.text:hover:before {
    border-color: #737373
}
input[type=checkbox]~.text:active:before,
input[type=radio]~.text:active:before {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 1px 3px rgba(0, 0, 0, .1)
}
input[type=radio]~.text:before {
    border-radius: 100%;
    font-size: 10px;
    font-family: FontAwesome;
    line-height: 17px;
    height: 19px;
    min-width: 19px
}
input[type=radio]:checked~.text:before {
    content: ""
}
.radio label,
.checkbox label {
    padding-left: 0
}
input[type=checkbox].checkbox-slider {
    width: 55px;
    height: 25px;
    left: 0
}
input[type=checkbox].checkbox-slider.slider-icon:checked~.text:after {
    font-family: FontAwesome;
    font-size: 15px;
    line-height: 21px;
    content: "";
    border-width: 1px;
    padding-left: 3px;
    background-color: #fff;
    color: #444
}
input[type=checkbox].checkbox-slider.slider-icon~.text:after {
    font-family: FontAwesome;
    font-size: 14px;
    line-height: 20px;
    content: "";
    border-width: 1px;
    padding-left: 4px;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.toggle:checked~.text:after {
    background-color: #fff;
    color: #444
}
input[type=checkbox].checkbox-slider.toggle~.text:after {
    border: 0;
    background-color: #444;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
input[type=checkbox].checkbox-slider.yesno~.text:before {
    font-family: 'Tahoma';
    content: "YES            NO"
}
input[type=checkbox].checkbox-slider~.text {
    margin: 0 4px;
    min-height: 24px
}
input[type=checkbox].checkbox-slider~.text:before {
    font-family: 'Tahoma';
    content: "ON           OFF";
    background-color: #fff;
    color: #999;
    font-weight: 300;
    font-size: 10px;
    line-height: 18px;
    height: 20px;
    overflow: hidden;
    border-radius: 50rem;
    border: 1px solid #ccc;
    text-align: left;
    float: left;
    padding: 0;
    width: 52px;
    text-indent: -21px;
    margin-right: 0;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}
input[type=checkbox].checkbox-slider~.text:after {
    font-family: 'Tahoma';
    content: '';
    font-size: 10px;
    font-weight: 300;
    background-color: #fff;
    border: 5px solid #444;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    line-height: 20px;
    position: absolute;
    top: -2px;
    left: -3px;
    padding: 0;
    -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .3);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}
input[type=checkbox].checkbox-slider:checked~.text:before {
    text-indent: 5px;
    background-color: #444;
    border-color: #444;
    color: #fff
}
input[type=checkbox].checkbox-slider:checked~.text:after {
    left: 34px;
    background-color: #444;
    border: 5px solid #fff
}
input[type=checkbox].checkbox-slider.colored-blue~.text:after {
    border-color: #5db2ff
}
input[type=checkbox].checkbox-slider.colored-blue:checked~.text:before {
    background-color: #5db2ff;
    border-color: #5db2ff
}
input[type=checkbox].checkbox-slider.colored-blue:checked~.text:after {
    background-color: #5db2ff;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-blue.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #5db2ff
}
input[type=checkbox].checkbox-slider.colored-blue.slider-icon~.text:after {
    border-color: #fff;
    color: #5db2ff
}
input[type=checkbox].checkbox-slider.colored-blue.toggle:checked~.text:after {
    color: #5db2ff;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-blue.toggle~.text:after {
    background-color: #5db2ff
}
input[type=checkbox].checkbox-slider.colored-primary~.text:after {
    border-color: #4374e0
}
input[type=checkbox].checkbox-slider.colored-primary:checked~.text:before {
    background-color: #4374e0;
    border-color: #4374e0
}
input[type=checkbox].checkbox-slider.colored-primary:checked~.text:after {
    background-color: #4374e0;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-primary.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #4374e0
}
input[type=checkbox].checkbox-slider.colored-primary.slider-icon~.text:after {
    border-color: #fff;
    color: #4374e0
}
input[type=checkbox].checkbox-slider.colored-primary.toggle:checked~.text:after {
    color: #4374e0;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-primary.toggle~.text:after {
    background-color: #4374e0
}
input[type=checkbox].checkbox-slider.colored-danger~.text:after {
    border-color: #d73d32
}
input[type=checkbox].checkbox-slider.colored-danger:checked~.text:before {
    background-color: #d73d32;
    border-color: #d73d32
}
input[type=checkbox].checkbox-slider.colored-danger:checked~.text:after {
    background-color: #d73d32;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-danger.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #d73d32
}
input[type=checkbox].checkbox-slider.colored-danger.slider-icon~.text:after {
    border-color: #fff;
    color: #d73d32
}
input[type=checkbox].checkbox-slider.colored-danger.toggle:checked~.text:after {
    color: #d73d32;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-danger.toggle~.text:after {
    background-color: #d73d32
}
input[type=checkbox].checkbox-slider.colored-success~.text:after {
    border-color: #53a93f
}
input[type=checkbox].checkbox-slider.colored-success:checked~.text:before {
    background-color: #53a93f;
    border-color: #53a93f
}
input[type=checkbox].checkbox-slider.colored-success:checked~.text:after {
    background-color: #53a93f;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-success.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #53a93f
}
input[type=checkbox].checkbox-slider.colored-success.slider-icon~.text:after {
    border-color: #fff;
    color: #53a93f
}
input[type=checkbox].checkbox-slider.colored-success.toggle:checked~.text:after {
    color: #53a93f;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-success.toggle~.text:after {
    background-color: #53a93f
}
input[type=checkbox].checkbox-slider.colored-warning~.text:after {
    border-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-warning:checked~.text:before {
    background-color: #f4b400;
    border-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-warning:checked~.text:after {
    background-color: #f4b400;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-warning.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-warning.slider-icon~.text:after {
    border-color: #fff;
    color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-warning.toggle:checked~.text:after {
    color: #f4b400;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-warning.toggle~.text:after {
    background-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-silver~.text:after {
    border-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-silver:checked~.text:before {
    background-color: #f4b400;
    border-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-silver:checked~.text:after {
    background-color: #f4b400;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-silver.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-silver.slider-icon~.text:after {
    border-color: #fff;
    color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-silver.toggle:checked~.text:after {
    color: #f4b400;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-silver.toggle~.text:after {
    background-color: #f4b400
}
input[type=checkbox].checkbox-slider.colored-blueberry~.text:after {
    border-color: #6f85bf
}
input[type=checkbox].checkbox-slider.colored-blueberry:checked~.text:before {
    background-color: #6f85bf;
    border-color: #6f85bf
}
input[type=checkbox].checkbox-slider.colored-blueberry:checked~.text:after {
    background-color: #6f85bf;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-blueberry.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #6f85bf
}
input[type=checkbox].checkbox-slider.colored-blueberry.slider-icon~.text:after {
    border-color: #fff;
    color: #6f85bf
}
input[type=checkbox].checkbox-slider.colored-blueberry.toggle:checked~.text:after {
    color: #6f85bf;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-blueberry.toggle~.text:after {
    background-color: #6f85bf
}
input[type=checkbox].checkbox-slider.colored-palegreen~.text:after {
    border-color: #a0d468
}
input[type=checkbox].checkbox-slider.colored-palegreen:checked~.text:before {
    background-color: #a0d468;
    border-color: #a0d468
}
input[type=checkbox].checkbox-slider.colored-palegreen:checked~.text:after {
    background-color: #a0d468;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-palegreen.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #a0d468
}
input[type=checkbox].checkbox-slider.colored-palegreen.slider-icon~.text:after {
    border-color: #fff;
    color: #a0d468
}
input[type=checkbox].checkbox-slider.colored-palegreen.toggle:checked~.text:after {
    color: #a0d468;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-palegreen.toggle~.text:after {
    background-color: #a0d468
}
input[type=checkbox].checkbox-slider.colored-orange~.text:after {
    border-color: #fb6e52
}
input[type=checkbox].checkbox-slider.colored-orange:checked~.text:before {
    background-color: #fb6e52;
    border-color: #fb6e52
}
input[type=checkbox].checkbox-slider.colored-orange:checked~.text:after {
    background-color: #fb6e52;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-orange.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #fb6e52
}
input[type=checkbox].checkbox-slider.colored-orange.slider-icon~.text:after {
    border-color: #fff;
    color: #fb6e52
}
input[type=checkbox].checkbox-slider.colored-orange.toggle:checked~.text:after {
    color: #fb6e52;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-orange.toggle~.text:after {
    background-color: #fb6e52
}
input[type=checkbox].checkbox-slider.colored-darkorange~.text:after {
    border-color: #ed4e2a
}
input[type=checkbox].checkbox-slider.colored-darkorange:checked~.text:before {
    background-color: #ed4e2a;
    border-color: #ed4e2a
}
input[type=checkbox].checkbox-slider.colored-darkorange:checked~.text:after {
    background-color: #ed4e2a;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-darkorange.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #ed4e2a
}
input[type=checkbox].checkbox-slider.colored-darkorange.slider-icon~.text:after {
    border-color: #fff;
    color: #ed4e2a
}
input[type=checkbox].checkbox-slider.colored-darkorange.toggle:checked~.text:after {
    color: #ed4e2a;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-darkorange.toggle~.text:after {
    background-color: #ed4e2a
}
input[type=checkbox].checkbox-slider.colored-magenta~.text:after {
    border-color: #bc5679
}
input[type=checkbox].checkbox-slider.colored-magenta:checked~.text:before {
    background-color: #bc5679;
    border-color: #bc5679
}
input[type=checkbox].checkbox-slider.colored-magenta:checked~.text:after {
    background-color: #bc5679;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-magenta.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #bc5679
}
input[type=checkbox].checkbox-slider.colored-magenta.slider-icon~.text:after {
    border-color: #fff;
    color: #bc5679
}
input[type=checkbox].checkbox-slider.colored-magenta.toggle:checked~.text:after {
    color: #bc5679;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-magenta.toggle~.text:after {
    background-color: #bc5679
}
input[type=checkbox].checkbox-slider.colored-purple~.text:after {
    border-color: #7e3794
}
input[type=checkbox].checkbox-slider.colored-purple:checked~.text:before {
    background-color: #7e3794;
    border-color: #7e3794
}
input[type=checkbox].checkbox-slider.colored-purple:checked~.text:after {
    background-color: #7e3794;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-purple.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #7e3794
}
input[type=checkbox].checkbox-slider.colored-purple.slider-icon~.text:after {
    border-color: #fff;
    color: #7e3794
}
input[type=checkbox].checkbox-slider.colored-purple.toggle:checked~.text:after {
    color: #7e3794;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-purple.toggle~.text:after {
    background-color: #7e3794
}
input[type=checkbox].checkbox-slider.colored-maroon~.text:after {
    border-color: #981b48
}
input[type=checkbox].checkbox-slider.colored-maroon:checked~.text:before {
    background-color: #981b48;
    border-color: #981b48
}
input[type=checkbox].checkbox-slider.colored-maroon:checked~.text:after {
    background-color: #981b48;
    border-color: #fff
}
input[type=checkbox].checkbox-slider.colored-maroon.slider-icon:checked~.text:after {
    background-color: #fff;
    color: #981b48
}
input[type=checkbox].checkbox-slider.colored-maroon.slider-icon~.text:after {
    border-color: #fff;
    color: #981b48
}
input[type=checkbox].checkbox-slider.colored-maroon.toggle:checked~.text:after {
    color: #981b48;
    background-color: #fff
}
input[type=checkbox].checkbox-slider.colored-maroon.toggle~.text:after {
    background-color: #981b48
}
.noUi-target,
.noUi-target {
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.noUi-base {
    width: 100%;
    height: 100%;
    position: relative
}
.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box
}
.noUi-stacking .noUi-handle {
    z-index: 10
}
.noUi-stacking+.noUi-origin {
    *z-index: -1
}
.noUi-state-tap .noUi-origin {
    -webkit-transition: left .3s, top .3s;
    -moz-transition: left .3s, top .3s;
    -o-transition: left .3s, top .3s;
    transition: left .3s, top .3s
}
.noUi-state-drag {
    cursor: inherit !important
}
.noUi-horizontal {
    height: 18px
}
.noUi-horizontal.slider-xs {
    height: 14px
}
.noUi-horizontal.slider-xs .noUi-handle {
    height: 24px;
    width: 26px
}
.noUi-horizontal.slider-xs .noUi-handle:before {
    left: 10px;
    top: 4px
}
.noUi-horizontal.slider-xs .noUi-handle:after {
    left: 13px;
    top: 4px
}
.noUi-horizontal.slider-sm {
    height: 16px
}
.noUi-horizontal.slider-sm .noUi-handle {
    height: 26px;
    width: 30px
}
.noUi-horizontal.slider-sm .noUi-handle:before {
    left: 12px;
    top: 5px
}
.noUi-horizontal.slider-sm .noUi-handle:after {
    left: 15px;
    top: 5px
}
.noUi-horizontal.slider-lg {
    height: 20px
}
.noUi-horizontal.slider-lg .noUi-handle {
    height: 30px;
    width: 36px
}
.noUi-horizontal.slider-lg .noUi-handle:before {
    left: 15px;
    top: 7px
}
.noUi-horizontal.slider-lg .noUi-handle:after {
    left: 18px;
    top: 7px
}
.noUi-horizontal.slider-xl {
    height: 22px
}
.noUi-horizontal.slider-xl .noUi-handle {
    height: 32px;
    width: 38px
}
.noUi-horizontal.slider-xl .noUi-handle:before {
    left: 16px;
    top: 8px
}
.noUi-horizontal.slider-xl .noUi-handle:after {
    left: 19px;
    top: 8px
}
.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px
}
.noUi-horizontal.noUi-extended {
    padding: 0 15px
}
.noUi-horizontal.noUi-extended .noUi-origin {
    right: -15px
}
.noUi-vertical {
    width: 18px
}
.noUi-vertical.slider-xs {
    width: 14px
}
.noUi-vertical.slider-xs .noUi-handle {
    width: 24px;
    height: 26px
}
.noUi-vertical.slider-xs .noUi-handle:before {
    top: 10px;
    left: 4px
}
.noUi-vertical.slider-xs .noUi-handle:after {
    top: 13px;
    left: 4px
}
.noUi-vertical.slider-sm {
    width: 16px
}
.noUi-vertical.slider-sm .noUi-handle {
    width: 26px;
    height: 30px
}
.noUi-vertical.slider-sm .noUi-handle:before {
    top: 12px;
    left: 5px
}
.noUi-vertical.slider-sm .noUi-handle:after {
    top: 15px;
    left: 5px
}
.noUi-vertical.slider-lg {
    width: 20px
}
.noUi-vertical.slider-lg .noUi-handle {
    width: 30px;
    height: 36px
}
.noUi-vertical.slider-lg .noUi-handle:before {
    top: 15px;
    left: 7px
}
.noUi-vertical.slider-lg .noUi-handle:after {
    top: 18px;
    left: 7px
}
.noUi-vertical.slider-xl {
    width: 22px
}
.noUi-vertical.slider-xl .noUi-handle {
    width: 32px;
    height: 38px
}
.noUi-vertical.slider-xl .noUi-handle:before {
    top: 16px;
    left: 8px
}
.noUi-vertical.slider-xl .noUi-handle:after {
    top: 19px;
    left: 8px
}
.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px
}
.noUi-vertical.noUi-extended {
    padding: 15px 0
}
.noUi-vertical.noUi-extended .noUi-origin {
    bottom: -15px
}
.noUi-background {
    background: #fafafa
}
.noUi-connect {
    background: #2dc3e8;
    -webkit-transition: background 450ms;
    -moz-transition: background 450ms;
    -o-transition: background 450ms;
    transition: background 450ms
}
.noUi-target {
    border-radius: 4px;
    border: 1px solid #d3d3d3
}
.noUi-dragable {
    cursor: w-resize
}
.noUi-vertical .noUi-dragable {
    cursor: n-resize
}
.noUi-handle {
    position: relative;
    z-index: 1;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    -webkit-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    -moz-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb
}
.noUi-active {
    -webkit-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
    -moz-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb
}
.noUi-handle:before,
.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    left: 14px;
    top: 6px
}
.noUi-handle:after {
    left: 17px
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px
}
.noUi-vertical .noUi-handle:after {
    top: 17px
}
[disabled].noUi-connect,
[disabled] .noUi-connect {
    background: #b8b8b8
}
[disabled] .noUi-handle {
    cursor: not-allowed
}
.noUi-state-blocked.noUi-connect,
.noUi-state-blocked .noUi-connect {
    background: #4fdacf
}
.ui-rangeSlider {
    height: 30px;
    padding-top: 40px
}
.ui-rangeSlider.slider-sm .ui-rangeSlider-container,
.ui-rangeSlider.slider-sm .ui-rangeSlider-arrow {
    height: 20px
}
.ui-rangeSlider.slider-sm .ui-rangeSlider-bar {
    height: 18px
}
.ui-rangeSlider.slider-xs .ui-rangeSlider-container,
.ui-rangeSlider.slider-xs .ui-rangeSlider-arrow {
    height: 10px
}
.ui-rangeSlider.slider-xs .ui-rangeSlider-bar {
    height: 8px
}
.ui-rangeSlider.slider-xs .ui-rangeSlider-arrow-inner:before {
    font-size: 10px;
    line-height: 22px
}
.ui-rangeSlider.valuelabel-blue .ui-rangeSlider-label {
    background-color: #5db2ff
}
.ui-rangeSlider.valuelabel-blue .ui-rangeSlider-label-inner {
    border-top: 6px solid #5db2ff
}
.ui-rangeSlider.valuelabel-primary .ui-rangeSlider-label {
    background-color: #4374e0
}
.ui-rangeSlider.valuelabel-primary .ui-rangeSlider-label-inner {
    border-top: 6px solid #4374e0
}
.ui-rangeSlider.valuelabel-danger .ui-rangeSlider-label {
    background-color: #d73d32
}
.ui-rangeSlider.valuelabel-danger .ui-rangeSlider-label-inner {
    border-top: 6px solid #d73d32
}
.ui-rangeSlider.valuelabel-success .ui-rangeSlider-label {
    background-color: #53a93f
}
.ui-rangeSlider.valuelabel-success .ui-rangeSlider-label-inner {
    border-top: 6px solid #53a93f
}
.ui-rangeSlider.valuelabel-warning .ui-rangeSlider-label {
    background-color: #f4b400
}
.ui-rangeSlider.valuelabel-warning .ui-rangeSlider-label-inner {
    border-top: 6px solid #f4b400
}
.ui-rangeSlider.valuelabel-silver .ui-rangeSlider-label {
    background-color: #777
}
.ui-rangeSlider.valuelabel-silver .ui-rangeSlider-label-inner {
    border-top: 6px solid #777
}
.ui-rangeSlider.valuelabel-blueberry .ui-rangeSlider-label {
    background-color: #6f85bf
}
.ui-rangeSlider.valuelabel-blueberry .ui-rangeSlider-label-inner {
    border-top: 6px solid #6f85bf
}
.ui-rangeSlider.valuelabel-palegreen .ui-rangeSlider-label {
    background-color: #a0d468
}
.ui-rangeSlider.valuelabel-palegreen .ui-rangeSlider-label-inner {
    border-top: 6px solid #a0d468
}
.ui-rangeSlider.valuelabel-orange .ui-rangeSlider-label {
    background-color: #fb6e52
}
.ui-rangeSlider.valuelabel-orange .ui-rangeSlider-label-inner {
    border-top: 6px solid #fb6e52
}
.ui-rangeSlider.valuelabel-darkorange .ui-rangeSlider-label {
    background-color: #ed4e2a
}
.ui-rangeSlider.valuelabel-darkorange .ui-rangeSlider-label-inner {
    border-top: 6px solid #ed4e2a
}
.ui-rangeSlider.valuelabel-magenta .ui-rangeSlider-label {
    background-color: #bc5679
}
.ui-rangeSlider.valuelabel-magenta .ui-rangeSlider-label-inner {
    border-top: 6px solid #bc5679
}
.ui-rangeSlider.valuelabel-purple .ui-rangeSlider-label {
    background-color: #7e3794
}
.ui-rangeSlider.valuelabel-purple .ui-rangeSlider-label-inner {
    border-top: 6px solid #7e3794
}
.ui-rangeSlider.valuelabel-maroon .ui-rangeSlider-label {
    background-color: #981b48
}
.ui-rangeSlider.valuelabel-maroon .ui-rangeSlider-label-inner {
    border-top: 6px solid #981b48
}
.ui-rangeSlider.valuelabel-yellow .ui-rangeSlider-label {
    background-color: #ffce55
}
.ui-rangeSlider.valuelabel-yellow .ui-rangeSlider-label-inner {
    border-top: 6px solid #ffce55
}
.ui-rangeSlider.valuelabel-success .ui-rangeSlider-label {
    background-color: #53a93f
}
.ui-rangeSlider.valuelabel-success .ui-rangeSlider-label-inner {
    border-top: 6px solid #53a93f
}
.ui-rangeSlider.silder-blue .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-blue .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #5db2ff !important
}
.ui-rangeSlider.silder-blue .ui-rangeSlider-bar {
    background: #5db2ff !important
}
.ui-rangeSlider.silder-primary .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-primary .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #4374e0 !important
}
.ui-rangeSlider.silder-primary .ui-rangeSlider-bar {
    background: #4374e0 !important
}
.ui-rangeSlider.silder-danger .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-danger .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #d73d32 !important
}
.ui-rangeSlider.silder-danger .ui-rangeSlider-bar {
    background: #d73d32 !important
}
.ui-rangeSlider.silder-warning .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-warning .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #f4b400 !important
}
.ui-rangeSlider.silder-warning .ui-rangeSlider-bar {
    background: #f4b400 !important
}
.ui-rangeSlider.silder-silver .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-silver .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #777 !important
}
.ui-rangeSlider.silder-silver .ui-rangeSlider-bar {
    background: #777 !important
}
.ui-rangeSlider.silder-blueberry .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-blueberry .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #6f85bf !important
}
.ui-rangeSlider.silder-blueberry .ui-rangeSlider-bar {
    background: #6f85bf !important
}
.ui-rangeSlider.silder-palegreen .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-palegreen .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #a0d468 !important
}
.ui-rangeSlider.silder-palegreen .ui-rangeSlider-bar {
    background: #a0d468 !important
}
.ui-rangeSlider.silder-success .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-success .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #53a93f !important
}
.ui-rangeSlider.silder-success .ui-rangeSlider-bar {
    background: #53a93f !important
}
.ui-rangeSlider.silder-yellow .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-yellow .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #ffce55 !important
}
.ui-rangeSlider.silder-yellow .ui-rangeSlider-bar {
    background: #ffce55 !important
}
.ui-rangeSlider.silder-orange .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-orange .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #fb6e52 !important
}
.ui-rangeSlider.silder-orange .ui-rangeSlider-bar {
    background: #fb6e52 !important
}
.ui-rangeSlider.silder-darkorange .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-darkorange .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #ed4e2a !important
}
.ui-rangeSlider.silder-darkorange .ui-rangeSlider-bar {
    background: #ed4e2a !important
}
.ui-rangeSlider.silder-magenta .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-magenta .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #bc5679 !important
}
.ui-rangeSlider.silder-magenta .ui-rangeSlider-bar {
    background: #bc5679 !important
}
.ui-rangeSlider.silder-purple .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-purple .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #7e3794 !important
}
.ui-rangeSlider.silder-purple .ui-rangeSlider-bar {
    background: #7e3794 !important
}
.ui-rangeSlider.silder-maroon .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider.silder-maroon .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #981b48 !important
}
.ui-rangeSlider.silder-maroon .ui-rangeSlider-bar {
    background: #981b48 !important
}
.ui-rangeSlider,
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.ui-rangeSlider-withArrows .ui-rangeSlider-container {
    margin: 0 15px
}
.ui-rangeSlider-noArrow .ui-rangeSlider-container {
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    border-left: solid 1px #d3d3d3;
    border-right: solid 1px #d3d3d3
}
.ui-rangeSlider-disabled.ui-rangeSlider-noArrow .ui-rangeSlider-container {
    border-color: #8490a3
}
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
    height: 30px;
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
    background: #fff
}
.ui-rangeSlider-label {
    background-color: #fb6e52;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    padding: 5px 10px;
    bottom: 36px;
    color: #fff;
    font-size: 13px;
    cursor: col-resize
}
.ui-rangeSlider-disabled .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow,
.ui-rangeSlider-disabled .ui-rangeSlider-label {
    zoom: 1;
    filter: alpha(opacity=40);
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4
}
.ui-rangeSlider-arrow {
    width: 14px;
    cursor: pointer
}
.ui-rangeSlider-leftArrow {
    -webkit-border-radius: 2px 0 0 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px 0 0 2px;
    -moz-background-clip: padding;
    border-radius: 2px 0 0 2px;
    background-clip: padding-box;
    border-left: solid 1px #d3d3d3
}
.ui-rangeSlider-rightArrow {
    -webkit-border-radius: 0 2px 2px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 2px 2px 0;
    -moz-background-clip: padding;
    border-radius: 0 2px 2px 0;
    background-clip: padding-box;
    border-right: solid 1px #d3d3d3
}
.ui-rangeSlider-arrow-inner {
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    margin-top: -10px
}
.ui-rangeSlider-arrow-inner:before {
    position: absolute;
    top: 0;
    font-family: FontAwesome;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    border-width: 1px;
    content: "";
    color: #d3d3d3
}
.ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner:before {
    content: "";
    left: 8px;
    margin-left: -5px
}
.ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner:before,
.ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner:before {
    color: #a0d468
}
.ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner:before {
    content: "";
    right: 0;
    margin-right: -11px
}
.ui-rangeSlider-innerBar {
    width: 110%;
    height: 100%;
    left: -10px;
    overflow: hidden
}
.ui-rangeSlider-bar {
    background: #2dc3e8;
    height: 28px;
    margin: 1px;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab
}
.ui-rangeSlider-disabled .ui-rangeSlider-bar {
    zoom: 1;
    filter: alpha(opacity=40);
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4
}
.ui-rangeSlider-handle {
    width: 10px;
    height: 30px;
    background: transparent;
    cursor: col-resize
}
.ui-rangeSlider-label-inner {
    position: absolute;
    top: 100%;
    left: 50%;
    display: block;
    z-index: 99;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: -6px;
    border-top: 6px solid #fb6e52
}
.ui-rangeSlider-disabled .ui-rangeSlider-label-inner {
    zoom: 1;
    filter: alpha(opacity=40);
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4
}
.ui-editRangeSlider-inputValue {
    width: 2em;
    text-align: center;
    font-size: 13px;
    border: 1px solid transparent !important;
    margin-left: -5px;
    margin-right: -5px
}
.ui-rangeSlider .ui-ruler-scale {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}
.ui-rangeSlider .ui-ruler-tick {
    float: left
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-inner {
    color: #d3d3d3;
    margin-top: 1px;
    border-left: 1px solid #d3d3d3;
    height: 30px;
    padding-left: 2px;
    position: relative
}
.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-label {
    position: absolute;
    bottom: 6px
}
.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-label {
    color: #fbfbfb
}
.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-inner {
    border-left: 1px solid #d3d3d3;
    margin-top: 25px;
    height: 5px
}
.spinbox {
    position: relative
}
.spinbox input {
    float: left;
    padding-left: 35px
}
.spinbox .btn {
    position: relative;
    width: 20px;
    padding-top: 0;
    padding-right: 9px;
    padding-left: 9px;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 17px;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7
}
.spinbox .btn:hover {
    color: #f5f5f5;
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.spinbox .btn:focus,
.spinbox .btn:active {
    color: #f5f5f5;
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.spinbox .btn.disabled {
    cursor: not-allowed
}
.spinbox .spinbox-buttons {
    position: absolute;
    left: 0;
    float: left;
    width: 20px;
    height: 34px;
    border-left: 1px solid #d5d5d5
}
.spinbox .spinbox-up {
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    top: 0;
    background-color: #fbfbfb;
    border-left: 1px !important
}
.spinbox .spinbox-up i {
    position: relative;
    top: -6px;
    margin-right: 0
}
.spinbox .spinbox-down {
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    bottom: 0;
    background-color: #fbfbfb;
    border-bottom-left-radius: 0 !important;
    border-left: 1px !important;
    border-top: 1px solid #d5d5d5
}
.spinbox .spinbox-down i {
    position: relative;
    top: -7px;
    margin-right: 0
}
.spinbox.spinbox-right .spinbox-buttons {
    left: auto;
    right: 0;
    border-right: 0
}
.spinbox.spinbox-right input {
    padding-left: 10px;
    padding-right: 35px;
    margin-left: 0
}
.spinbox.spinbox-horizontal input {
    padding-left: 60px
}
.spinbox.spinbox-horizontal .spinbox-buttons {
    margin: 0;
    font-size: 0;
    width: 50px;
    left: 0;
    top: 0
}
.spinbox.spinbox-horizontal .spinbox-buttons>.btn {
    height: 34px;
    line-height: 32px;
    font-size: 18px;
    display: inline-block;
    vertical-align: sub;
    margin: 0
}
.spinbox.spinbox-horizontal .spinbox-buttons>.btn>i {
    vertical-align: middle;
    display: inline-block
}
.spinbox.spinbox-horizontal .spinbox-down {
    width: 24px;
    border-left: 0
}
.spinbox.spinbox-horizontal .spinbox-down i {
    top: -5px
}
.spinbox.spinbox-horizontal .spinbox-up {
    width: 24px
}
.spinbox.spinbox-horizontal .spinbox-up i {
    top: -5px
}
.spinbox.spinbox-horizontal.spinbox-right {
    margin-left: 0
}
.spinbox.spinbox-horizontal.spinbox-right .spinbox-buttons {
    right: -1px;
    left: auto
}
.spinbox.spinbox-horizontal.spinbox-right input {
    padding-left: 10px
}
.spinbox.spinbox-horizontal.spinbox-two-sided .spinbox-buttons {
    width: 25px !important
}
.spinbox.spinbox-horizontal.spinbox-two-sided .spinbox-buttons.spinbox-buttons-left {
    left: 0
}
.spinbox.spinbox-horizontal.spinbox-two-sided .spinbox-buttons.spinbox-buttons-right {
    right: 0;
    left: auto
}
.spinbox.spinbox-horizontal.spinbox-two-sided input {
    padding-left: 35px !important
}
.bootstrap-tagsinput {
    display: block;
    width: 100%;
    min-height: 32px;
    padding: 1px 3px;
    font-size: 13px;
    line-height: 1.428571429;
    color: #262626;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0
}
.bootstrap-tagsinput>span {
    font-weight: 400;
    padding: 4px 28px 4px 8px;
    font-size: 13px;
    border: 1px solid #2dc3e8;
    background: #2dc3e8
}
.bootstrap-tagsinput input {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit
}
.bootstrap-tagsinput input:focus {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.bootstrap-tagsinput .tag {
    color: #fff;
    position: relative;
    margin: 3px 0 3px 2px;
    display: inline-block;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box
}
.bootstrap-tagsinput .tag [data-role=remove] {
    display: block;
    top: -1px;
    right: 0;
    padding: 3px 4px 3px 5px;
    width: 23px;
    height: 22px;
    position: absolute;
    cursor: pointer
}
.bootstrap-tagsinput .tag [data-role=remove]:hover:after {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.bootstrap-tagsinput .tag [data-role=remove]:hover:active:after {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.bootstrap-tagsinput .tag [data-role=remove]:after {
    content: "";
    font-family: fontAwesome;
    padding: 2px 1px;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7
}
.bootstrap-tagsinput .tag [data-role=remove]:after:hover {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.bootstrap-tagsinput .tag [data-role=remove]:after:hover:active {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.minicolors {
    position: relative
}
.minicolors-swatch {
    position: absolute;
    vertical-align: middle;
    background: url(../img/jquery.minicolors.png) -80px 0;
    border: solid 1px #fbfbfb;
    cursor: text;
    padding: 0;
    margin: 0;
    display: inline-block;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.minicolors-swatch-color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}
.minicolors input[type=hidden]+.minicolors-swatch {
    width: 28px;
    position: static;
    cursor: pointer
}
.minicolors-panel {
    position: absolute;
    width: 173px;
    height: 152px;
    background: #fff;
    border: solid 1px #ccc;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
    z-index: 99999;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: none
}
.minicolors-panel.minicolors-visible {
    display: block
}
.minicolors-position-top .minicolors-panel {
    top: -154px
}
.minicolors-position-right .minicolors-panel {
    right: 0
}
.minicolors-position-bottom .minicolors-panel {
    top: auto
}
.minicolors-position-left .minicolors-panel {
    left: 0
}
.minicolors-with-opacity .minicolors-panel {
    width: 194px
}
.minicolors .minicolors-grid {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 150px;
    height: 150px;
    background: url(../img/jquery.minicolors.png) -120px 0;
    cursor: crosshair
}
.minicolors .minicolors-grid-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: none
}
.minicolors-slider-saturation .minicolors-grid {
    background-position: -420px 0
}
.minicolors-slider-saturation .minicolors-grid-inner {
    background: url(../img/jquery.minicolors.png) -270px 0
}
.minicolors-slider-brightness .minicolors-grid {
    background-position: -570px 0
}
.minicolors-slider-brightness .minicolors-grid-inner {
    background: #000
}
.minicolors-slider-wheel .minicolors-grid {
    background-position: -720px 0
}
.minicolors-slider,
.minicolors-opacity-slider {
    position: absolute;
    top: 1px;
    left: 152px;
    width: 20px;
    height: 150px;
    background: #fff url(../img/jquery.minicolors.png) 0 0;
    cursor: row-resize
}
.minicolors-slider-saturation .minicolors-slider {
    background-position: -60px 0
}
.minicolors-slider-brightness .minicolors-slider {
    background-position: -20px 0
}
.minicolors-slider-wheel .minicolors-slider {
    background-position: -20px 0
}
.minicolors-opacity-slider {
    left: 173px;
    background-position: -40px 0;
    display: none
}
.minicolors-with-opacity .minicolors-opacity-slider {
    display: block
}
.minicolors-grid .minicolors-picker {
    position: absolute;
    top: 70px;
    left: 70px;
    width: 12px;
    height: 12px;
    border: solid 1px #000;
    border-radius: 10px;
    margin-top: -6px;
    margin-left: -6px;
    background: none
}
.minicolors-grid .minicolors-picker>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: solid 2px #fff;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.minicolors-picker {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 2px;
    background: #fff;
    border: solid 1px #000;
    margin-top: -2px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}
.minicolors-inline {
    display: inline-block
}
.minicolors-inline .minicolors-input {
    display: none !important
}
.minicolors-inline .minicolors-panel {
    position: relative;
    top: auto;
    left: auto;
    box-shadow: none;
    z-index: auto;
    display: inline-block
}
.minicolors-theme-bootstrap .minicolors-swatch {
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.minicolors-theme-bootstrap.minicolors-position-right .minicolors-swatch {
    left: auto;
    right: 3px
}
.minicolors-theme-bootstrap .minicolors-input {
    padding-left: 44px
}
.minicolors-theme-bootstrap.minicolors-position-right .minicolors-input {
    padding-right: 44px;
    padding-left: 12px
}
.dropdown-colorpicker {
    height: 35px;
    width: 35px;
    padding: 7px
}
.dropdown-colorpicker>.dropdown-menu {
    top: 100%;
    left: 0;
    padding: 4px;
    min-width: 154px;
    max-width: 154px
}
.dropdown-colorpicker>.dropdown-menu>li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    position: relative;
    -webkit-transition: all ease .1s;
    -moz-transition: all ease .1s;
    -o-transition: all ease .1s;
    transition: all ease .1s
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn:hover {
    text-decoration: none;
    zoom: 1;
    filter: alpha(opacity=80);
    -webkit-opacity: .8;
    -moz-opacity: .8;
    opacity: .8;
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -o-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08)
}
.dropdown-colorpicker>.dropdown-menu>li>.colorpick-btn.selected:after {
    content: "";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 11px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px
}
.btn-colorpicker {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    vertical-align: middle;
    border-radius: 0
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px
}
.bootstrap-timepicker {
    position: relative
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
    left: auto;
    right: 0
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
    left: auto;
    right: 12px
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
    left: auto;
    right: 13px
}
.bootstrap-timepicker .add-on {
    cursor: pointer
}
.bootstrap-timepicker .add-on i {
    display: inline-block;
    width: 16px;
    height: 16px
}
.bootstrap-timepicker-widget.dropdown-menu {
    padding: 4px
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    display: inline-block
}
.bootstrap-timepicker-widget.dropdown-menu:before {
    border-bottom: 7px solid rgba(0, 0, 0, .2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    position: absolute
}
.bootstrap-timepicker-widget.dropdown-menu:after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    position: absolute
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
    left: 6px
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
    left: 7px
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
    right: 6px
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
    right: 7px
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
    top: -7px
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
    top: -6px
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
    border-radius: 4px
}
.bootstrap-timepicker-widget table {
    width: 100%;
    margin: 0
}
.bootstrap-timepicker-widget table td {
    text-align: center;
    height: 30px;
    margin: 0;
    padding: 2px
}
.bootstrap-timepicker-widget table td:not(.separator) {
    min-width: 30px
}
.bootstrap-timepicker-widget table td span {
    width: 100%
}
.bootstrap-timepicker-widget table td a {
    border: 1px transparent solid;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 8px 0;
    outline: 0;
    color: #333
}
.bootstrap-timepicker-widget table td a:hover {
    text-decoration: none;
    background-color: #eee;
    border-color: #ddd
}
.bootstrap-timepicker-widget table td a i {
    margin-top: 2px;
    font-size: 14px;
    color: #262626
}
.bootstrap-timepicker-widget table td input {
    width: 35px;
    margin: 0;
    text-align: center;
    padding: 5px
}
.bootstrap-timepicker-widget .modal-content {
    padding: 4px
}
@media(min-width:767px) {
    .bootstrap-timepicker-widget.modal {
        width: 200px;
        margin-left: -100px
    }
}
@media(max-width:767px) {
    .bootstrap-timepicker {
        width: 100%
    }
    .bootstrap-timepicker .dropdown-menu {
        width: 100%
    }
}
.wysiwyg-editor {
    max-height: 250px;
    height: 250px;
    background-color: #fff;
    border-collapse: separate;
    border: 1px solid #ccc;
    padding: 4px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: rgba(0, 0, 0, .0745098) 0 1px 1px 0 inset;
    -moz-box-shadow: rgba(0, 0, 0, .0745098) 0 1px 1px 0 inset;
    box-shadow: rgba(0, 0, 0, .0745098) 0 1px 1px 0 inset;
    overflow: scroll;
    outline: none
}
.wysiwyg-toolbar {
    margin-bottom: 10px
}
.wysiwyg-toolbar .btn-active {
    color: #444 !important;
    background-color: #e5e5e5
}
.wysiwyg-toolbar .dropdown-menu {
    padding: 5px
}
.wysiwyg-toolbar .btn-group {
    margin-bottom: 5px
}
.wysiwyg-voiceBtn {
    width: 20px;
    color: transparent !important;
    background-color: transparent !important;
    -webkit-transform: scale(2, 2);
    -moz-transform: scale(2, 2);
    -o-transform: scale(2, 2);
    -ms-transform: scale(2, 2);
    transform: scale(2, 2);
    border: transparent !important;
    cursor: pointer;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
div[data-role="editor-toolbar"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.note-editor {
    border: 1px solid #d5d5d5
}
.note-editor .note-dropzone {
    position: absolute;
    z-index: 1;
    display: none;
    color: #87cefa;
    background-color: #fff;
    border: 2px dashed #87cefa;
    opacity: .95;
    pointer-event: none
}
.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle
}
.note-editor .note-dropzone.hover {
    color: #2dc3e8;
    border: 2px dashed #2dc3e8
}
.note-editor.dragover .note-dropzone {
    display: table
}
.note-editor.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%
}
.note-editor.fullscreen .note-editable {
    background-color: #fff
}
.note-editor.fullscreen .note-resizebar {
    display: none
}
.note-editor.codeview .note-editable {
    display: none
}
.note-editor.codeview .note-codable {
    display: block
}
.note-editor .note-toolbar {
    padding-bottom: 5px;
    padding-left: 5px;
    margin: 0;
    background-color: #fbfbfb;
    border-bottom: 1px solid #d5d5d5
}
.note-editor .note-toolbar>.btn-group {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 0
}
.note-editor .note-toolbar>.input-group {
    float: none
}
.note-editor .note-toolbar .note-table .dropdown-menu {
    min-width: 0;
    padding: 5px
}
.note-editor .note-toolbar .note-table .dropdown-menu .note-dimension-picker {
    font-size: 18px
}
.note-editor .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer
}
.note-editor .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}
.note-editor .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}
.note-editor .note-toolbar .note-style h1,
.note-editor .note-toolbar .note-style h2,
.note-editor .note-toolbar .note-style h3,
.note-editor .note-toolbar .note-style h4,
.note-editor .note-toolbar .note-style h5,
.note-editor .note-toolbar .note-style h6,
.note-editor .note-toolbar .note-style blockquote {
    margin: 0
}
.note-editor .note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px
}
.note-editor .note-toolbar .note-color .dropdown-menu {
    min-width: 290px
}
.note-editor .note-toolbar .note-color .dropdown-menu .btn-group {
    margin: 0
}
.note-editor .note-toolbar .note-color .dropdown-menu .btn-group:first-child {
    margin: 0 5px
}
.note-editor .note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
    margin: 2px 7px;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #eee
}
.note-editor .note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
    padding: 0 3px;
    margin: 5px;
    font-size: 12px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}
.note-editor .note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
    background: #eee
}
.note-editor .note-toolbar .note-para .dropdown-menu {
    min-width: 216px;
    padding: 5px
}
.note-editor .note-toolbar .note-para .dropdown-menu>div:first-child {
    margin-right: 5px
}
.note-editor .note-statusbar {
    background-color: #f5f5f5
}
.note-editor .note-statusbar .note-resizebar {
    width: 100%;
    height: 8px;
    cursor: s-resize;
    border-top: 1px solid #d5d5d5
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #d5d5d5
}
.note-editor .note-popover .popover {
    max-width: none
}
.note-editor .note-popover .popover .popover-content {
    padding: 5px
}
.note-editor .note-popover .popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle
}
.note-editor .note-popover .popover .popover-content .btn-group+.btn-group {
    margin-left: 5px
}
.note-editor .note-popover .popover .arrow {
    left: 20px
}
.note-editor .note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid #000
}
.note-editor .note-handle .note-control-selection>div {
    position: absolute
}
.note-editor .note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    opacity: .3;
    -ms-filter: alpha(opacity=30);
    filter: alpha(opacity=30)
}
.note-editor .note-handle .note-control-selection .note-control-handle {
    width: 7px;
    height: 7px;
    border: 1px solid #000
}
.note-editor .note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid #000
}
.note-editor .note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    background-color: #fff;
    border: 1px solid #000
}
.note-editor .note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0
}
.note-editor .note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none
}
.note-editor .note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0
}
.note-editor .note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize
}
.note-editor .note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: alpha(opacity=70);
    filter: alpha(opacity=70)
}
.note-editor .note-dialog>div {
    display: none
}
.note-editor .note-dialog .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: #d3d3d3;
    text-align: center;
    border: 4px dashed #d3d3d3
}
.note-editor .note-dialog .note-help-dialog {
    font-size: 12px;
    color: #ccc;
    background: transparent;
    background-color: #222 !important;
    border: 0;
    -webkit-opacity: .9;
    -khtml-opacity: .9;
    -moz-opacity: .9;
    opacity: .9;
    -ms-filter: alpha(opacity=90);
    filter: alpha(opacity=90)
}
.note-editor .note-dialog .note-help-dialog .modal-content {
    background: transparent;
    border: 1px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.note-editor .note-dialog .note-help-dialog a {
    font-size: 12px;
    color: #fff
}
.note-editor .note-dialog .note-help-dialog .title {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border-bottom: #fff 1px solid
}
.note-editor .note-dialog .note-help-dialog .modal-close {
    font-size: 14px;
    color: #dd0;
    cursor: pointer
}
.note-editor .note-dialog .note-help-dialog .note-shortcut-layout {
    width: 100%
}
.note-editor .note-dialog .note-help-dialog .note-shortcut-layout td {
    vertical-align: top
}
.note-editor .note-dialog .note-help-dialog .note-shortcut {
    margin-top: 8px
}
.note-editor .note-dialog .note-help-dialog .note-shortcut th {
    font-size: 13px;
    color: #dd0;
    text-align: left
}
.note-editor .note-dialog .note-help-dialog .note-shortcut td:first-child {
    min-width: 110px;
    padding-right: 10px;
    font-family: "Courier New";
    color: #dd0;
    text-align: right
}
.note-editor .note-editable {
    padding: 10px;
    overflow: auto;
    outline: 0;
    background-color: #fff
}
.note-editor .note-editable[contenteditable="false"] {
    background-color: #e5e5e5
}
.note-editor .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    resize: none
}
.note-editor .dropdown-menu {
    min-width: 90px
}
.note-editor .dropdown-menu.right {
    right: 0;
    left: auto
}
.note-editor .dropdown-menu.right::before {
    right: 9px;
    left: auto !important
}
.note-editor .dropdown-menu.right::after {
    right: 10px;
    left: auto !important
}
.note-editor .dropdown-menu li a i {
    color: #2dc3e8;
    visibility: hidden
}
.note-editor .dropdown-menu li a.checked i {
    visibility: visible
}
.note-editor .note-fontsize-10 {
    font-size: 10px
}
.note-editor .note-color-palette {
    line-height: 1
}
.note-editor .note-color-palette div .note-color-btn {
    width: 17px;
    height: 17px;
    padding: 0;
    margin: 0;
    border: 1px solid #fff
}
.note-editor .note-color-palette div .note-color-btn:hover {
    border: 1px solid #000
}
.bv-form .help-block {
    margin-bottom: 0
}
.nav-tabs li.bv-tab-success>a {
    color: #53a93f
}
.nav-tabs li.bv-tab-error>a {
    color: #d73d32
}
.login-container {
    position: relative;
    margin: 10% auto;
    max-width: 300px
}
.login-container .loginbox {
    position: relative;
    width: 300px !important;
    height: auto !important;
    padding: 0 0 20px 0;
    -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    box-shadow: 0 0 14px rgba(0, 0, 0, .1)
}
.login-container .loginbox .loginbox-title {
    position: relative;
    text-align: center;
    width: 100%;
    height: 35px;
    padding-top: 10px;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-size: 20px;
    font-weight: normal;
    color: #444
}
.login-container .loginbox .loginbox-social {
    padding: 0 10px 10px;
    text-align: center
}
.login-container .loginbox .loginbox-social .social-title {
    font-size: 14px;
    font-weight: 500;
    color: #a9a9a9;
    margin-top: 10px
}
.login-container .loginbox .loginbox-social .social-buttons {
    height: 80px;
    padding: 15px 35px;
    text-align: center
}
.login-container .loginbox .loginbox-social .social-buttons .button-facebook {
    float: left;
    border: 2px solid #3b5998;
    color: #3b5998;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    background-color: #fff
}
.login-container .loginbox .loginbox-social .social-buttons .button-facebook i {
    font-size: 26px;
    line-height: 50px
}
.login-container .loginbox .loginbox-social .social-buttons .button-twitter {
    float: left;
    border: 2px solid #29c1f6;
    color: #29c1f6;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    background-color: #fff
}
.login-container .loginbox .loginbox-social .social-buttons .button-twitter i {
    font-size: 26px;
    line-height: 50px
}
.login-container .loginbox .loginbox-social .social-buttons .button-google {
    border: 2px solid #ef4f1d;
    color: #ef4f1d;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff
}
.login-container .loginbox .loginbox-social .social-buttons .button-google i {
    font-size: 26px;
    line-height: 50px
}
.login-container .loginbox .loginbox-or {
    position: relative;
    text-align: center;
    height: 20px
}
.login-container .loginbox .loginbox-or .or {
    position: absolute;
    top: 0;
    -lh-property: 0;
    left: -webkit-calc(50% - 25px);
    left: -moz-calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 20px;
    background-color: #fff;
    color: #999;
    margin: 0 auto
}
.login-container .loginbox .loginbox-or .or-line {
    position: absolute;
    height: 1px;
    top: 10px;
    left: 40px;
    right: 40px;
    background-color: #ccc
}
.login-container .loginbox .loginbox-textbox {
    padding: 10px 40px
}
.login-container .loginbox .loginbox-textbox .form-control {
    -webkit-border-radius: 3px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 3px !important;
    -moz-background-clip: padding !important;
    border-radius: 3px !important;
    background-clip: padding-box !important
}
.login-container .loginbox .loginbox-forgot {
    padding-left: 40px
}
.login-container .loginbox .loginbox-forgot a {
    font-size: 11px;
    color: #666
}
.login-container .loginbox .loginbox-submit {
    padding: 10px 40px
}
.login-container .loginbox .loginbox-signup {
    text-align: center;
    padding-top: 10px
}
.login-container .loginbox .loginbox-signup a {
    font-size: 13px;
    color: #666
}
.login-container .logobox {
    width: 300px !important;
    height: 50px !important;
    padding: 5px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    background-color: #fff;
    text-align: left
}
.register-container {
    position: relative;
    margin: 8% auto;
    max-width: 350px
}
.register-container .registerbox {
    position: relative;
    width: 350px !important;
    height: 560px !important;
    padding: 0;
    -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    box-shadow: 0 0 14px rgba(0, 0, 0, .1)
}
.register-container .registerbox .registerbox-title {
    position: relative;
    text-align: left;
    width: 100%;
    height: 35px;
    padding: 20px 20px 0;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    color: #444
}
.register-container .registerbox .registerbox-caption {
    font-size: 14px;
    font-weight: 500;
    color: #a9a9a9;
    padding: 15px 20px 0
}
.register-container .registerbox .registerbox-textbox {
    padding: 10px 20px
}
.register-container .registerbox .registerbox-textbox .form-control {
    -webkit-border-radius: 3px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 3px !important;
    -moz-background-clip: padding !important;
    border-radius: 3px !important;
    background-clip: padding-box !important
}
.register-container .registerbox .registerbox-submit {
    padding: 0 20px
}
.register-container .logobox {
    width: 350px !important;
    height: 50px !important;
    padding: 5px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    box-shadow: 0 0 14px rgba(0, 0, 0, .1);
    background-color: #fff;
    text-align: left
}
.lock-container {
    margin: 15% auto;
    max-width: 300px
}
.lock-container .lock-box {
    position: relative;
    background: #fff;
    width: 280px;
    height: 170px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YzZjNmMyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: -moz-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: -o-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: linear-gradient(to top, #fff, #f3f3f3)
}
.lock-container .lock-box .lock-username {
    position: absolute;
    height: 50px;
    width: 100%;
    line-height: 50px;
    top: 60px;
    text-align: center;
    color: #999
}
.lock-container .lock-box img {
    position: absolute;
    -lh-property: 0;
    left: -webkit-calc(50% - 64px);
    left: -moz-calc(50% - 64px);
    left: calc(50% - 64px);
    top: -64px;
    border: 3px solid #fff;
    width: 128px;
    height: 128px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3)
}
.lock-container .lock-box .lock-password {
    position: absolute;
    top: 115px;
    text-align: center;
    -lh-property: 0;
    left: -webkit-calc(50% - 114px);
    left: -moz-calc(50% - 114px);
    left: calc(50% - 114px)
}
.lock-container .lock-box .lock-password input {
    background-color: #fff;
    border-color: #e5e5e5;
    -webkit-border-radius: 4px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 4px !important;
    -moz-background-clip: padding !important;
    border-radius: 4px !important;
    background-clip: padding-box !important;
    text-align: center;
    padding-left: 25px
}
.lock-container .lock-box .btn-lock,
.lock-container .lock-box .btn-lock:hover {
    background: #fff;
    color: #2dc3e8;
    margin-top: -8px;
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.lock-container .lock-box .btn-lock i:before,
.lock-container .lock-box .btn-lock:hover i:before {
    font-size: 18px
}
.lock-container .signinbox {
    width: 280px !important;
    height: 50px !important;
    padding: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    background-color: #fff;
    text-align: center
}
.lock-container .signinbox a {
    font-size: 13px;
    color: #2dc3e8;
    padding-left: 5px;
    cursor: pointer
}
.pricing-container {
    padding: 20px;
    -webkit-text-shadow: gba(0, 0, 0, .2) 1px 1px 1px;
    text-shadow: gba(0, 0, 0, .2) 1px 1px 1px
}
.pricing-container [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px
}
.pricing-container .plan {
    text-align: center;
    background: #fff;
    color: #333;
    padding: 0;
    width: 100%;
    float: left;
    _display: inline;
    position: relative;
    margin: 0 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    margin-bottom: 20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YzZjNmMyIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: -webkit-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: -moz-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: -o-linear-gradient(bottom, #fff, #f3f3f3);
    background-image: linear-gradient(to top, #fff, #f3f3f3)
}
.pricing-container .plan .header {
    position: relative;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 15px;
    margin: 0;
    border-bottom: 3px solid
}
.pricing-container .plan .price {
    font-size: 26px;
    padding: 5px 5px 0;
    background-color: #fff;
    -webkit-text-shadow: none;
    text-shadow: none
}
.pricing-container .plan .monthly {
    font-size: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #999;
    background-color: #fff;
    padding-bottom: 10px;
    -webkit-text-shadow: none;
    text-shadow: none
}
.pricing-container .plan ul {
    margin: 20px 0;
    padding: 0;
    list-style: none
}
.pricing-container .plan ul li {
    padding: 10px 0
}
.pricing-container .plan ul li p {
    margin: 0 20px;
    text-align: left
}
.pricing-container .plan .signup {
    position: relative;
    padding: 8px 17px;
    color: #fff;
    font: bold 15px 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    background-color: #2dc3e8;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, .15);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .15);
    opacity: .9;
    margin: 15px
}
.pricing-container .plan .signup:hover {
    opacity: 1
}
.pricing-container .plan .signup:active {
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .3) inset;
    -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, .3) inset;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .3) inset
}
.pricing-container .popular-plan {
    top: -20px;
    padding: 0 0 25px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3)
}
.pricing-container .popular-plan .header {
    padding: 35px 15px 15px
}
.plan-circular {
    margin: 10px auto;
    text-align: center
}
.plan-circular .plan-title {
    color: #fff
}
.plan-circular .title-devider {
    margin: 10px 40%;
    border-color: #fff
}
.plan-circular .plan-body {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border: 10px solid rgba(255, 255, 255, .3);
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif
}
.plan-circular .plan-body .plan-price {
    position: absolute;
    top: 33px;
    right: 35px;
    font-size: 40px;
    color: #555
}
.plan-circular .plan-body .plan-currency {
    position: absolute;
    top: 33px;
    right: 80px;
    font-size: 26px;
    color: #777
}
.plan-circular .plan-body .plan-month {
    position: absolute;
    top: 80px;
    right: 38px;
    font-size: 12px;
    color: #999
}
.plan-circular .plan-tag {
    width: 25px;
    height: 40px;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    margin: 0 auto;
    margin-top: -15px
}
.plan-circular .plan-tag .tag-icon {
    margin-top: 20px;
    font-size: 15px;
    color: #fff
}
.invoice-container .table-bordered thead>tr {
    background-color: #fff !important;
    background-image: none !important
}
.invoice-container .panel {
    -webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box
}
.invoice-container .panel .panel-heading {
    background-color: #fff
}
.invoice-container .panel .panel-heading h1,
.invoice-container .panel .panel-heading h2,
.invoice-container .panel .panel-heading h3,
.invoice-container .panel .panel-heading h4,
.invoice-container .panel .panel-heading h5,
.invoice-container .panel .panel-heading h6 {
    margin-bottom: 0
}
.invoice-container ul {
    list-style: none;
    padding-left: 0
}
.invoice-container ul li {
    line-height: 22px
}
.timeline {
    list-style: none;
    padding: 0;
    position: relative
}
.timeline:before {
    top: 0;
    bottom: -35px;
    position: absolute;
    content: " ";
    width: 3px;
    left: 50%;
    margin-left: -1.5px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .175);
    background-color: #f3f3f3
}
.timeline>li {
    margin-bottom: 20px;
    position: relative
}
.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}
.timeline>li:after {
    clear: both
}
.timeline>li>.timeline-datetime {
    color: #737373;
    position: absolute;
    left: 55%;
    top: 6px;
    text-align: left;
    padding: 10px
}
.timeline>li>.timeline-datetime .timeline-time,
.timeline>li>.timeline-datetime .timeline-date {
    display: block;
    font-size: 11px;
    font-family: 'Lucida Sans', 'trebuchet MS', Arial, Helvetica
}
.timeline>li>.timeline-datetime .timeline-time {
    font-size: 14px;
    font-weight: bold
}
.timeline>li>.timeline-panel {
    background-color: #fbfbfb;
    -lh-property: 0;
    width: -webkit-calc(50% - 45px);
    width: -moz-calc(50% - 45px);
    width: calc(50% - 45px);
    float: left;
    border-radius: 2px;
    padding: 0;
    position: relative;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .175)
}
.timeline>li>.timeline-panel .timeline-header .timeline-title {
    display: block;
    padding: 10px 20px 0;
    min-width: 200px;
    font-size: 14px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-weight: 700
}
.timeline>li>.timeline-panel .timeline-header .timeline-datetime {
    position: absolute;
    top: 5px;
    right: 10px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    font-size: 13px;
    display: none
}
.timeline>li>.timeline-panel .timeline-body {
    padding: 10px 20px 20px;
    line-height: 22px
}
.timeline>li>.timeline-panel .timeline-body>p,
.timeline>li>.timeline-panel .timeline-body>ul {
    margin-bottom: 0
}
.timeline>li>.timeline-panel .timeline-body>p+p {
    margin-top: 5px
}
.timeline>li>.timeline-panel .timeline-footer {
    padding: 5px 20px 10px;
    border-top: 1px solid #eee
}
.timeline>li>.timeline-panel .timeline-footer .footer-registrar {
    font-size: 12px;
    color: #555
}
.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 16px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #e5e5e5;
    border-right: 0 solid #e5e5e5;
    border-bottom: 15px solid transparent;
    content: " "
}
.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 17px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fbfbfb;
    border-right: 0 solid #fbfbfb;
    border-bottom: 14px solid transparent;
    content: " "
}
.timeline>li .timeline-badge {
    width: 50px;
    height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -25px;
    background-image: linear-gradient(to bottom, #eee 0, #fbfbfb 100%);
    z-index: 100;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .175);
    color: #444
}
.timeline>li .timeline-badge .badge-picture {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box
}
.timeline>li .timeline-badge i {
    line-height: 50px
}
.timeline>li .timeline-badge[class*="bg-"] {
    background-image: none !important;
    color: #fff
}
.timeline>li.timeline-inverted>.timeline-datetime {
    left: auto;
    right: 55%;
    text-align: right
}
.timeline>li.timeline-inverted>.timeline-panel {
    float: right
}
.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto
}
.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto
}
.timeline>li.timeline-node {
    width: 150px;
    margin: 0 auto 20px;
    text-align: center
}
@media(max-width:767px) {
    .timeline:before {
        left: 40px
    }
    .timeline>li>.timeline-panel {
        -lh-property: 0;
        width: -webkit-calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: calc(100% - 90px)
    }
    .timeline>li>.timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 6px
    }
    .timeline>li>.timeline-panel {
        float: right
    }
    .timeline>li>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto
    }
    .timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto
    }
    .timeline>li>.timeline-panel .timeline-header .timeline-title {
        top: 5px
    }
    .timeline>li>.timeline-panel .timeline-header .timeline-datetime {
        display: block
    }
    .timeline>li>.timeline-datetime {
        display: none
    }
    .timeline>li.timeline-node {
        margin: 0 0 20px -35px
    }
}
@media(max-width:500px) {
    .timeline>li>.timeline-panel .timeline-header .timeline-datetime {
        position: relative;
        padding: 5px 30px 0;
        margin: 0
    }
    .timeline>li>.timeline-panel .timeline-body {
        padding: 20px 20px 20px
    }
}
.profile-container .profile-header {
    min-height: 175px;
    margin: 15px 15px 0;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    background-color: #fbfbfb
}
.profile-container .profile-header .header-avatar {
    width: 125px;
    height: 125px;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    border: 5px solid #f5f5f5;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    margin: 25px auto
}
.profile-container .profile-header .profile-info {
    min-height: 175px;
    border-right: 1px solid #eee;
    padding: 15px 40px 35px 0
}
.profile-container .profile-header .profile-info .header-fullname {
    font: 21px 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    margin-top: 27px;
    display: inline-block
}
.profile-container .profile-header .profile-info .btn-follow {
    position: absolute;
    top: 45px;
    right: 40px
}
.profile-container .profile-header .profile-info .header-information {
    line-height: 23px;
    margin-top: 15px;
    text-align: justify
}
.profile-container .profile-header .profile-stats {
    min-height: 175px;
    border-right: 1px solid #eee
}
.profile-container .profile-header .profile-stats .stats-col {
    margin: 30px 0;
    text-align: center
}
.profile-container .profile-header .profile-stats .stats-col:not(:last-child) {
    border-right: 1px solid #eee
}
.profile-container .profile-header .profile-stats .stats-col .stats-value {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica
}
.profile-container .profile-header .profile-stats .stats-col .stats-title {
    display: block;
    margin: 0 auto;
    text-align: center
}
.profile-container .profile-header .profile-stats .inlinestats-col {
    padding-top: 15px;
    text-align: center;
    font-family: 'Roboto', 'Lucida Sans', 'trebuchet MS', Arial, Helvetica;
    border-top: 1px solid #eee;
    min-height: 55px
}
.profile-container .profile-header .profile-stats .inlinestats-col:not(:last-child) {
    border-right: 1px solid #eee
}
.profile-container .profile-header .profile-stats .inlinestats-col i {
    margin-right: 2px
}
.profile-container .profile-header .profile-contactinfo .title {
    margin-bottom: 5px;
    margin-top: 30px
}
.profile-container .profile-header .profile-contactinfo .list-group-item {
    background: none !important;
    border: none;
    padding-bottom: 0;
    margin-top: 5px;
    padding-left: 0;
    padding-right: 5px
}
.profile-container .profile-body .nav-tabs>li.active>a,
.profile-container .profile-body .nav-tabs>li.active>a:hover,
.profile-container .profile-body .nav-tabs>li.active>a:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: 0;
    border-bottom: 2px solid #2dc3e8;
    background-color: #fbfbfb;
    top: 2px;
    margin-bottom: 0
}
.profile-container .profile-body .nav-tabs {
    border-top: 1px solid #e5e5e5;
    top: 0;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35)
}
.profile-container .profile-body .nav-tabs.tabs-flat {
    background-color: #fbfbfb
}
.profile-container .profile-body .tab-content {
    margin-top: 30px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .35);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .35)
}
.profile-container .profile-body .setting-header {
    margin-bottom: 25px
}
.profile-container .profile-contacts {
    padding: 25px
}
.profile-container .profile-contacts .contact-badge i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    background-image: linear-gradient(to bottom, #eee 0, #fbfbfb 100%);
    z-index: 100;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .175);
    color: #444
}
.profile-container .profile-contacts .contact-badge span {
    font-size: 26px;
    margin-left: 20px;
    font-weight: 300
}
.profile-container .profile-contacts .contact-info p {
    padding: 10px 70px
}
.profile-container #contact-map {
    margin: 20px;
    width: 400px;
    height: 400px;
    -webkit-border-radius: 100%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 100%;
    -moz-background-clip: padding;
    border-radius: 100%;
    background-clip: padding-box;
    border: #fbfbfb 5px solid;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3)
}
.profile-container .profile-badge i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    background-image: linear-gradient(to bottom, #eee 0, #fbfbfb 100%);
    z-index: 100;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .175);
    color: #444
}
.profile-container .profile-badge span {
    font-size: 26px;
    margin-left: 20px;
    font-weight: 300
}
.profile-container .profile-overview {
    padding: 5px
}
.profile-container .row-title {
    margin-left: -17px;
    margin-top: 50px
}
.profile-container .timeline {
    padding-top: 35px;
    margin-top: -16px
}
@media(max-width:992px) {
    .profile-container .profile-header .profile-info {
        padding: 0 40px
    }
    .profile-container .profile-header .profile-info .btn-follow {
        top: 28px
    }
    .profile-container .profile-header .profile-stats .stats-col {
        border-right: 0
    }
}
.comment {
    padding-top: 12px
}
.comment:first-child {
    padding-top: 0
}
.comment>.comment {
    margin-left: 25px !important
}
.comment .comment-avatar {
    position: relative;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
    display: block;
    float: left;
    height: 32px;
    width: 32px
}
.comment .comment-header {
    font-size: 12px;
    padding-bottom: 2px
}
.comment .comment-header span {
    color: #d0d0d0;
    display: inline-block;
    font-size: 11px;
    margin-left: 5px
}
.comment .comment-body {
    float: none;
    margin-left: 40px;
    position: relative
}
.comment .comment-text {
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    padding: 7px 12px 8px
}
.comment .comment-footer {
    display: block;
    font-size: 12px;
    padding: 4px 12px 0
}
.comment .comment-footer i {
    font-size: 13px;
    margin-right: 7px
}
.comment .comment-footer,
.comment .comment-footer a {
    color: #d0d0d0
}
.comment .comment-footer a:hover {
    color: #2dc3e8
}
.mail-container {
    position: relative
}
.mail-container .mail-sidebar,
.mail-container .mail-body {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}
.mail-container .mail-sidebar {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    min-height: 100%;
    background: #fbfbfb;
    border-right: 1px solid #ebebeb
}
.mail-container .mail-sidebar .mail-menu {
    list-style: none;
    margin: 0;
    padding: 0
}
.mail-container .mail-sidebar .mail-menu>li {
    margin: 0;
    padding: 0
}
.mail-container .mail-sidebar .mail-menu>li a {
    color: #777;
    display: block;
    padding: 10px 10px 10px 25px;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    font-size: 13px
}
.mail-container .mail-sidebar .mail-menu>li a:hover {
    text-decoration: none;
    color: #444
}
.mail-container .mail-sidebar .mail-menu>li a i {
    margin-right: 10px;
    font-size: 13px
}
.mail-container .mail-sidebar .mail-menu>li a .badge {
    font-size: 13px
}
.mail-container .mail-sidebar .mail-menu>li a .badge-tag {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px
}
.mail-container .mail-sidebar .mail-menu>li:hover a {
    background: #fff
}
.mail-container .mail-sidebar .mail-menu>li.active a {
    background: #f3f3f3;
    font-weight: bold
}
.mail-container .mail-sidebar .mail-menu>li.divider {
    padding: 0;
    height: 1px;
    margin: 9px 0 3px;
    background: #e5e5e5
}
.mail-container .mail-sidebar .mail-menu>li.menu-title {
    margin-top: 25px;
    font-size: 11px;
    text-transform: uppercase;
    padding-left: 25px;
    font-weight: 700
}
.mail-container .mail-header {
    position: relative;
    height: 38px;
    margin-left: 200px;
    padding: 0;
    border-bottom: 1px solid #eee;
    background-color: #fff
}
.mail-container .mail-header .header-buttons {
    list-style: none;
    margin-bottom: 0;
    display: inline-block;
    float: left;
    text-align: left;
    height: 38px;
    padding: 0 2px 0 7px
}
.mail-container .mail-header .header-buttons li {
    float: left;
    position: relative;
    height: 38px;
    width: 38px;
    padding: 4px;
    text-align: center
}
.mail-container .mail-header .header-buttons li a {
    display: block;
    width: 28px;
    height: 28px;
    border: 1px solid #d0d0d0;
    -webkit-border-radius: 50%;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 50%;
    -moz-background-clip: padding;
    border-radius: 50%;
    background-clip: padding-box
}
.mail-container .mail-header .header-buttons li a:hover {
    cursor: pointer;
    border-color: #2dc3e8
}
.mail-container .mail-header .header-buttons li a:hover i {
    color: #2dc3e8
}
.mail-container .mail-header .header-buttons li a i {
    line-height: 24px;
    color: #d0d0d0;
    font-size: 14px
}
.mail-container .mail-header .header-buttons li a i.fa {
    line-height: 28px;
    font-size: 16px
}
.mail-container .mail-header .header-buttons li.search {
    width: 150px
}
.mail-container .mail-header .header-buttons li.search input[type="text"] {
    border-color: #e5e5e5;
    -webkit-border-radius: 5px !important;
    -webkit-background-clip: padding-box !important;
    -moz-border-radius: 5px !important;
    -moz-background-clip: padding !important;
    border-radius: 5px !important;
    background-clip: padding-box !important
}
.mail-container .mail-header .pages {
    float: right;
    font-style: italic;
    line-height: 38px;
    color: #d0d0d0
}
.mail-container .mail-header .draft {
    float: right;
    margin-right: 15px;
    font-style: italic;
    line-height: 38px;
    color: #d0d0d0
}
.mail-container .mail-header .mail-title {
    float: left;
    margin-left: 15px;
    line-height: 36px;
    color: #555
}
.mail-container .mail-body {
    position: relative;
    margin-left: 200px;
    padding: 0;
    overflow: hidden
}
.mail-container .mail-body .mail-info {
    width: 100%;
    background: #f5f5f5;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    border-bottom: 1px solid #ebebeb
}
.mail-container .mail-body .mail-info .mail-sender,
.mail-container .mail-body .mail-info .mail-date {
    display: table-cell;
    width: 50%;
    color: #737373;
    padding: 8px 10px 7px
}
.mail-container .mail-body .mail-info .mail-sender.mail-sender a:hover,
.mail-container .mail-body .mail-info .mail-date.mail-sender a:hover {
    text-decoration: none
}
.mail-container .mail-body .mail-info .mail-sender.mail-sender span,
.mail-container .mail-body .mail-info .mail-date.mail-sender span {
    font-weight: bold;
    color: #fb6e52
}
.mail-container .mail-body .mail-info .mail-sender.mail-sender img,
.mail-container .mail-body .mail-info .mail-date.mail-sender img {
    margin-right: 5px;
    border: 2px solid #e5e5e5
}
.mail-container .mail-body .mail-info .mail-sender.mail-date,
.mail-container .mail-body .mail-info .mail-date.mail-date {
    text-align: right
}
.mail-container .mail-body .mail-text {
    border-bottom: 1px solid #ebebeb;
    padding: 15px
}
.mail-container .mail-body .mail-attachments {
    padding: 10px;
    border-bottom: 1px solid #ebebeb
}
.mail-container .mail-body .mail-attachments h4 {
    margin-bottom: 30px;
    line-height: 1
}
.mail-container .mail-body .mail-attachments h4 span {
    zoom: 1;
    filter: alpha(opacity=40);
    -webkit-opacity: .4;
    -moz-opacity: .4;
    opacity: .4
}
.mail-container .mail-body .mail-attachments ul {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0
}
.mail-container .mail-body .mail-attachments ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 30px;
    margin-bottom: 10px
}
.mail-container .mail-body .mail-attachments ul li>a {
    display: block
}
.mail-container .mail-body .mail-attachments ul li>a img {
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 10px
}
.mail-container .mail-body .mail-attachments ul li>a.thumb {
    position: relative
}
.mail-container .mail-body .mail-attachments ul li>a.thumb:after {
    font-family: 'FontAwesome';
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    vertical-align: text-top;
    color: #fff;
    background: rgba(0, 0, 0, .25);
    -webkit-border-radius: 4px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 4px;
    -moz-background-clip: padding;
    border-radius: 4px;
    background-clip: padding-box;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out
}
.mail-container .mail-body .mail-attachments ul li>a.thumb:hover:after {
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1
}
.mail-container .mail-body .mail-attachments ul li>a.thumb.download:after {
    content: ''
}
.mail-container .mail-body .mail-attachments ul li>a.name span {
    color: #666;
    float: right
}
.mail-container .mail-body .mail-attachments ul li .links {
    display: block;
    font-size: 11px;
    color: #666;
    margin-top: 6px
}
.mail-container .mail-body .mail-attachments ul li .links a {
    color: #666
}
.mail-container .mail-body .mail-reply {
    border-bottom: 1px solid #ebebeb;
    padding: 10px
}
.mail-container .mail-body .mail-reply .reply-form {
    padding: 10px;
    border: 1px solid #ebebeb;
    min-height: 200px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.mail-container .mail-body .mail-reply .reply-form>div a {
    font-weight: bold;
    color: #fb6e52
}
.mail-container .mail-body .mail-compose {
    padding: 10px
}
.mail-container .mail-body .mail-compose .note-editor {
    border: 1px solid #e5e5e5 !important
}
.mail-container .mail-body .mail-compose .note-editor .btn-group>.btn.btn-default>.caret {
    border-top-color: #999;
    border-width: 4px;
    margin-top: -2px
}
.mail-container .mail-body .mail-compose .note-editor .note-toolbar {
    border-bottom: 1px solid #e5e5e5
}
.mail-container .mail-body .mail-compose .note-editor .note-toolbar .note-color .dropdown-toggle {
    width: 25px
}
.mail-container .mail-body .mail-compose .note-editor .btn {
    color: #999 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #e5e5e5;
    background-color: #fbfbfb
}
.mail-container .mail-body .mail-compose .note-editor .btn:hover {
    color: #444 !important
}
.mail-container .mail-body .mail-compose .form-group {
    position: relative;
    margin-bottom: 5px
}
.mail-container .mail-body .mail-compose .form-group label {
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 10;
    color: #999
}
.mail-container .mail-body .mail-compose .form-group input {
    border-color: #e5e5e5;
    border-left: 0;
    position: relative;
    padding-left: 100px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #fff
}
.mail-container .mail-body .mail-compose .form-group input:focus {
    background-color: #fff
}
.mail-container .mail-body .mail-compose .form-group .field-options {
    position: absolute;
    right: 5px;
    top: 5px
}
.mail-container .mail-body .mail-compose .form-group .field-options a {
    display: inline-block;
    background: #f3f4f4;
    color: #7e8186;
    padding: 2px 6px;
    margin-left: 4px;
    text-align: center;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box
}
.mail-container .mail-body>div:last-child {
    border-bottom: 0
}
.mail-container .mail-body .mail-list {
    list-style: none;
    margin-bottom: 0;
    display: block;
    width: 100%;
    padding: 0
}
.mail-container .mail-body .mail-list .list-item {
    display: block;
    position: relative;
    height: 38px;
    padding: 0
}
.mail-container .mail-body .mail-list .list-item:before {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    max-width: 4px;
    overflow: hidden;
    background-color: #2dc3e8
}
.mail-container .mail-body .mail-list .list-item:hover:before {
    display: block
}
.mail-container .mail-body .mail-list .list-item:nth-child(2n-1) {
    background: #f6f6f6
}
.mail-container .mail-body .mail-list .list-item.unread>div.item-sender,
.mail-container .mail-body .mail-list .list-item.unread>div.item-subject {
    font-weight: bold
}
.mail-container .mail-body .mail-list .list-item>div {
    position: absolute;
    height: 38px;
    top: 0
}
.mail-container .mail-body .mail-list .list-item>div.item-check {
    left: 0;
    width: 42px;
    padding: 0 5px 0 15px;
    text-align: center;
    line-height: 38px
}
.mail-container .mail-body .mail-list .list-item>div.item-star {
    line-height: 38px;
    width: 22px;
    left: 42px
}
.mail-container .mail-body .mail-list .list-item>div.item-star a {
    font-size: 15px;
    color: #d0d0d0
}
.mail-container .mail-body .mail-list .list-item>div.item-star a.stared {
    color: #ffce55
}
.mail-container .mail-body .mail-list .list-item>div.item-sender {
    width: 184px;
    left: 64px;
    line-height: 38px;
    padding-left: 5px
}
.mail-container .mail-body .mail-list .list-item>div.item-sender a {
    color: #444;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block
}
.mail-container .mail-body .mail-list .list-item>div.item-sender a:hover {
    text-decoration: none
}
.mail-container .mail-body .mail-list .list-item>div.item-subject {
    min-width: 200px;
    left: 250px;
    line-height: 38px;
    padding-left: 10px
}
.mail-container .mail-body .mail-list .list-item>div.item-subject a {
    color: #444
}
.mail-container .mail-body .mail-list .list-item>div.item-subject a:hover {
    text-decoration: none
}
.mail-container .mail-body .mail-list .list-item>div.item-time {
    right: 0;
    width: 120px;
    line-height: 38px;
    text-align: right;
    padding-right: 20px
}
.mail-container .mail-body .mail-list .list-item>div.item-options {
    right: 120px;
    width: 38px;
    line-height: 38px;
    text-align: right;
    padding-right: 10px
}
.mail-container .mail-body .mail-list .list-item>div.item-options a {
    color: #555;
    font-size: 19px
}
.mail-container .mail-body .mail-list .list-item>div.item-options a:hover {
    text-decoration: none
}
.mail-container.right-sidebar .mail-sidebar {
    border-left: 1px solid #ebebeb;
    border-right: 0
}
.mail-container.right-sidebar .mail-body {
    float: left
}
@media(max-width:999px) {
    .mail-container .mail-body .mail-list .list-item {
        height: 70px;
        padding: 5px
    }
    .mail-container .mail-body .mail-list .list-item>div.item-subject {
        top: 25px;
        left: 0;
        padding: 0 5px 0 15px;
        min-width: 100px
    }
    .mail-container .mail-body .mail-list .list-item>div.item-options {
        right: 5px
    }
    .mail-container .mail-body .mail-list .list-item>div.item-time {
        right: 30px
    }
}
@media(max-width:760px) {
    .mail-container .mail-sidebar {
        display: none
    }
    .mail-container .mail-body,
    .mail-container .mail-header {
        margin-left: 0
    }
}
@media(max-width:570px) {
    .mail-container .mail-header {
        height: 76px
    }
    .mail-container .mail-header .header-buttons {
        display: block;
        float: none;
        margin: 0 auto
    }
}
@media(max-width:330px) {
    .mail-container .mail-body .mail-list .list-item {
        height: 90px
    }
    .mail-container .mail-body .mail-list .list-item>div.item-subject {
        margin-top: 20px;
        margin-right: 50px;
        line-height: 20px;
        height: 50px
    }
    .mail-container .mail-body .mail-list .list-item>div.item-time {
        display: none
    }
    .mail-container .mail-header {
        text-align: center;
        height: 114px
    }
    .mail-container .mail-header .pages {
        display: block;
        float: none;
        margin: 0 auto
    }
}