#saving-text {
    position: absolute;
    top: 0px;
    left: 40%;
}
.files-table tr:first-child td {
    border-top: none;
}
.files-table tr {
    cursor: pointer;
}
.files-table tr:hover {
    background: #eaeaea;
}
.comments-container {
    position: relative;
}