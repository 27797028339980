@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/sweetalert/dev/sweetalert";
@import "node_modules/toastr/toastr";

// Select2
$s2bs-dropdown-link-active-bg: #2dc3e8;
@import "node_modules/select2/src/scss/core";
@import "node_modules/select2/src/scss/theme/default/layout";
@import "node_modules/select2-bootstrap-theme/src/select2-bootstrap";

@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3";
@import "./beyond";

@import "./components/appointments";
@import "./components/comments";
@import "./components/container";
@import "./components/fonts";
@import "./components/general";
@import "./components/messages";
@import "./components/modal";
@import "./components/statements";
@import "./components/survey";
@import "./components/surveys";
@import "./components/tabs";
@import "./components/wizard";
@import "./components/tree";

h1.heading,
h2.heading,
h3.heading,
h4.heading,
h5.heading,
h6.heading {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #eee;
}

[v-cloak] {
	display: none;
}

table.patient-info td {
    padding-bottom: 5px;
}
table.patient-info tr td:last-child {
    font-weight: bold;
}

.service-select-container .service-group:nth-child(odd) {
    background: rgb(235, 235, 235);
}
.service-select-container .service-group {
    padding: 10px;
}

.search-fore {
    background: #ffff00;
}

.has-flash {
    display: none;
}

.type-count {
    cursor: pointer;
}
.type-count:hover {
    color: red;
}
#ajax-records-container {
    max-height: 500px;
    overflow-y: scroll;
}
.survey-appointments {
    background: #eaeaea;
    border-top: 1px solid silver;
    margin-top: 10px;
    position: relative;
}
.survey-appointments .table {
    background-color: #eaeaea;
}

.cke_top {
    background-image: none !important;
    background: #eaeaea !important;
}
.cke_chrome {
    box-shadow: none !important;
}

.cke_wysiwyg_frame, .cke_wysiwyg_div {
    width: 770px !important;
    margin: 0 auto !important;
    display: flex;
    -webkit-box-shadow: -1px 2px 14px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 14px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 14px 0px rgba(0,0,0,0.75);
}
.cke_contents {
    background-color: grey !important;
}

#toast-container {
    top: 45px !important;
    left: 45px !important;
}

body.modal-open {
    overflow: auto;
}
body.modal-open[style] {
    padding-right: 0px !important;
}

.modal::-webkit-scrollbar {
    width: 0 !important; /*removes the scrollbar but still scrollable*/
    /* reference: http://stackoverflow.com/a/26500272/2259400 */
}

.page-title {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 18px;
}

.table-small {
    font-size: 12px;
    tr {
        td {
            padding: 3px !important;
        }
    }
}