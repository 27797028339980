.comments {
	margin-bottom: 10px;
}
.comments .comment {
    border-bottom: 1px solid #eaeaea;
}
.comment {
    padding-bottom: 10px;
}
.comment .avatar {
    float:left;
}
.comment .text {
    margin-left: 50px;
}
h5.doctor {
    margin-bottom: 4px;
}
.text-heading .doctor {
    color: #003bb3;
    font-weight: 700;
}
.text-heading .time {
    color:#bfbfbf;
}
.text-heading::after {
    clear:both;
}