.list-header {
    padding-bottom: 20px;
    padding-left: 5px;
}
.order-list .order:last-child {
    border-bottom: none;
}
.order-list .code {
    /*font-weight: bold;*/
}
.order-list .patient {
    color: rgb(103, 103, 103);
    font-weight: bold;
}
.order {
    padding: 10px 0px;
    border-bottom: 1px solid #eaeaea;
}
.order:hover {
    cursor: pointer;
}
.order.row {
    margin: 0px;
}
.order-info {
    position: relative;
    padding-left: 50px;
}
.order-info .avatar {
    position: absolute;
    left: 0px;
    width: 36px;
}
.survey_result {
    cursor: pointer;
}

.survey-appointments {
    background: #eaeaea;
    border-top: 1px solid silver;
    margin-top: 10px;
    position: relative;
}
.survey-appointments .table {
    background-color: #eaeaea;
}
