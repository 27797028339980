textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    color: #333;
}

input[type="radio"] {
    background-color: #333 !important;
    border-color: #333 !important;
    box-shadow: none !important;
    color: #333 !important;
}

#WiredWizard ul li {
    width: 25%;
}


.birth {
    width: 60px !important;
    float: left;
    margin-left: 15px
}

.year {
    width: 80px !important;
    margin-left: 0px;
}