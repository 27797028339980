.survey {
    position: relative;
    min-height: 50px;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0px;
}
.surveys-container .survey:last-child {
    border-bottom: none;
}
.survey .survey-info {
    margin-left: 20px;
}
.expand-sign {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 5px;
}
.expand-sign .fa:before {
    content: "\f078";
}
.expand-sign.expanded .fa:before {
    content: "\f077";
}